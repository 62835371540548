import { Fill, Icon, Style, Text } from 'ol/style'
import {
  INFRA_BRIDGE_ID,
  INFRA_CHARGING_STATION_ID,
  INFRA_RAIL_INTERSECTION_ID,
  INFRA_RAIL_SWITCH_CONTROL_CENTER_ID,
  INFRA_RAIL_SWITCH_HEATING_CENTER_ID,
  INFRA_RAIL_SWITCH_ID,
  INFRA_RETAINING_WALL_ID,
  INFRA_TRAM_STOP_CENTER_ID,
  INFRA_TRAM_STOP_ID,
  INFRA_CAPACITOR_ID,
  INFRA_DISCONNECTOR_ID
} from '../store/map/reducers'

export const POINT_TYPE_ID_KEY = 'type_id'
export const INFRA_RESOURCE_TYPE_TRAM_STOPS = 'Pysäkki'
export const INFRA_RESOURCE_TYPE_RAIL_SWITCHES = 'Vaihde'
export const INFRA_RESOURCE_TYPE_RAIL_INTERSECTIONS = 'Raideristeys'
export const INFRA_RESOURCE_TYPE_CHARGING_STATIONS = 'Sähkönsyöttöasema'
export const INFRA_RESOURCE_TYPE_TRAM_STOP_CENTER = 'Pysäkkikeskus'
export const INFRA_RESOURCE_TYPE_RAIL_SWITCH_HEATING_CENTER = 'Vaihteenlämmityskeskus'
export const INFRA_RESOURCE_TYPE_RAIL_SWITCH_CONTROL_CENTER = 'Vaihteenohjauskeskus'
export const INFRA_RESOURCE_TYPE_BRIDGES = 'Silta'
export const INFRA_RESOURCE_TYPE_RETAINING_WALLS = 'Tukimuuri'
export const INFRA_RESOURCE_TYPE_CAPACITORS = 'Jaksoeristin'
export const INFRA_RESOURCE_TYPE_DISCONNECTORS = 'Erotin'

const ICONS_PATH = '/images/icons'
const INFRA_POINT_ICONS_PATH = '/images/map/markers/infra'
export const INFRA_RESOURCE_METADATA = {
  [INFRA_TRAM_STOP_ID]: {
    icon: ICONS_PATH + '/infra-tram-stop.svg',
    locationIcon: INFRA_POINT_ICONS_PATH + '/infra-tram-stop.svg'
  },
  [INFRA_RAIL_SWITCH_ID]: {
    icon: ICONS_PATH + '/infra-rail-switch.svg',
    locationIcon: INFRA_POINT_ICONS_PATH + '/infra-rail-switch.svg'
  },
  [INFRA_RAIL_INTERSECTION_ID]: {
    icon: ICONS_PATH + '/infra-rail-intersection.svg',
    locationIcon: INFRA_POINT_ICONS_PATH + '/infra-rail-intersection.svg'
  },
  [INFRA_CHARGING_STATION_ID]: {
    icon: ICONS_PATH + '/infra-charging-station.svg',
    locationIcon: INFRA_POINT_ICONS_PATH + '/infra-charging-station.svg'
  },
  [INFRA_TRAM_STOP_CENTER_ID]: {
    icon: ICONS_PATH + '/infra-tram-stop-center.svg',
    locationIcon: INFRA_POINT_ICONS_PATH + '/infra-tram-stop-center.svg'
  },
  [INFRA_RAIL_SWITCH_HEATING_CENTER_ID]: {
    icon: ICONS_PATH + '/infra-rail-switch-heating-center.svg',
    locationIcon: INFRA_POINT_ICONS_PATH + '/infra-rail-switch-heating-center.svg'
  },
  [INFRA_RAIL_SWITCH_CONTROL_CENTER_ID]: {
    icon: ICONS_PATH + '/infra-rail-switch-control-center.svg',
    locationIcon: INFRA_POINT_ICONS_PATH + '/infra-rail-switch-control-center.svg'
  },
  [INFRA_BRIDGE_ID]: {
    icon: ICONS_PATH + '/infra-bridge.svg',
    locationIcon: INFRA_POINT_ICONS_PATH + '/infra-bridge.svg'
  },
  [INFRA_RETAINING_WALL_ID]: {
    icon: ICONS_PATH + '/infra-retaining-wall.svg',
    locationIcon: INFRA_POINT_ICONS_PATH + '/infra-retaining-wall.svg'
  },
  [INFRA_CAPACITOR_ID]: {
    icon: ICONS_PATH + '/infra-capacitor.svg',
    locationIcon: INFRA_POINT_ICONS_PATH + '/infra-capacitor.svg'
  },
  [INFRA_DISCONNECTOR_ID]: {
    icon: ICONS_PATH + '/infra-disconnector.svg',
    locationIcon: INFRA_POINT_ICONS_PATH + '/infra-disconnector.svg'
  }
}

const TRAM_POINT_ICONS_PATH = '/images/map/markers/tram'
const TRAM_POINT_ICON = TRAM_POINT_ICONS_PATH + '/tram-green-white.svg'
const TRAM_EARLY_POINT_ICON = TRAM_POINT_ICONS_PATH + '/tram-green-red.svg'
const TRAM_LATE_POINT_ICON = TRAM_POINT_ICONS_PATH + '/tram-green-orange.svg'
const EXPIRED_TRAM_POINT_ICON = TRAM_POINT_ICONS_PATH + '/tram-gray-white.svg'
const EXPIRED_TRAM_EARLY_POINT_ICON = TRAM_POINT_ICONS_PATH + '/tram-gray-red.svg'
const EXPIRED_TRAM_LATE_POINT_ICON = TRAM_POINT_ICONS_PATH + '/tram-gray-orange.svg'
const DEFAULT_LABEL_FONT = 'bold 12px/1 sans-serif'
const DEFAULT_LABEL_COLOR = 'black'

export const getTramIconPointStyles = (label, rotation, zIndex, dataExpired, delayMs) => {
  return [
    new Style({
      image: new Icon({
        src: getTramIcon(dataExpired, delayMs),
        rotation: rotation
      }),
      text: new Text({
        font: DEFAULT_LABEL_FONT,
        fill: new Fill({ color: DEFAULT_LABEL_COLOR }),
        text: label
      }),
      zIndex: zIndex
    })
  ]
}

const getTramIcon = (dataExpired, delayMs) => {
  const tramEarlyLimitMs = -60000 // 1min
  const tramLateLimitMs = 60000 * 3 // 3min
  const tramTooEarly = delayMs < tramEarlyLimitMs
  const tramTooLate = delayMs > tramLateLimitMs
  if (dataExpired) {
    return tramTooEarly ? EXPIRED_TRAM_EARLY_POINT_ICON
      : tramTooLate ? EXPIRED_TRAM_LATE_POINT_ICON
        : EXPIRED_TRAM_POINT_ICON
  } else {
    return tramTooEarly ? TRAM_EARLY_POINT_ICON
      : tramTooLate ? TRAM_LATE_POINT_ICON
        : TRAM_POINT_ICON
  }
}

export const getInfraIconPointStyles = (infraId) => {
  const yAxisOffsetPx = 62 // the height from top of the SVG to the location markers bottom tip
  return [
    new Style({
      image: new Icon({
        src: INFRA_RESOURCE_METADATA[infraId].locationIcon,
        scale: 0.80,
        anchor: [0.5, yAxisOffsetPx],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels'
      })
    })
  ]
}
