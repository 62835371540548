import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import equipmentService from '../../services/equipmentService'
import {
  setEquipmentData,
  setEquipmentSummaryData
} from '../../store/equipment/actions'
import { EQUIPMENT_ID } from '../../store/equipment/reducers'
import {
  INFRA_MAINTENANCES_CLOSED_AT_LIMIT_DAYS,
  INFRA_MAINTENANCES_DEADLINE_IN_DAYS
} from '../../util/infraUtil'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  EQUIPMENT_SLICE_ID,
  MAINTENANCES_SLICE_ID
} from '../../store'
import { INFRA_ID } from '../../store/maintenances/reducers'
import {
  setEquipmentMaintenanceData,
  setEquipmentMaintenanceSummaryData,
  setMaintenanceData,
  setMaintenanceSummaryData
} from '../../store/maintenances/actions'
import {
  DEVIATION_ID,
  OBSERVATION_ID
} from '../../store/deviations/reducers'
import { setDeviationData } from '../../store/deviations/actions'
import Grid from '@material-ui/core/Grid'
import ColumnContent from '../../components/ColumnContent/ColumnContent'
import ColumnTopbar from '../../components/ColumnTopbar/ColumnTopbar'
import ColumnTopbarHeadingButton from '../../components/ColumnTopbar/ColumnTopbarHeadingButton'
import DoughnutChart from '../../components/DoughnutChart/DoughnutChart'
import RecordSummary from '../../components/RecordSummary/RecordSummary'
import RecordSummaryItem from '../../components/RecordSummary/RecordSummaryItem'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import { activateData } from '../../store/general/actions'
import { ACTIVATE_MAINTENANCE_DATA } from '../../store/maintenances/actionTypes'
import { ACTIVATE_REPORT_DATA } from '../../store/reports/actionTypes'
import {
  CUSTOMER_SATISFACTION_ID,
  CUSTOMER_SATISFACTION_VISUAL_CUSTOMER_SATISFACTION_AVERAGE_ID,
  CUSTOMER_SATISFACTION_VISUAL_NPS_ID,
  PASSENGER_COUNTING_ID, PASSENGER_COUNTING_VISUAL_PASSENGER_COUNT_BY_MONTH_ID,
  REALIABILITY_VISUAL_DEPARTURE_ACCURACY_CHART_ID,
  REALIABILITY_VISUAL_DEPARTURE_ACCURACY_PERCENTAGE_ID,
  REALIABILITY_VISUAL_DEPARTURE_ACCURACY_PERCENTAGE_PRESENT_YEAR_ID,
  REALIABILITY_VISUAL_SYSTEM_RELIABILITY_CHART_ID,
  REALIABILITY_VISUAL_SYSTEM_RELIABILITY_PERCENTAGE_ID,
  REALIABILITY_VISUAL_SYSTEM_RELIABILITY_PERCENTAGE_PRESENT_YEAR_ID,
  RELIABILITY_ID,
  RELIABILITY_PAGE_PUNCTUALITY_ID,
  RELIABILITY_PAGE_RELIABILITY_ID
} from '../../util/reportUtil'
import { models } from 'powerbi-client'
import reportService from '../../services/reportService'
import AspectRatioWrapper from '../../components/AspectRatioWrapper/AspectRatioWrapper'
import { PowerBIEmbed } from 'powerbi-client-react'
import Box from '@material-ui/core/Box'

function Dashboard ({ token }) {
  const { t, i18n } = useTranslation()
  const currentLanguage = i18n.language
  const dispatch = useDispatch()
  const equipmentSummary = useSelector(state => state[EQUIPMENT_SLICE_ID][EQUIPMENT_ID].summaryData)
  const equipmentQueryTimestamp = equipmentSummary.dataUpdateTimestamp
  const infraQueryTimestamp = useSelector(state => state[MAINTENANCES_SLICE_ID][INFRA_ID].dataTimestamp)
  const infraSummaryData = useSelector(state => state[MAINTENANCES_SLICE_ID][INFRA_ID].summaryData[0])
  const deviationsTimeFilter = 30
  const [embedToken, setEmbedToken] = useState(null)
  const [reliabilityReport, setReliabilityReport] = useState(null)
  const [customerSatisfactionReport, setCustomerSatisfactionReport] = useState(null)
  const [passengerCountingReport, setPassengerCountingReport] = useState(null)
  const [powerBIEmbedSettings] = useState({
    filterPaneEnabled: false,
    navContentPaneEnabled: false,
    localeSettings: {
      language: currentLanguage,
      formatLocale: currentLanguage
    }
  })

  useEffect(() => {
    dispatch(setMaintenanceData(INFRA_ID, INFRA_MAINTENANCES_CLOSED_AT_LIMIT_DAYS, INFRA_MAINTENANCES_DEADLINE_IN_DAYS))
    dispatch(setMaintenanceSummaryData(INFRA_ID, INFRA_MAINTENANCES_CLOSED_AT_LIMIT_DAYS, INFRA_MAINTENANCES_DEADLINE_IN_DAYS))
    dispatch(setDeviationData(DEVIATION_ID, deviationsTimeFilter))
    dispatch(setDeviationData(OBSERVATION_ID, deviationsTimeFilter))
    dispatch(setEquipmentData(EQUIPMENT_ID))
    dispatch(setEquipmentSummaryData(EQUIPMENT_ID))
    dispatch(setEquipmentMaintenanceSummaryData(EQUIPMENT_ID))
    dispatch(setEquipmentMaintenanceData(EQUIPMENT_ID))
  }, [dispatch])

  useEffect(() => {
    reportService.getPowerBIReports().then((data) => {
      if (data.data.embedToken.value) {
        setEmbedToken(data.data.embedToken)
        setReliabilityReport(reportService.getReportById(data.data.reports, RELIABILITY_ID))
        setCustomerSatisfactionReport(reportService.getReportById(data.data.reports, CUSTOMER_SATISFACTION_ID))
        setPassengerCountingReport(reportService.getReportById(data.data.reports, PASSENGER_COUNTING_ID))
      }
    })
  }, [setEmbedToken, setReliabilityReport, setCustomerSatisfactionReport])

  useEffect(() => {
    if (embedToken) {
      const currentMs = new Date().getTime()
      const tokenExpirationMs = new Date(embedToken?.expiration).getTime()
      const expirationOffsetMs = 60000 * 2 // 2 minutes
      const updateTokenMs = tokenExpirationMs - currentMs - expirationOffsetMs
      const timer = setTimeout(() => {
        reportService.getPowerBIReports().then((data) => {
          setEmbedToken(data.data.embedToken)
        })
      }, updateTokenMs)
      return () => clearTimeout(timer)
    }
  }, [embedToken, setEmbedToken])

  const history = useHistory()

  const handleLinkClick = (url) => {
    history.push(url)
  }

  const handleLinkClickAndActivateData = (url, actionType, dataId, state) => {
    history.push({
      pathname: url,
      state: state
    })
    dispatch(activateData(actionType, dataId))
  }

  return (
    <div className='view-container'>
      <Grid container spacing={4}>
        <Grid item lg={4} xs={12}>
          <ColumnContent>
            <ColumnTopbar>
              <ColumnTopbarHeadingButton
                heading={t('equipment.status')}
                clickFn={() => handleLinkClick('/kalusto')}
                updatedTimestamp={equipmentQueryTimestamp}
              />
            </ColumnTopbar>
            <DoughnutChart
              data={equipmentService.getEquipmentStatusSummary(equipmentSummary)}
              label={t('total') + ' ' + equipmentService.getEquipmentStatusSummaryAmount(equipmentSummary)}
              legendPositionClass='legend-right'
              showValuesInLegend
            />
          </ColumnContent>
        </Grid>

        <Grid item lg={4} xs={12}>
          <ColumnContent>
            <ColumnTopbar>
              <ColumnTopbarHeadingButton
                heading={t('departure.accuracy.at.last.stops')}
                clickFn={() => handleLinkClickAndActivateData('/raportit', ACTIVATE_REPORT_DATA, RELIABILITY_ID, { pageName: reportService.getReportPageById(reliabilityReport?.pages, RELIABILITY_PAGE_PUNCTUALITY_ID) })}
              />
            </ColumnTopbar>
            <Box display='flex'>
              <Box maxWidth='125px' width='100%' marginRight='20px'>
                <Box maxWidth='115px' width='100%'>
                  <AspectRatioWrapper width={10} height={3.25}>
                    <PowerBIEmbed
                      cssClassName='position-absolute w-100 h-100'
                      embedConfig={{
                        type: 'visual',
                        id: reliabilityReport?.reportId,
                        embedUrl: reliabilityReport?.embedUrl,
                        accessToken: embedToken?.value,
                        tokenType: models.TokenType.Embed,
                        settings: powerBIEmbedSettings,
                        pageName: reliabilityReport?.embeddedVisuals?.pageName,
                        visualName: reportService.getVisualNameById(reliabilityReport?.embeddedVisuals?.visuals, REALIABILITY_VISUAL_DEPARTURE_ACCURACY_PERCENTAGE_ID)
                      }}
                    />
                  </AspectRatioWrapper>
                </Box>
                <div className='text-md'>{t('ongoing.month')}</div>
              </Box>
              <Box maxWidth='125px' width='100%'>
                <Box maxWidth='115px' width='100%'>
                  <AspectRatioWrapper width={10} height={3.25}>
                    <PowerBIEmbed
                      cssClassName='position-absolute w-100 h-100'
                      embedConfig={{
                        type: 'visual',
                        id: reliabilityReport?.reportId,
                        embedUrl: reliabilityReport?.embedUrl,
                        accessToken: embedToken?.value,
                        tokenType: models.TokenType.Embed,
                        settings: powerBIEmbedSettings,
                        pageName: reliabilityReport?.embeddedVisuals?.pageName,
                        visualName: reportService.getVisualNameById(reliabilityReport?.embeddedVisuals?.visuals, REALIABILITY_VISUAL_DEPARTURE_ACCURACY_PERCENTAGE_PRESENT_YEAR_ID)
                      }}
                    />
                  </AspectRatioWrapper>
                </Box>
                <div className='text-md'>{t('ongoing.year')}</div>
              </Box>
            </Box>
            <AspectRatioWrapper width={16} height={9}>
              <PowerBIEmbed
                cssClassName='position-absolute w-100 h-100'
                embedConfig={{
                  type: 'visual',
                  id: reliabilityReport?.reportId,
                  embedUrl: reliabilityReport?.embedUrl,
                  accessToken: embedToken?.value,
                  tokenType: models.TokenType.Embed,
                  settings: powerBIEmbedSettings,
                  pageName: reliabilityReport?.embeddedVisuals?.pageName,
                  visualName: reportService.getVisualNameById(reliabilityReport?.embeddedVisuals?.visuals, REALIABILITY_VISUAL_DEPARTURE_ACCURACY_CHART_ID)
                }}
              />
            </AspectRatioWrapper>
          </ColumnContent>
        </Grid>

        <Grid item lg={4} xs={12}>
          <ColumnContent>
            <ColumnTopbar>
              <ColumnTopbarHeadingButton
                heading={t('system.reliability')}
                clickFn={() => handleLinkClickAndActivateData('/raportit', ACTIVATE_REPORT_DATA, RELIABILITY_ID, { pageName: reportService.getReportPageById(reliabilityReport?.pages, RELIABILITY_PAGE_RELIABILITY_ID) })}
              />
            </ColumnTopbar>
            <Box display='flex'>
              <Box maxWidth='125px' width='100%' marginRight='20px'>
                <Box maxWidth='115px' width='100%'>
                  <AspectRatioWrapper width={10} height={3.25}>
                    <PowerBIEmbed
                      cssClassName='position-absolute w-100 h-100'
                      embedConfig={{
                        type: 'visual',
                        id: reliabilityReport?.reportId,
                        embedUrl: reliabilityReport?.embedUrl,
                        accessToken: embedToken?.value,
                        tokenType: models.TokenType.Embed,
                        settings: powerBIEmbedSettings,
                        pageName: reliabilityReport?.embeddedVisuals?.pageName,
                        visualName: reportService.getVisualNameById(reliabilityReport?.embeddedVisuals?.visuals, REALIABILITY_VISUAL_SYSTEM_RELIABILITY_PERCENTAGE_ID)
                      }}
                    />
                  </AspectRatioWrapper>
                </Box>
                <div className='text-md'>{t('ongoing.month')}</div>
              </Box>
              <Box maxWidth='125px' width='100%'>
                <Box maxWidth='115px' width='100%'>
                  <AspectRatioWrapper width={10} height={3.25}>
                    <PowerBIEmbed
                      cssClassName='position-absolute w-100 h-100'
                      embedConfig={{
                        type: 'visual',
                        id: reliabilityReport?.reportId,
                        embedUrl: reliabilityReport?.embedUrl,
                        accessToken: embedToken?.value,
                        tokenType: models.TokenType.Embed,
                        settings: powerBIEmbedSettings,
                        pageName: reliabilityReport?.embeddedVisuals?.pageName,
                        visualName: reportService.getVisualNameById(reliabilityReport?.embeddedVisuals?.visuals, REALIABILITY_VISUAL_SYSTEM_RELIABILITY_PERCENTAGE_PRESENT_YEAR_ID)
                      }}
                    />
                  </AspectRatioWrapper>
                </Box>
                <div className='text-md'>{t('ongoing.year')}</div>
              </Box>
            </Box>
            <AspectRatioWrapper width={16} height={9}>
              <PowerBIEmbed
                cssClassName='position-absolute w-100 h-100'
                embedConfig={{
                  type: 'visual',
                  id: reliabilityReport?.reportId,
                  embedUrl: reliabilityReport?.embedUrl,
                  accessToken: embedToken?.value,
                  tokenType: models.TokenType.Embed,
                  settings: powerBIEmbedSettings,
                  pageName: reliabilityReport?.embeddedVisuals?.pageName,
                  visualName: reportService.getVisualNameById(reliabilityReport?.embeddedVisuals?.visuals, REALIABILITY_VISUAL_SYSTEM_RELIABILITY_CHART_ID)
                }}
              />
            </AspectRatioWrapper>
          </ColumnContent>
        </Grid>

        <Grid item lg={4} xs={12}>
          <ColumnContent>
            <ColumnTopbar>
              <ColumnTopbarHeadingButton
                heading={t('infra.maintenance')}
                clickFn={() => handleLinkClickAndActivateData('/huollot', ACTIVATE_MAINTENANCE_DATA, INFRA_ID)}
                updatedTimestamp={infraQueryTimestamp}
              />
            </ColumnTopbar>
            <RecordSummary>
              <RecordSummaryItem
                label={t('open.malfunctions.restricts.usage')}
                color='red'
                icon={<ErrorOutline />}
                amount={infraSummaryData ? infraSummaryData.malfunctionsOpenByHighestPriority : 0}
                trend=''
              />
              <RecordSummaryItem
                label={t('open.malfunctions.limited.usage')}
                color='orange'
                icon={<ErrorOutline />}
                amount={infraSummaryData ? infraSummaryData.malfunctionsOpenByMediumPriority : 0}
                trend=''
              />
            </RecordSummary>
          </ColumnContent>
        </Grid>

        <Grid item lg={4} xs={12}>
          <ColumnContent>
            <ColumnTopbar>
              <ColumnTopbarHeadingButton
                heading={t('customer.satisfaction')}
                clickFn={() => handleLinkClickAndActivateData('/raportit', ACTIVATE_REPORT_DATA, CUSTOMER_SATISFACTION_ID)}
                subtext={t('ongoing.year')}
              />
            </ColumnTopbar>
            <Grid container spacing={5}>
              <Grid item lg={6} xs={12}>
                <h3 className='mb-0 text-center w-100'>{t('nps')}</h3>
                <AspectRatioWrapper width={5} height={4}>
                  <PowerBIEmbed
                    cssClassName='position-absolute w-100 h-100'
                    embedConfig={{
                      type: 'visual',
                      id: customerSatisfactionReport?.reportId,
                      embedUrl: customerSatisfactionReport?.embedUrl,
                      accessToken: embedToken?.value,
                      tokenType: models.TokenType.Embed,
                      settings: powerBIEmbedSettings,
                      pageName: customerSatisfactionReport?.embeddedVisuals?.pageName,
                      visualName: reportService.getVisualNameById(customerSatisfactionReport?.embeddedVisuals?.visuals, CUSTOMER_SATISFACTION_VISUAL_NPS_ID)
                    }}
                  />
                </AspectRatioWrapper>
              </Grid>
              <Grid item lg={6} xs={12}>
                <h3 className='text-center w-100'>{t('customer.satisfaction.average')}</h3>
                <AspectRatioWrapper width={16} height={9}>
                  <PowerBIEmbed
                    cssClassName='position-absolute w-100 h-100'
                    embedConfig={{
                      type: 'visual',
                      id: customerSatisfactionReport?.reportId,
                      embedUrl: customerSatisfactionReport?.embedUrl,
                      accessToken: embedToken?.value,
                      tokenType: models.TokenType.Embed,
                      settings: powerBIEmbedSettings,
                      pageName: customerSatisfactionReport?.embeddedVisuals?.pageName,
                      visualName: reportService.getVisualNameById(customerSatisfactionReport?.embeddedVisuals?.visuals, CUSTOMER_SATISFACTION_VISUAL_CUSTOMER_SATISFACTION_AVERAGE_ID)
                    }}
                  />
                </AspectRatioWrapper>
              </Grid>
            </Grid>
          </ColumnContent>
        </Grid>

        <Grid item lg={4} xs={12}>
          <ColumnContent>
            <ColumnTopbar>
              <ColumnTopbarHeadingButton
                heading={t('passenger.count.by.month')}
                clickFn={() => handleLinkClickAndActivateData('/raportit', ACTIVATE_REPORT_DATA, PASSENGER_COUNTING_ID)}
              />
            </ColumnTopbar>
            <AspectRatioWrapper width={16} height={9}>
              <PowerBIEmbed
                cssClassName='position-absolute w-100 h-100'
                embedConfig={{
                  type: 'visual',
                  id: passengerCountingReport?.reportId,
                  embedUrl: passengerCountingReport?.embedUrl,
                  accessToken: embedToken?.value,
                  tokenType: models.TokenType.Embed,
                  settings: powerBIEmbedSettings,
                  pageName: passengerCountingReport?.embeddedVisuals?.pageName,
                  visualName: reportService.getVisualNameById(passengerCountingReport?.embeddedVisuals?.visuals, PASSENGER_COUNTING_VISUAL_PASSENGER_COUNT_BY_MONTH_ID)
                }}
              />
            </AspectRatioWrapper>
          </ColumnContent>
        </Grid>
      </Grid>
    </div>
  )
}

export default Dashboard
