import {
  STATUS_COLOR_BLUE,
  STATUS_COLOR_GREEN,
  STATUS_COLOR_ORANGE,
  STATUS_COLOR_RED,
  ONLINE_STATUS_AVAILABLE_FOR_TRAFFIC,
  ONLINE_STATUS_IN_TRAFFIC,
  ONLINE_STATUS_OFF_TRAFFIC,
  ONLINE_STATUS_PROHIBITED_FROM_TRAFFIC,
  ONLINE_STATUS_IN_MAINTENANCE,
  ONLINE_STATUS_IN_REPAIRMENT,
  STATUS_ASSIGNED,
  STATUS_LATE,
  STATUS_OPEN,
  STATUS_PROCESSED,
  STATUS_READY_FOR_WORK,
  STATUS_PROCESSED_UNSIGNED,
  STATUS_PLANNED,
  STATUS_IN_PROGRESS,
  ONLINE_STATUS_READY_FOR_TRAFFIC
} from './constants'

export const getLocaleDateString = (date) => {
  return date ? new Date(date).toLocaleDateString('fi-FI') : ''
}

export const getLocaleTimeString = (date) => {
  return date ? new Date(date).toLocaleTimeString('fi-FI', { hour: '2-digit', minute: '2-digit' }) : ''
}

export const getLocaleTimeWithSecondsString = (date) => {
  return date ? new Date(date).toLocaleTimeString('fi-FI', { hour: '2-digit', minute: '2-digit', second: '2-digit' }) : ''
}

export const isDataActive = (activeDataId, dataId) => {
  return activeDataId === dataId
}

export const isDeadlineExceeded = (date, status) => {
  return status === STATUS_OPEN && new Date(date) < new Date()
}

export const isSubsetDataActive = (subsetData, dataId) => {
  return (subsetData.indexOf(dataId) > -1)
}

const STRING_STATUS_MAP = new Map()
STRING_STATUS_MAP.set(STATUS_OPEN, { color: STATUS_COLOR_ORANGE, tKey: 'vacant' })
STRING_STATUS_MAP.set(STATUS_LATE, { color: STATUS_COLOR_RED, tKey: 'late' })
STRING_STATUS_MAP.set(STATUS_PROCESSED, { color: STATUS_COLOR_GREEN, tKey: 'finished' })
STRING_STATUS_MAP.set(STATUS_ASSIGNED, { color: STATUS_COLOR_BLUE, tKey: 'assigned' })
STRING_STATUS_MAP.set(STATUS_READY_FOR_WORK, { color: STATUS_COLOR_ORANGE, tKey: 'ready.to.begin' })
STRING_STATUS_MAP.set(STATUS_PROCESSED_UNSIGNED, { color: STATUS_COLOR_GREEN, tKey: 'processed.unsigned' })
STRING_STATUS_MAP.set(STATUS_PLANNED, { color: STATUS_COLOR_BLUE, tKey: 'planned' })
STRING_STATUS_MAP.set(STATUS_IN_PROGRESS, { color: STATUS_COLOR_BLUE, tKey: 'started' })
STRING_STATUS_MAP.set(ONLINE_STATUS_OFF_TRAFFIC, { color: STATUS_COLOR_RED, tKey: 'not.in.use' })
STRING_STATUS_MAP.set(ONLINE_STATUS_IN_TRAFFIC, { color: STATUS_COLOR_GREEN, tKey: 'in.traffic' })
STRING_STATUS_MAP.set(ONLINE_STATUS_READY_FOR_TRAFFIC, { color: STATUS_COLOR_GREEN, tKey: 'in.traffic' })
STRING_STATUS_MAP.set(ONLINE_STATUS_AVAILABLE_FOR_TRAFFIC, { color: STATUS_COLOR_BLUE, tKey: 'free.for.traffic' })
STRING_STATUS_MAP.set(ONLINE_STATUS_PROHIBITED_FROM_TRAFFIC, { color: STATUS_COLOR_RED, tKey: 'use.prohibited' })
STRING_STATUS_MAP.set(ONLINE_STATUS_IN_MAINTENANCE, { color: STATUS_COLOR_ORANGE, tKey: 'in.maintenance' })
STRING_STATUS_MAP.set(ONLINE_STATUS_IN_REPAIRMENT, { color: STATUS_COLOR_ORANGE, tKey: 'in.repairment' })

export const getStatus = (status) => {
  return STRING_STATUS_MAP.get(status) || null
}

export const timeFilterOptions = [
  {
    labelKey: 'time.filter.last.day',
    value: 1
  },
  {
    labelKey: 'time.filter.last.week',
    value: 7
  },
  {
    labelKey: 'time.filter.last.fifteen.days',
    value: 15
  },
  {
    labelKey: 'time.filter.last.thirty.days',
    value: 30
  }
]

export const round = (value, precision) => {
  const multiplier = Math.pow(10, precision || 0)
  return Math.round(value * multiplier) / multiplier
}

export const millisToMinutesAndSeconds = (millis) => {
  if (millis === null || millis === '' || isNaN(millis)) {
    return '--.--.--'
  } else {
    const millisPositive = Math.abs(millis)
    const minutes = Math.floor(millisPositive / 60000)
    const seconds = ((millisPositive % 60000) / 1000).toFixed(2)
    return (millis < 0 ? '-' : Number(millis) === 0 ? '' : '+') + (minutes < 10 ? '0' : '') + minutes + '.' + (seconds < 10 ? '0' : '') + seconds
  }
}

export const getPdfFileData = (filePath, apiKey, token) => {
  return { url: filePath, httpHeaders: { 'x-functions-key': apiKey, Authorization: `Bearer ${token}` } }
}
