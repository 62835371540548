import React, { useState, useEffect, useRef } from 'react'
import './DoughnutChart.scss'
import Chart from 'chart.js'

const CHART_RADIUS_BACKGROUND_COLOR = '#F5F5F5'
const TOOLTIP_LABEL_MAX_LENGTH = 25

const createLegend = (chart, showValuesInLegend) => {
  const text = []
  text.push('<ul>')
  for (let i = 0; i < chart.data.datasets[0].data.length; i++) {
    const labelColor = chart.data.datasets[0].backgroundColor[i]
    const labelName = chart.data.labels[i]
    const labelValue = chart.data.datasets[0].data[i]
    text.push('<li>')
    text.push('<span class="label-color" style="background-color:' + labelColor + '"></span>')
    text.push('<span>' + (showValuesInLegend ? `${labelName} (${labelValue})` : labelName) + '</span>')
    text.push('</li>')
  }
  text.push('</ul>')
  return text.join('')
}

function DoughnutChart (props) {
  const { heading, data, label, legendPositionClass, showValuesInLegend } = props
  const chartContainer = useRef(null)
  const [legendHtml, setLegendHtml] = useState(null)
  const [chartInstance, setChartInstance] = useState(null)

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const newChartInstance = new Chart(chartContainer.current)
      setChartInstance(newChartInstance)
    }
  }, [chartContainer])

  useEffect(() => {
    if (chartInstance) {
      chartInstance.config.type = 'doughnut'
      chartInstance.aspectRatio = 1
      chartInstance.config.data = data.data
      chartInstance.config.data.datasets[0].backgroundColor = data.colors
      chartInstance.options = {
        radiusBackground: {
          color: CHART_RADIUS_BACKGROUND_COLOR
        },
        legend: false,
        cutoutPercentage: 70,
        elements: {
          center: {
            text: label
          }
        },
        legendCallback: (chart) => {
          return createLegend(chart, showValuesInLegend)
        },
        tooltips: {
          displayColors: false,
          callbacks: {
            label: function (tooltipItem, data) {
              const label = data.labels[tooltipItem.index]
              const value = data.datasets[0].data[tooltipItem.index]
              let tooltipText = label + ': ' + value
              if (label.length > TOOLTIP_LABEL_MAX_LENGTH) {
                const multilineLabel = label.split(' ')
                const lastWordIndex = multilineLabel.length - 1
                multilineLabel[lastWordIndex] = multilineLabel[lastWordIndex] + ': ' + value
                tooltipText = multilineLabel
              }
              return tooltipText
            }
          }
        }
      }
      chartInstance.update(0)
    }
  }, [chartInstance, data, label, showValuesInLegend])

  useEffect(() => {
    if (chartInstance) {
      setLegendHtml({ __html: chartInstance.generateLegend() })
    }
  }, [chartInstance, data])

  return (
    <>
      {heading &&
        <h3>{heading}</h3>}
      <div className={`doughnut-chart ${legendPositionClass}`}>
        <div className='chart'>
          <canvas ref={chartContainer} />
        </div>
        {chartInstance && legendHtml &&
          <div className='legend' dangerouslySetInnerHTML={legendHtml} />}
      </div>
    </>
  )
}

export default DoughnutChart
