import { ACTIVATE_REPORT_DATA } from './actionTypes'
import { activateData } from '../general/mutations'
import { RELIABILITY_ID } from '../../util/reportUtil'

const initialState = {
  active: RELIABILITY_ID
}

const reports = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVATE_REPORT_DATA: {
      const { dataId } = action.payload
      return activateData(state, dataId)
    }
    default:
      return state
  }
}

export default reports
