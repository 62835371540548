import React from 'react'
import { useTranslation } from 'react-i18next'
import { EQUIPMENT_ID } from '../../store/equipment/reducers'
import equipmentService from '../../services/equipmentService'
import {
  TABLE_CELL_TYPE_COLLAPSE_ARRAY,
  TABLE_CELL_TYPE_DATE,
  TABLE_CELL_TYPE_DATE_TIME,
  TABLE_CELL_TYPE_DEADLINE,
  TABLE_CELL_TYPE_EXTERNAL_LINK,
  TABLE_CELL_TYPE_LINK,
  TABLE_CELL_TYPE_ONLINE_STATUS,
  TABLE_CELL_TYPE_PERCENTAGE,
  TABLE_CELL_TYPE_POPUP,
  TABLE_CELL_TYPE_SEVERITY_TYPED_TEXT,
  TABLE_CELL_TYPE_STATUS,
  TABLE_CELL_TYPE_STATUS_COUNT,
  TABLE_CELL_TYPE_TEXT,
  SCROLLABLE_SUBTABLE_MAX_ROWS,
  TABLE_CELL_TYPE_TRAM_LINE,
  TABLE_CELL_TYPE_FLOAT_SINGLE_DECIMAL
} from '../../util/constants'
import {
  getLocaleDateString,
  getLocaleTimeString,
  isDeadlineExceeded,
  getStatus,
  round
} from '../../util/commonUtil'
import Map from '@material-ui/icons/Map'
import OpenInNew from '@material-ui/icons/OpenInNew'
import Warning from '@material-ui/icons/Warning'
import SimpleTable from '../SimpleTable/SimpleTable'

function EnhancedTableCellContent (props) {
  const { dataId, row, cell } = props
  const { t } = useTranslation()

  const getStatusColorByCount = (dataId, count) => {
    let color = ''
    if (dataId === EQUIPMENT_ID) {
      color = equipmentService.getEquipmentOpenMalfunctionsCountStatusColor(count)
    }
    return color
  }

  const getCellContent = (row, cell) => {
    const cellType = cell.type
    const cellId = cell.id
    const rowValue = row[cell.id]

    if (cellType === TABLE_CELL_TYPE_TEXT) {
      return (
        rowValue ||
          <span className='text-muted'>{t('item.undefined')}</span>
      )
    } else if (cellType === TABLE_CELL_TYPE_FLOAT_SINGLE_DECIMAL) {
      return (
        round(rowValue, 1) ||
          <span className='text-muted'>{t('item.undefined')}</span>
      )
    } else if (cellType === TABLE_CELL_TYPE_DATE) {
      return getLocaleDateString(rowValue)
    } else if (cellType === TABLE_CELL_TYPE_DATE_TIME) {
      return getLocaleDateString(rowValue) + ' ' + getLocaleTimeString(rowValue)
    } else if (cellType === TABLE_CELL_TYPE_DEADLINE) {
      const dateString = getLocaleDateString(rowValue)
      return (
        isDeadlineExceeded(rowValue, row.status)
          ? <div className='graphic-info deadline-exceeded'><Warning />{dateString}</div>
          : dateString
      )
    } else if (cellType === TABLE_CELL_TYPE_LINK) {
      let cellElement = (
        <span className='text-muted'>
          {t('no.assignments')}
        </span>
      )
      if (rowValue.length > 0) {
        cellElement = (
          <a href={rowValue}>
            {t('show.assignments')}
          </a>
        )
      }
      return cellElement
    } else if (cellType === TABLE_CELL_TYPE_STATUS) {
      return (
        <span className={'status ' + getStatus(rowValue)?.color}>
          {getStatus(rowValue)?.tKey ? t(getStatus(rowValue)?.tKey) : rowValue}
        </span>
      )
    } else if (cellType === TABLE_CELL_TYPE_POPUP) {
      // TODO Button to show popup map with points
      return (
        <button type='button' className='btn-link'>
          {t('show.on.map')}<Map />
        </button>
      )
    } else if (cellType === TABLE_CELL_TYPE_EXTERNAL_LINK) {
      return (
        <a href={rowValue} target='_blank' rel='noopener noreferrer'>
          {t('go.to.' + cellId)}<OpenInNew />
        </a>
      )
    } else if (cellType === TABLE_CELL_TYPE_SEVERITY_TYPED_TEXT) {
      // TODO Logic to define severity (=add icon)
      return (
        <span className='graphic-info'>
          <Warning />{rowValue}
        </span>
      )
    } else if (cellType === TABLE_CELL_TYPE_ONLINE_STATUS) {
      return (
        <span className={'online-status ' + getStatus(rowValue)?.color}>
          {getStatus(rowValue)?.tKey ? t(getStatus(rowValue)?.tKey) : rowValue}
        </span>
      )
    } else if (cellType === TABLE_CELL_TYPE_PERCENTAGE) {
      return rowValue ? rowValue + ' %' : '-'
    } else if (cellType === TABLE_CELL_TYPE_STATUS_COUNT) {
      return (
        <span className={'status count ' + getStatusColorByCount(dataId, rowValue)}>
          {rowValue}
        </span>
      )
    } else if (cellType === TABLE_CELL_TYPE_TRAM_LINE) {
      return (
        rowValue ||
          <span className='text-muted'>{t('tram.not.in.line')}</span>
      )
    } else if (cellType === TABLE_CELL_TYPE_COLLAPSE_ARRAY) {
      const cellHeadingKey = cell.headingKey
      const cellSubHeadCells = cell.headCells
      const cellSubCells = cell.cells
      const subTableMaxRowsExceeded = rowValue.length > SCROLLABLE_SUBTABLE_MAX_ROWS
      return <SimpleTable headingKey={cellHeadingKey} headCells={cellSubHeadCells} cells={cellSubCells} data={rowValue} scrollable={subTableMaxRowsExceeded} />
    }
  }

  return (
    <>
      {getCellContent(row, cell)}
    </>
  )
}

export default EnhancedTableCellContent
