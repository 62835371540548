import {
  SET_TRAM_DATA,
  SET_TRAM_WS_DATA_LOCATION,
  SET_TRAM_WS_DATA_PASSENGER,
  EMPTY_TRAM_DATA,
  SET_TRAM_WS_DATA_LINE,
  SET_MAP_DATA_SUBSET,
  ACTIVATE_MAP_DATA_SUBSET,
  DEACTIVATE_MAP_DATA_SUBSET,
  TOGGLE_MAP_DATA_ACTIVITY,
  SET_TRAM_DATA_CONNECTION_ERROR,
  SET_TRAM_DATA_EXPIRY_STATUS
} from './actionTypes'
import {
  setDataSubset,
  activateDataSubset,
  deactivateDataSubset
} from '../general/mutations'
import {
  hasTramLocationTimestampExpired
} from '../../util/tramUtil'
export const TRAM_ID = 'tram'
export const INFRA_ID = 'infra'
export const INFRA_TRAM_STOP_ID = 'tram_stop'
export const INFRA_RAIL_SWITCH_ID = 'rail_switch'
export const INFRA_RAIL_INTERSECTION_ID = 'rail_intersection'
export const INFRA_CHARGING_STATION_ID = 'charging_station'
export const INFRA_TRAM_STOP_CENTER_ID = 'tram_stop_center'
export const INFRA_RAIL_SWITCH_HEATING_CENTER_ID = 'rail_switch_heating_center'
export const INFRA_RAIL_SWITCH_CONTROL_CENTER_ID = 'rail_switch_control_center'
export const INFRA_BRIDGE_ID = 'bridge'
export const INFRA_RETAINING_WALL_ID = 'retaining_wall'
export const INFRA_CAPACITOR_ID = 'capacitor'
export const INFRA_DISCONNECTOR_ID = 'disconnector'

const initialState = {
  [TRAM_ID]: {
    active: true,
    locationData: [],
    passengerData: [],
    lineData: [],
    tramMetadata: [],
    connectionError: false
  },
  [INFRA_ID]: {
    active: [
      INFRA_TRAM_STOP_ID
    ],
    [INFRA_TRAM_STOP_ID]: {
      data: []
    },
    [INFRA_RAIL_SWITCH_ID]: {
      data: []
    },
    [INFRA_RAIL_INTERSECTION_ID]: {
      data: []
    },
    [INFRA_CHARGING_STATION_ID]: {
      data: []
    },
    [INFRA_TRAM_STOP_CENTER_ID]: {
      data: []
    },
    [INFRA_RAIL_SWITCH_HEATING_CENTER_ID]: {
      data: []
    },
    [INFRA_RAIL_SWITCH_CONTROL_CENTER_ID]: {
      data: []
    },
    [INFRA_BRIDGE_ID]: {
      data: []
    },
    [INFRA_RETAINING_WALL_ID]: {
      data: []
    },
    [INFRA_CAPACITOR_ID]: {
      data: []
    },
    [INFRA_DISCONNECTOR_ID]: {
      data: []
    }
  }
}

const map = (state = initialState, action) => {
  switch (action.type) {
    case SET_TRAM_DATA: {
      const { dataId, data } = action.payload
      // Check if data is newer than already in state, if websocket is already providing data
      const newLocationData = replaceCurrentDataIfReceivedNewer([...state[dataId].locationData], data.locationData)
      const newPassengerData = replaceCurrentDataIfReceivedNewer([...state[dataId].passengerData], data.passengerData)
      const newLineData = replaceCurrentDataIfReceivedNewer([...state[dataId].lineData], data.lineData)
      const tramMetadata = replaceCurrentDataIfReceivedNewer([...state[dataId].tramMetadata], data.tramMetadata)
      setTramExpiryStatuses(newLocationData)
      return {
        ...state,
        [dataId]: {
          ...state[dataId],
          locationData: newLocationData,
          passengerData: newPassengerData,
          lineData: newLineData,
          tramMetadata: tramMetadata
        }
      }
    }
    case SET_TRAM_WS_DATA_LOCATION: {
      const { dataId, data } = action.payload
      setTramExpiryStatuses(data)
      const newData = updateCurrentDataAndAddNew([...state[dataId].locationData], data)
      return {
        ...state,
        [dataId]: {
          ...state[dataId],
          locationData: newData
        }
      }
    }
    case SET_TRAM_WS_DATA_PASSENGER: {
      const { dataId, data } = action.payload
      const newData = updateCurrentDataAndAddNew([...state[dataId].passengerData], data)
      return {
        ...state,
        [dataId]: {
          ...state[dataId],
          passengerData: newData
        }
      }
    }
    case SET_TRAM_WS_DATA_LINE: {
      const { dataId, data } = action.payload
      const newData = updateCurrentDataAndAddNew([...state[dataId].lineData], data)
      return {
        ...state,
        [dataId]: {
          ...state[dataId],
          lineData: newData
        }
      }
    }
    case EMPTY_TRAM_DATA: {
      const { dataId } = action.payload
      return {
        ...state,
        [dataId]: {
          ...state[dataId],
          locationData: [],
          passengerData: [],
          lineData: []
        }
      }
    }
    case SET_TRAM_DATA_CONNECTION_ERROR: {
      const { dataId, isError } = action.payload
      return {
        ...state,
        [dataId]: {
          ...state[dataId],
          connectionError: isError
        }
      }
    }
    case SET_TRAM_DATA_EXPIRY_STATUS: {
      const { dataId, tramId, status } = action.payload
      const newData = setTramExpiryStatus([...state[dataId].locationData], tramId, status)
      return {
        ...state,
        [dataId]: {
          ...state[dataId],
          locationData: newData
        }
      }
    }
    case SET_MAP_DATA_SUBSET: {
      const { dataId, subsetId, data } = action.payload
      return setDataSubset(state, dataId, subsetId, data)
    }
    case ACTIVATE_MAP_DATA_SUBSET: {
      const { dataId, subsetId } = action.payload
      return activateDataSubset(state, dataId, subsetId)
    }
    case DEACTIVATE_MAP_DATA_SUBSET: {
      const { dataId, subsetId } = action.payload
      return deactivateDataSubset(state, dataId, subsetId)
    }
    // TODO: TEMPORARY UNTIL TRO-289
    case TOGGLE_MAP_DATA_ACTIVITY: {
      const { dataId } = action.payload
      return {
        ...state,
        [dataId]: {
          ...state[dataId],
          active: !state[dataId].active
        }
      }
    }
    default:
      return state
  }
}

const updateCurrentDataAndAddNew = (currentStateData, receivedData) => {
  if (currentStateData && currentStateData.length > 0) {
    // get existing data and merge updated data items if found
    const existingItemsUpdated = currentStateData.map(currentItem => {
      const itemToUpdate = receivedData.find(receivedItem => receivedItem.tramId === currentItem.tramId)
      if (itemToUpdate) {
        return Object.assign(currentItem, itemToUpdate)
      }
      return currentItem
    })
    // Gather all new data (not in the state already) for concatenation
    const newItems = receivedData.filter(newItem => currentStateData.findIndex(currentItem => currentItem.tramId === newItem.tramId) === -1)

    return existingItemsUpdated.concat(newItems)
  }
  return receivedData
}

const replaceCurrentDataIfReceivedNewer = (currentStateData, receivedData) => {
  if (currentStateData && currentStateData.length > 0) {
    // get existing data and replace updated data items if found
    const existingItems = currentStateData.map(item => receivedData.find(itemToUpdate => itemToUpdate.tramId === item.tramId &&
      itemToUpdate.timestamp > item.timestamp) || item)

    // Gather all new data (not in the state already) for concatenation
    const newItems = receivedData.filter(newItem => currentStateData.findIndex(currentItem => currentItem.tramId === newItem.tramId) === -1)
    return existingItems.concat(newItems)
  }
  return receivedData
}

const setTramExpiryStatuses = (currentStateData) => {
  if (currentStateData && currentStateData.length > 0) {
    currentStateData.map(currentItem => {
      if (currentItem.timestamp) {
        currentItem.dataExpired = hasTramLocationTimestampExpired(currentItem.dataFetchTimestamp, currentItem.timestamp)
      }
      return currentItem
    })
  }
  return currentStateData
}

const setTramExpiryStatus = (data, tramId, status) => {
  const foundIndex = data.findIndex(item => item.tramId === tramId)
  if (foundIndex !== -1) {
    data[foundIndex].dataExpired = status
  }
  return data
}

export default map
