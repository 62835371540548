import {
  TABLE_CELL_TYPE_DATE_TIME,
  TABLE_CELL_TYPE_DATE,
  TABLE_CELL_TYPE_STATUS,
  TABLE_CELL_TYPE_TEXT,
  TABLE_CELL_TYPE_FLOAT_SINGLE_DECIMAL,
  TABLE_CELL_TYPE_ONLINE_STATUS,
  TABLE_CELL_TYPE_PERCENTAGE,
  TABLE_CELL_TYPE_STATUS_COUNT,
  TABLE_CELL_TYPE_COLLAPSE_ARRAY,
  TABLE_CELL_TYPE_TRAM_LINE
} from './constants'

export const EQUIPMENT_OPEN_MALFUNCTIONS_STATUS_OK_COUNT = 0
export const EQUIPMENT_OPEN_MALFUNCTIONS_STATUS_WARNING_COUNT = 4

const equipmentMalfunctionHistoryTableHeadCells = [
  { id: 'repairedTimestamp', labelKey: 'date.shortened' },
  { id: 'workCode', labelKey: 'code' },
  { id: 'description', labelKey: 'description' }
]

const equipmentMalfunctionHistoryTableCells = [
  { id: 'repairedTimestamp', type: TABLE_CELL_TYPE_DATE },
  { id: 'workCode', type: TABLE_CELL_TYPE_TEXT },
  { id: 'description', type: TABLE_CELL_TYPE_TEXT }
]

const equipmentOpenMalfunctionsTableHeadCells = [
  { id: 'workCode', labelKey: 'code' },
  { id: 'createTimestamp', labelKey: 'creation.date' },
  { id: 'status', labelKey: 'handling.status' },
  { id: 'description', labelKey: 'description' }
]

const equipmentOpenMalfunctionsTableCells = [
  { id: 'workCode', type: TABLE_CELL_TYPE_TEXT },
  { id: 'createTimestamp', type: TABLE_CELL_TYPE_DATE },
  { id: 'status', type: TABLE_CELL_TYPE_STATUS },
  { id: 'description', type: TABLE_CELL_TYPE_TEXT }
]

const equipmentTableHeadCells = [
  { id: 'tramId', labelKey: 'carriage', sortable: true },
  { id: 'tramName', labelKey: 'name', sortable: true },
  { id: 'status', labelKey: 'status', sortable: true },
  { id: 'tramLine', labelKey: 'tram.line', sortable: true },
  { id: 'drivenKm', labelKey: 'kilometers.driven', sortable: true },
  { id: 'capacity', labelKey: 'capacity.usage', sortable: true },
  { id: 'openMalfunctionsCount', labelKey: 'open.malfunctions', sortable: true }
]

const equipmentTableCells = [
  { id: 'tramId', type: TABLE_CELL_TYPE_TEXT },
  { id: 'tramName', type: TABLE_CELL_TYPE_TEXT },
  { id: 'status', type: TABLE_CELL_TYPE_ONLINE_STATUS },
  { id: 'tramLine', type: TABLE_CELL_TYPE_TRAM_LINE },
  { id: 'drivenKm', type: TABLE_CELL_TYPE_FLOAT_SINGLE_DECIMAL },
  { id: 'capacity', type: TABLE_CELL_TYPE_PERCENTAGE },
  { id: 'openMalfunctionsCount', type: TABLE_CELL_TYPE_STATUS_COUNT },
  {
    id: 'malfunctionHistory',
    type: TABLE_CELL_TYPE_COLLAPSE_ARRAY,
    headingKey: 'maintenance.history',
    headCells: equipmentMalfunctionHistoryTableHeadCells,
    cells: equipmentMalfunctionHistoryTableCells,
    collapseCell: true
  },
  {
    id: 'openMalfunctions',
    type: TABLE_CELL_TYPE_COLLAPSE_ARRAY,
    headingKey: 'open.malfunctions',
    headCells: equipmentOpenMalfunctionsTableHeadCells,
    cells: equipmentOpenMalfunctionsTableCells,
    collapseCell: true
  }
]

const equipmentMalfunctionsTableHeadCells = [
  { id: 'tramId', labelKey: 'carriage', sortable: true },
  { id: 'tramName', labelKey: 'name', sortable: true },
  { id: 'malfunctionCode', labelKey: 'malfunction.code', sortable: true },
  { id: 'createTimestamp', labelKey: 'creation.date', sortable: true },
  { id: 'repairedTimestamp', labelKey: 'fixed', sortable: true },
  { id: 'description', labelKey: 'description', sortable: false },
  { id: 'malfunctionType', labelKey: 'malfunction.type', sortable: true },
  { id: 'processingStatus', labelKey: 'handling.status', sortable: true }
]

const equipmentMalfunctionsTableCells = [
  { id: 'tramId', type: TABLE_CELL_TYPE_TEXT },
  { id: 'tramName', type: TABLE_CELL_TYPE_TEXT },
  { id: 'malfunctionCode', type: TABLE_CELL_TYPE_TEXT },
  { id: 'createTimestamp', type: TABLE_CELL_TYPE_DATE_TIME },
  { id: 'repairedTimestamp', type: TABLE_CELL_TYPE_DATE_TIME },
  { id: 'description', type: TABLE_CELL_TYPE_TEXT },
  { id: 'malfunctionType', type: TABLE_CELL_TYPE_TEXT },
  { id: 'processingStatus', type: TABLE_CELL_TYPE_STATUS }
]

const EQUIPMENT_MALFUNCTIONS_SUMMARY_CATEGORY_RESTRICTION_OOL_24H = 'restrictionOutOfLineLast24h'
const EQUIPMENT_MALFUNCTIONS_SUMMARY_CATEGORY_RESTRICTION_OTHERS_24H = 'restrictionOthersLast24h'
const EQUIPMENT_MALFUNCTIONS_SUMMARY_CATEGORY_RESTRICTION_LATE_24H = 'restrictionArrivingLateLast24h'

const equipmentMalfunctionsTableFilters = [
  {
    id: 'tramId',
    legendKey: 'carriage',
    filters: []
  },
  {
    id: 'malfunctionType',
    legendKey: 'malfunction.type',
    filters: []
  },
  {
    id: 'processingStatus',
    legendKey: 'handling.status',
    filters: []
  },
  {
    id: 'summaryCategories',
    legendKey: 'summary',
    filters: [
      EQUIPMENT_MALFUNCTIONS_SUMMARY_CATEGORY_RESTRICTION_OOL_24H,
      EQUIPMENT_MALFUNCTIONS_SUMMARY_CATEGORY_RESTRICTION_OTHERS_24H,
      EQUIPMENT_MALFUNCTIONS_SUMMARY_CATEGORY_RESTRICTION_LATE_24H
    ]
  }
]

const equipmentMaintenancesTableHeadCells = [
  { id: 'tramId', labelKey: 'carriage', sortable: true },
  { id: 'tramName', labelKey: 'name', sortable: true },
  { id: 'maintenanceType', labelKey: 'maintenance.type', sortable: true },
  { id: 'datePlanned', labelKey: 'planned', sortable: true },
  { id: 'dateDone', labelKey: 'done', sortable: true },
  { id: 'status', labelKey: 'status', sortable: true },
  { id: 'description', labelKey: 'description', sortable: false },
  { id: 'kilometersDriven', labelKey: 'kilometers.driven', sortable: true }
]

const equipmentMaintenancesTableCells = [
  { id: 'tramId', type: TABLE_CELL_TYPE_TEXT },
  { id: 'tramName', type: TABLE_CELL_TYPE_TEXT },
  { id: 'maintenanceType', type: TABLE_CELL_TYPE_TEXT },
  { id: 'datePlanned', type: TABLE_CELL_TYPE_DATE },
  { id: 'dateDone', type: TABLE_CELL_TYPE_DATE },
  { id: 'status', type: TABLE_CELL_TYPE_STATUS },
  { id: 'description', type: TABLE_CELL_TYPE_TEXT },
  { id: 'kilometersDriven', type: TABLE_CELL_TYPE_FLOAT_SINGLE_DECIMAL }
]

const EQUIPMENT_MAINTENANCES_SUMMARY_CATEGORY_PLANNED_NEXT_24H = 'plannedMaintenanceNext24h'
const EQUIPMENT_MAINTENANCES_SUMMARY_CATEGORY_COMPLETED_LAST_24H = 'completedMaintenanceLast24h'
const EQUIPMENT_MAINTENANCES_SUMMARY_CATEGORY_OPEN_MALFUNCTIONS = 'openMalfunctions'
const EQUIPMENT_MAINTENANCES_SUMMARY_CATEGORY_RESTRICTED_TRAMS = 'restrictedTrams'
const EQUIPMENT_MAINTENANCES_SUMMARY_CATEGORY_REPAIRED_FAULTS_WITHIN_24H = 'repairedFaultsWithin24h'

const equipmentMaintenancesTableFilters = [
  {
    id: 'tramId',
    legendKey: 'carriage',
    filters: []
  },
  {
    id: 'maintenanceType',
    legendKey: 'maintenance.type',
    filters: []
  },
  {
    id: 'status',
    legendKey: 'status',
    filters: []
  },
  {
    id: 'summaryCategories',
    legendKey: 'summary',
    filters: [
      EQUIPMENT_MAINTENANCES_SUMMARY_CATEGORY_PLANNED_NEXT_24H,
      EQUIPMENT_MAINTENANCES_SUMMARY_CATEGORY_COMPLETED_LAST_24H,
      EQUIPMENT_MAINTENANCES_SUMMARY_CATEGORY_OPEN_MALFUNCTIONS,
      EQUIPMENT_MAINTENANCES_SUMMARY_CATEGORY_RESTRICTED_TRAMS,
      EQUIPMENT_MAINTENANCES_SUMMARY_CATEGORY_REPAIRED_FAULTS_WITHIN_24H
    ]
  }
]

const equipmentStatusSummaryLabels = {
  'status.label.in.traffic': 'Liikenteessä',
  'status.label.available.for.traffic': 'Vapaana liikenteeseen',
  'status.label.restricted': 'Käyttökiellossa',
  'status.label.in.maintenance': 'Huollossa'
}

const equipmentStatusSummaryLabelColors = {
  'status.label.in.traffic.color': '#72C140',
  'status.label.available.for.traffic.color': '#2BA3E7',
  'status.label.restricted.color': '#F83245',
  'status.label.in.maintenance.color': '#F6C637'
}

export {
  equipmentTableHeadCells,
  equipmentTableCells,
  equipmentMalfunctionsTableHeadCells,
  equipmentMalfunctionsTableCells,
  equipmentMalfunctionsTableFilters,
  equipmentMaintenancesTableHeadCells,
  equipmentMaintenancesTableCells,
  equipmentMaintenancesTableFilters,
  equipmentStatusSummaryLabels,
  equipmentStatusSummaryLabelColors,
  EQUIPMENT_MALFUNCTIONS_SUMMARY_CATEGORY_RESTRICTION_OOL_24H,
  EQUIPMENT_MALFUNCTIONS_SUMMARY_CATEGORY_RESTRICTION_OTHERS_24H,
  EQUIPMENT_MALFUNCTIONS_SUMMARY_CATEGORY_RESTRICTION_LATE_24H,
  EQUIPMENT_MAINTENANCES_SUMMARY_CATEGORY_PLANNED_NEXT_24H,
  EQUIPMENT_MAINTENANCES_SUMMARY_CATEGORY_COMPLETED_LAST_24H,
  EQUIPMENT_MAINTENANCES_SUMMARY_CATEGORY_OPEN_MALFUNCTIONS,
  EQUIPMENT_MAINTENANCES_SUMMARY_CATEGORY_RESTRICTED_TRAMS,
  EQUIPMENT_MAINTENANCES_SUMMARY_CATEGORY_REPAIRED_FAULTS_WITHIN_24H
}
