import VectorSource from 'ol/source/Vector'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import { fromLonLat } from 'ol/proj'
import {
  POINT_TYPE_ID_KEY,
  getTramIconPointStyles,
  getInfraIconPointStyles,
  INFRA_RESOURCE_METADATA
} from '../util/mapUtil'
import {
  TRAM_ID,
  INFRA_ID
} from '../store/map/reducers'

const getTramPoints = (tramLocations, tramPassengers, tramLines, tramMeta) => {
  if (!tramLocations || tramLocations.length <= 0) return
  const vectorSource = new VectorSource()
  tramLocations.forEach((tram, index) => {
    const lon = tram.coordinates && tram.coordinates.lon
    const lat = tram.coordinates && tram.coordinates.lat
    const tramId = tram.tramId
    const directionRadian = tram.directionRad
    const speedKilometersPerHour = (tram.speedMetersPerSecond * 3.6).toFixed(1)
    const tramLocationTimestamp = tram.timestamp
    const dataExpired = tram.dataExpired
    const passengerData = tramPassengers.find(item => item.tramId === tram.tramId)
    const capacity = passengerData?.capacity
    const passengerCountingTimestamp = passengerData?.timestamp
    const prevStop = passengerData?.prevStop
    const nextStop = passengerData?.nextStop
    const lineData = tramLines.find(item => item.tramId === tram.tramId)
    const passengerCountTotal = passengerData?.passengerCountTotal
    const tramMetaData = tramMeta.find(item => item.tramId === tram.tramId)
    const tramName = tramMetaData?.tramName
    const feature = new Feature({
      geometry: new Point(fromLonLat([lon, lat])),
      [POINT_TYPE_ID_KEY]: TRAM_ID,
      tramId: tramId,
      tramName: tramName,
      capacity: capacity,
      speed: speedKilometersPerHour,
      tramLocationTimestamp: tramLocationTimestamp,
      passengerCountingTimestamp: passengerCountingTimestamp,
      prevStop: prevStop,
      nextStop: nextStop,
      tramLine: lineData?.tramLine,
      passengerCountTotal: passengerCountTotal,
      delayMs: lineData?.delayMs
    })
    const pointLabel = lineData?.tramLine ? lineData?.tramLine + '-' + tramId : tramId
    feature.setId(tramId)
    feature.setStyle(getTramIconPointStyles(pointLabel, directionRadian, index, dataExpired, lineData?.delayMs))
    vectorSource.addFeature(feature)
  })
  return vectorSource
}

const getInfraPoints = (infras, infraId) => {
  if (!infras || infras.length <= 0) return
  const vectorSource = new VectorSource()
  Object.keys(infras).forEach(function (key) {
    const lon = infras[key].coordinates.lon
    const lat = infras[key].coordinates.lat
    const name = infras[key].name
    const resourceType = infras[key].resourceType
    const deadlineExceededCount = infras[key].deadlineExceededCount
    const openCount = infras[key].openCount
    const feature = new Feature({
      geometry: new Point(fromLonLat([lon, lat])),
      [POINT_TYPE_ID_KEY]: INFRA_ID,
      infraId: infraId,
      name: name,
      resourceType: resourceType,
      icon: INFRA_RESOURCE_METADATA[infraId].icon,
      deadlineExceededCount: deadlineExceededCount,
      openCount: openCount
    })
    feature.setStyle(getInfraIconPointStyles(infraId))
    vectorSource.addFeature(feature)
  })
  return vectorSource
}

export default {
  getTramPoints,
  getInfraPoints
}
