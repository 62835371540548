import {
  infraMaintenanceStatusSummaryLabels,
  infraMaintenanceStatusSummaryLabelColors,
  infraMaintenanceByDeadlineSummaryLabels,
  infraMaintenanceByDeadlineSummaryLabelColors,
  infraMaintenanceCriticalMalfunctionsSummaryLabels,
  infraMaintenanceCriticalMalfunctionsSummaryLabelColors,
  infraTableHeadCells,
  infraTableCells,
  infraMalfunctionsTableFilters,
  infraMaintenancesTableFilters
} from '../util/infraUtil'
import { createFilterList } from '../util/filterUtil'
import axios from 'axios'

const getInfraByResourceType = async (resourceType) => {
  const url = `/infrastructure-assets?resourceType=${resourceType}`
  const response = await axios.get(url)
  return response.data
}

const getInfraMaintenanceData = async (type, status, deadlineInDays, deadlineExceeded, closedAtLimitDays) => {
  let url = `infrastructure-events?type=${type}&status=${status}`
  if (deadlineExceeded) {
    url += '&deadlineExceeded=true'
  }
  if (deadlineInDays) {
    url += `&deadlineInDays=${deadlineInDays}`
  }
  if (closedAtLimitDays) {
    url += `&closedAtLimitDays=${closedAtLimitDays}`
  }
  const response = await axios.get(url)
  return response.data
}

const getInfraMaintenanceSummary = async (deadlineInDays, closedAtLimitDays) => {
  const url = 'infrastructure-events-summary?othersDeadlineInDays=true&maintenanceDeadlineInDays=true' +
    '&maintenanceDeadlineExceeded=true&maintenanceCompleted=true&malfunctionsCompleted=true&malfunctionsOpen=true' +
    '&maintenanceOpenByPriorities=true&maintenanceOpenByDeadlines=true&malfunctionsOpenByPriorities=true' +
     `&closedAtLimitDays=${closedAtLimitDays}&deadlineInDays=${deadlineInDays}`

  const response = await axios.get(url)
  return response.data
}

const getInfraMalfunctionsSummary = async (closedAtLimitDays) => {
  const url = 'infrastructure-events-summary?malfunctionsCompleted=true&malfunctionsOpen=true' +
  `&malfunctionsOpenByPriorities=true&closedAtLimitDays=${closedAtLimitDays}`

  const response = await axios.get(url)
  return response.data
}

const constructSummaryForLabel = (retValue, amount, label, color) => {
  if (amount > 0) {
    retValue.data.labels.push(label)
    retValue.data.datasets[0].data.push(amount)
    retValue.colors.push(color)
  }
}

const getInfraMaintenanceStatusSummary = (data) => {
  const infraMaintenanceStatusDataSummary = { data: { labels: [], datasets: [{ data: [] }] }, colors: [] }
  constructSummaryForLabel(infraMaintenanceStatusDataSummary, data && data.maintenanceOpenByHighestPriority,
    infraMaintenanceStatusSummaryLabels['status.label.acute'], infraMaintenanceStatusSummaryLabelColors['status.label.acute.color'])
  constructSummaryForLabel(infraMaintenanceStatusDataSummary, data && data.maintenanceOpenByMediumPriority,
    infraMaintenanceStatusSummaryLabels['status.label.urgent'], infraMaintenanceStatusSummaryLabelColors['status.label.urgent.color'])
  constructSummaryForLabel(infraMaintenanceStatusDataSummary, data && data.maintenanceOpenByLowPriority,
    infraMaintenanceStatusSummaryLabels['status.label.not.urgent'], infraMaintenanceStatusSummaryLabelColors['status.label.not.urgent.color'])
  constructSummaryForLabel(infraMaintenanceStatusDataSummary, data && data.maintenanceOpenByNoPriority,
    infraMaintenanceStatusSummaryLabels['status.label.no.priority'], infraMaintenanceStatusSummaryLabelColors['status.label.no.priority.color'])

  return infraMaintenanceStatusDataSummary
}

const getInfraMaintenanceByDeadlineSummary = (data) => {
  const infraMaintenanceByDeadlineDataSummary = { data: { labels: [], datasets: [{ data: [] }] }, colors: [] }
  constructSummaryForLabel(infraMaintenanceByDeadlineDataSummary, data && data.maintenanceOpenByDeadlineIn3days,
    infraMaintenanceByDeadlineSummaryLabels['deadline.label.under.three.days'],
    infraMaintenanceByDeadlineSummaryLabelColors['deadline.label.under.three.days.color'])
  constructSummaryForLabel(infraMaintenanceByDeadlineDataSummary, data && data.maintenanceOpenByDeadlineIn3_7days,
    infraMaintenanceByDeadlineSummaryLabels['deadline.label.three.to.seven.days'],
    infraMaintenanceByDeadlineSummaryLabelColors['deadline.label.three.to.seven.days.color'])
  constructSummaryForLabel(infraMaintenanceByDeadlineDataSummary, data && data.maintenanceOpenByDeadlineIn8_15days,
    infraMaintenanceByDeadlineSummaryLabels['deadline.label.eight.to.fifteen.days'],
    infraMaintenanceByDeadlineSummaryLabelColors['deadline.label.eight.to.fifteen.days.color'])
  constructSummaryForLabel(infraMaintenanceByDeadlineDataSummary, data && data.maintenanceOpenByDeadlineIn16_60days,
    infraMaintenanceByDeadlineSummaryLabels['deadline.label.sixteen.to.sixty.days'],
    infraMaintenanceByDeadlineSummaryLabelColors['deadline.label.sixteen.to.sixty.days.color'])
  constructSummaryForLabel(infraMaintenanceByDeadlineDataSummary, data && data.maintenanceDeadlineExceeded,
    infraMaintenanceByDeadlineSummaryLabels['deadline.label.late'],
    infraMaintenanceByDeadlineSummaryLabelColors['deadline.label.late.color'])

  return infraMaintenanceByDeadlineDataSummary
}

const getInfraMaintenanceCriticalMalfunctionsSummary = (data) => {
  const infraMaintenanceCriticalMalfunctionsDataSummary = { data: { labels: [], datasets: [{ data: [] }] }, colors: [] }
  constructSummaryForLabel(infraMaintenanceCriticalMalfunctionsDataSummary, data && data.malfunctionsOpenByHighestPriority,
    infraMaintenanceCriticalMalfunctionsSummaryLabels['critical.malfunction.prevents.operating'],
    infraMaintenanceCriticalMalfunctionsSummaryLabelColors['critical.malfunction.prevents.operating.color'])
  constructSummaryForLabel(infraMaintenanceCriticalMalfunctionsDataSummary, data && data.malfunctionsOpenByMediumPriority,
    infraMaintenanceCriticalMalfunctionsSummaryLabels['critical.malfunction.limited.operation'],
    infraMaintenanceCriticalMalfunctionsSummaryLabelColors['critical.malfunction.limited.operation.color'])

  return infraMaintenanceCriticalMalfunctionsDataSummary
}

const getInfraMaintenanceStatusSummaryAmount = (data) => {
  return data ? data.maintenanceOpenByHighestPriority + data.maintenanceOpenByMediumPriority +
    data.maintenanceOpenByLowPriority + data.maintenanceOpenByNoPriority : 0
}

const getInfraMaintenanceByDeadlineSummaryAmount = (data) => {
  return data ? data.maintenanceOpenByDeadlineIn3days + data.maintenanceOpenByDeadlineIn3_7days +
    data.maintenanceOpenByDeadlineIn8_15days + data.maintenanceOpenByDeadlineIn16_60days +
    data.maintenanceDeadlineExceeded : 0
}

const getInfraMaintenanceCriticalMalfunctionsAmount = (data) => {
  return data ? data.malfunctionsOpenByHighestPriority + data.malfunctionsOpenByMediumPriority : 0
}

const getInfraTableHeadCells = () => {
  return infraTableHeadCells
}

const getInfraTableCells = () => {
  return infraTableCells
}

const getInfraMalfunctionsTableFilters = (data) => {
  return createFilterList(data, infraMalfunctionsTableFilters)
}

const getInfraMaintenancesTableFilters = (data) => {
  return createFilterList(data, infraMaintenancesTableFilters)
}

export default {
  getInfraByResourceType,
  getInfraMaintenanceStatusSummary,
  getInfraMaintenanceByDeadlineSummary,
  getInfraMaintenanceCriticalMalfunctionsSummary,
  getInfraTableHeadCells,
  getInfraTableCells,
  getInfraMalfunctionsTableFilters,
  getInfraMaintenancesTableFilters,
  getInfraMaintenanceData,
  getInfraMaintenanceSummary,
  getInfraMalfunctionsSummary,
  getInfraMaintenanceStatusSummaryAmount,
  getInfraMaintenanceByDeadlineSummaryAmount,
  getInfraMaintenanceCriticalMalfunctionsAmount
}
