import React from 'react'
import { useTranslation } from 'react-i18next'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'

const InfraPopup = (props) => {
  const { data } = props
  const { t } = useTranslation()
  const name = data.get('name')
  const resourceType = data.get('resourceType')
  const icon = data.get('icon')
  const deadlineExceededCount = data.get('deadlineExceededCount')
  const openCount = data.get('openCount')

  return (
    <>
      <div className='ol-popup-topbar'>
        <div className='graphic-data-item'>
          <img src={icon} alt='' />
          <div>
            <span className='name'>{name}</span>
            <br />
            <div className='category text-muted'>{resourceType}</div>
          </div>
        </div>
      </div>
      <div className='ol-popup-content'>
        <div className='graphic-data-item spaced-out'>
          <span>
            {deadlineExceededCount
              ? <ErrorOutline className='color-error' />
              : <CheckCircleOutline className='color-success' />}
          </span>
          <span>{deadlineExceededCount}</span>
          <span>{t('operation.deadline.exceeded', { count: deadlineExceededCount })}</span>
        </div>
        <div className='graphic-data-item spaced-out'>
          <span>
            {openCount
              ? <ErrorOutline className='color-primary-main' />
              : <CheckCircleOutline className='color-success' />}
          </span>
          <span>{openCount}</span>
          <span>{t('operation.open', { count: openCount })}</span>
        </div>
      </div>
    </>
  )
}

export default InfraPopup
