import React from 'react'
import { useTranslation } from 'react-i18next'
import './Drawer.scss'
import Button from '@material-ui/core/Button'
import FilterListIcon from '@material-ui/icons/FilterList'

function DrawerToggle ({ text, open, setOpen, altStyle }) {
  const { t } = useTranslation()

  const handleDrawerToggle = (open) => {
    setOpen(!open)
  }

  return (
    <Button
      className={`drawer-toggle ${open ? 'active' : ''} ${altStyle ? 'alt' : ''}`}
      variant='contained'
      color='primary'
      aria-label={open ? t('close.menu') : t('open.menu')}
      onClick={() => handleDrawerToggle(open)}
    >
      <span className='btn-text'>{text}</span>
      <FilterListIcon />
    </Button>
  )
}

export default DrawerToggle
