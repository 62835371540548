import React from 'react'
import './Loader.scss'

function Loader () {
  return (
    <div className='loader' />
  )
}

export default Loader
