import infraService from '../../services/infraService'
import {
  SET_EQUIPMENT_MAINTENANCE_SUMMARY_DATA,
  SET_MAINTENANCE_DATA,
  SET_MAINTENANCE_SUMMARY_DATA,
  SET_EQUIPMENT_MAINTENANCE_DATA
} from './actionTypes'
import equipmentService from '../../services/equipmentService'

export const setMaintenanceData = (dataId, closedAtLimitDays, deadlineInDays) => {
  // TODO get parameters from the summary item filters, using now just basic all item query
  const type = 'all'
  const status = 'all'
  const deadlineExceeded = true
  return async dispatch => {
    const data = await infraService.getInfraMaintenanceData(type, status, deadlineInDays, deadlineExceeded, closedAtLimitDays)
    dispatch({
      type: SET_MAINTENANCE_DATA,
      payload: {
        dataId: dataId,
        data: data.data,
        dataTimestamp: data.dataTimestamp
      }
    })
  }
}

export const setMaintenanceSummaryData = (dataId, closedAtLimitDays, deadlineInDays) => {
  return async dispatch => {
    const data = await infraService.getInfraMaintenanceSummary(deadlineInDays, closedAtLimitDays)
    dispatch({
      type: SET_MAINTENANCE_SUMMARY_DATA,
      payload: {
        dataId: dataId,
        summaryData: data.data
      }
    })
  }
}

export const setEquipmentMaintenanceSummaryData = (dataId) => {
  return async dispatch => {
    const data = await equipmentService.getEquipmentMaintenanceSummaryData()
    dispatch({
      type: SET_EQUIPMENT_MAINTENANCE_SUMMARY_DATA,
      payload: {
        dataId: dataId,
        summaryData: data.data
      }
    })
  }
}

export const setEquipmentMaintenanceData = (dataId) => {
  return async dispatch => {
    const data = await equipmentService.getEquipmentMaintenanceData()
    dispatch({
      type: SET_EQUIPMENT_MAINTENANCE_DATA,
      payload: {
        dataId: dataId,
        data: data.data,
        dataTimestamp: data.dataTimestamp
      }
    })
  }
}
