import React from 'react'
import { useTranslation } from 'react-i18next'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'

function EnhancedTableHead (props) {
  const { t } = useTranslation()
  const { headCells, order, orderBy, onRequestSort, collapsibleRows } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id && headCell.sortable ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {t(headCell.labelKey)}
                {orderBy === headCell.id ? (
                  <span className='visually-hidden'>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              <span>{t(headCell.labelKey)}</span>
            )}
          </TableCell>
        ))}
        {collapsibleRows &&
          <TableCell />}
      </TableRow>
    </TableHead>
  )
}

export default EnhancedTableHead
