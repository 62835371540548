import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'
import reportService from '../../services/reportService'
import { activateData } from '../../store/general/actions'
import { isDataActive } from '../../util/commonUtil'
import { REPORTS_SLICE_ID } from '../../store'
import { RELIABILITY_ID, CUSTOMER_SATISFACTION_ID, PASSENGER_COUNTING_ID } from '../../util/reportUtil'
import { ACTIVATE_REPORT_DATA } from '../../store/reports/actionTypes'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ArrowLeft from '@material-ui/icons/ArrowLeft'
import ArrowRight from '@material-ui/icons/ArrowRight'
import ColumnContent from '../../components/ColumnContent/ColumnContent'
import ColumnTopbar from '../../components/ColumnTopbar/ColumnTopbar'
import ColumnTopbarHeadingButton from '../../components/ColumnTopbar/ColumnTopbarHeadingButton'
import ColumnActivityIndicator from '../../components/ColumnActivityIndicator/ColumnActivityIndicator'
import AspectRatioWrapper from '../../components/AspectRatioWrapper/AspectRatioWrapper'
import { useHistory, useLocation } from 'react-router-dom'

const ReportsMobileTabs = ({ activeDataId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  return (
    <Box className='mobile-md-visible' display='flex'>
      {isDataActive(activeDataId, RELIABILITY_ID) && (
        <Box ml='auto' mb={2}>
          <Button
            variant='contained'
            color='primary'
            endIcon={<ArrowRight />}
            onClick={() => dispatch(activateData(ACTIVATE_REPORT_DATA, CUSTOMER_SATISFACTION_ID))}
          >
            {t('customer.satisfaction')}
          </Button>
        </Box>
      )}
      {isDataActive(activeDataId, CUSTOMER_SATISFACTION_ID) && (
        <Box width='100%' display='flex' justifyContent='space-between' flexWrap='wrap'>
          <Box mb={2}>
            <Button
              variant='contained'
              color='primary'
              startIcon={<ArrowLeft />}
              onClick={() => dispatch(activateData(ACTIVATE_REPORT_DATA, RELIABILITY_ID))}
            >
              {t('reliability.and.precision')}
            </Button>
          </Box>
          <Box mb={2}>
            <Button
              variant='contained'
              color='primary'
              endIcon={<ArrowRight />}
              onClick={() => dispatch(activateData(ACTIVATE_REPORT_DATA, PASSENGER_COUNTING_ID))}
            >
              {t('passenger.counting')}
            </Button>
          </Box>
        </Box>
      )}
      {isDataActive(activeDataId, PASSENGER_COUNTING_ID) && (
        <Box mr='auto' mb={2}>
          <Button
            variant='contained'
            color='primary'
            startIcon={<ArrowLeft />}
            onClick={() => dispatch(activateData(ACTIVATE_REPORT_DATA, CUSTOMER_SATISFACTION_ID))}
          >
            {t('customer.satisfaction')}
          </Button>
        </Box>
      )}
    </Box>
  )
}

function Reports () {
  const { t, i18n } = useTranslation()
  const currentLanguage = i18n.language
  const dispatch = useDispatch()
  const SITE_BREAKPOINT_LARGE = 1280
  const activeDataId = useSelector(state => state[REPORTS_SLICE_ID].active)
  const [embedToken, setEmbedToken] = useState(null)
  const [reliabilityReport, setReliabilityReport] = useState(null)
  const [customerSatisfactionReport, setCustomerSatisfactionReport] = useState(null)
  const [passengerCountingReport, setPassengerCountingReport] = useState(null)
  const [powerBIEmbedSettings] = useState({
    filterPaneEnabled: false,
    navContentPaneEnabled: false,
    localeSettings: {
      language: currentLanguage,
      formatLocale: currentLanguage
    },
    layoutType: window.innerWidth < SITE_BREAKPOINT_LARGE ? models.LayoutType.MobilePortrait : models.LayoutType.Master
  })
  const [reportPageStateParam, setReportPageStateParam] = useState('')

  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    if (location?.state?.pageName) {
      setReportPageStateParam(location?.state?.pageName)
      history.replace({ state: '' })
    }
  }, [location, history])

  useEffect(() => {
    reportService.getPowerBIReports().then((data) => {
      if (data.data.embedToken.value) {
        setEmbedToken(data.data.embedToken)
        setReliabilityReport(reportService.getReportById(data.data.reports, RELIABILITY_ID))
        setCustomerSatisfactionReport(reportService.getReportById(data.data.reports, CUSTOMER_SATISFACTION_ID))
        setPassengerCountingReport(reportService.getReportById(data.data.reports, PASSENGER_COUNTING_ID))
      }
    })
  }, [setEmbedToken, setReliabilityReport, setCustomerSatisfactionReport, setPassengerCountingReport])

  useEffect(() => {
    if (embedToken) {
      const currentMs = new Date().getTime()
      const tokenExpirationMs = new Date(embedToken?.expiration).getTime()
      const expirationOffsetMs = 60000 * 2 // 2 minutes
      const updateTokenMs = tokenExpirationMs - currentMs - expirationOffsetMs
      const timer = setTimeout(() => {
        reportService.getPowerBIReports().then((data) => {
          setEmbedToken(data.data.embedToken)
        })
      }, updateTokenMs)
      return () => clearTimeout(timer)
    }
  }, [embedToken, setEmbedToken])

  return (
    <div className='view-container'>
      <ReportsMobileTabs activeDataId={activeDataId} />
      <Grid container spacing={4}>
        <Grid item md={4} xs={12} className={!isDataActive(activeDataId, RELIABILITY_ID) ? 'mobile-md-hidden' : ''}>
          <ColumnContent>
            <ColumnTopbar>
              <ColumnTopbarHeadingButton
                heading={t('reliability.and.precision')}
                clickFn={() => dispatch(activateData(ACTIVATE_REPORT_DATA, RELIABILITY_ID))}
              />
            </ColumnTopbar>
          </ColumnContent>
          {isDataActive(activeDataId, RELIABILITY_ID) &&
            <ColumnActivityIndicator />}
        </Grid>
        <Grid item md={4} xs={12} className={!isDataActive(activeDataId, CUSTOMER_SATISFACTION_ID) ? 'mobile-md-hidden' : ''}>
          <ColumnContent>
            <ColumnTopbar>
              <ColumnTopbarHeadingButton
                heading={t('customer.satisfaction')}
                clickFn={() => dispatch(activateData(ACTIVATE_REPORT_DATA, CUSTOMER_SATISFACTION_ID))}
              />
            </ColumnTopbar>
          </ColumnContent>
          {isDataActive(activeDataId, CUSTOMER_SATISFACTION_ID) &&
            <ColumnActivityIndicator />}
        </Grid>
        <Grid item md={4} xs={12} className={!isDataActive(activeDataId, PASSENGER_COUNTING_ID) ? 'mobile-md-hidden' : ''}>
          <ColumnContent>
            <ColumnTopbar>
              <ColumnTopbarHeadingButton
                heading={t('passenger.counting')}
                clickFn={() => dispatch(activateData(ACTIVATE_REPORT_DATA, PASSENGER_COUNTING_ID))}
              />
            </ColumnTopbar>
          </ColumnContent>
          {isDataActive(activeDataId, PASSENGER_COUNTING_ID) &&
            <ColumnActivityIndicator />}
        </Grid>
        <Grid item xs={12}>
          <ColumnContent>
            <AspectRatioWrapper
              width={window.innerWidth >= SITE_BREAKPOINT_LARGE ? 16 : 9}
              height={window.innerWidth >= SITE_BREAKPOINT_LARGE ? 9 : 16}
            >
              <PowerBIEmbed
                cssClassName={`position-absolute w-100 h-100 ${isDataActive(activeDataId, RELIABILITY_ID) ? '' : 'visibility-hidden'}`}
                embedConfig={{
                  type: 'report',
                  id: reliabilityReport?.reportId,
                  embedUrl: reliabilityReport?.embedUrl,
                  accessToken: embedToken?.value,
                  tokenType: models.TokenType.Embed,
                  settings: powerBIEmbedSettings,
                  pageName: reportPageStateParam
                }}
              />
              <PowerBIEmbed
                cssClassName={`position-absolute w-100 h-100 ${isDataActive(activeDataId, CUSTOMER_SATISFACTION_ID) ? '' : 'visibility-hidden'}`}
                embedConfig={{
                  type: 'report',
                  id: customerSatisfactionReport?.reportId,
                  embedUrl: customerSatisfactionReport?.embedUrl,
                  accessToken: embedToken?.value,
                  tokenType: models.TokenType.Embed,
                  settings: powerBIEmbedSettings
                }}
              />
              <PowerBIEmbed
                cssClassName={`position-absolute w-100 h-100 ${isDataActive(activeDataId, PASSENGER_COUNTING_ID) ? '' : 'visibility-hidden'}`}
                embedConfig={{
                  type: 'report',
                  id: passengerCountingReport?.reportId,
                  embedUrl: passengerCountingReport?.embedUrl,
                  accessToken: embedToken?.value,
                  tokenType: models.TokenType.Embed,
                  settings: powerBIEmbedSettings
                }}
              />
            </AspectRatioWrapper>
          </ColumnContent>
        </Grid>
      </Grid>
    </div>
  )
}

export default Reports
