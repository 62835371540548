import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './ProfileMenu.scss'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Avatar from '@material-ui/core/Avatar'

function ProfileMenu (props) {
  const { name, picture } = props
  const { t } = useTranslation()
  const menuButton = useRef(null)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = () => {
    setAnchorEl(menuButton.current)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        id='profile-menu-toggle' aria-controls='profile-menu' aria-haspopup='true' aria-expanded={Boolean(anchorEl)}
        onClick={handleClick} ref={menuButton}
      >
        <span className='user-info'>
          <span className='fullname'>{name}</span>
          {/* <br /> */}
          {/* <span className='role'>{role}</span> */}
        </span>
        <Avatar alt={name} src={picture} />
      </Button>
      <Menu
        id='profile-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem onClick={handleClose}>
          <a href={`${process.env.REACT_APP_LOGOUT_URL}?post_logout_redirect_uri=/`}>
            {t('sign.out')}
          </a>
        </MenuItem>
      </Menu>
    </>
  )
}

export default ProfileMenu
