export const PASSENGER_COUNT_CHART_STEP_SIZE_MONTHLY = 2500
export const PASSENGER_COUNT_CHART_STEP_SIZE_DAILY = 250
export const CHART_TIME_UNIT_HOUR = 'hour'
export const LINE_FILTER_TOTAL = 'total'
export const LINE_FILTER_OFFLINE = 'notOnLine'

export const passengerCountTimeFilterOptions = [
  {
    labelKey: 'time.filter.last.day',
    value: 'hour'
  },
  {
    labelKey: 'time.filter.last.two.weeks',
    value: 'day'
  }
]

export const getPassengerCountLineFilter = (filters, filter) => {
  if (filter === LINE_FILTER_TOTAL) {
    filters.push({ labelKey: 'line_total', value: filter })
  } else if (filter === LINE_FILTER_OFFLINE) {
    filters.push({ labelKey: 'line_offline', value: filter })
  } else {
    filters.push({ labelKey: ['line_x', { line: filter }], value: filter })
  }
  return filters
}

export const getPassengerCountLineFiltersFromData = (data) => {
  const filters = []
  for (const key in data) {
    getPassengerCountLineFilter(filters, key)
  }
  filters.sort(function (x, y) { return x.value === LINE_FILTER_TOTAL ? -1 : y.value === LINE_FILTER_TOTAL ? 1 : 0 })
  return filters
}
