import React, { useState } from 'react'
import Topbar from '../Topbar/Topbar'
import Menubar from '../Menubar/Menubar'
import Backdrop from '@material-ui/core/Backdrop'

function SiteFrame ({ children, user }) {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <>
      <Topbar
        user={user.name}
        isMobileMenuOpen={isMobileMenuOpen}
        toggleMobileMenuFn={setMobileMenuOpen}
      />
      <div className={`main-container ${isMobileMenuOpen ? 'mobile-menu-open' : 'mobile-menu-hidden'}`}>
        <Menubar isMobileMenuOpen={isMobileMenuOpen} />
        {children}
        <Backdrop open={isMobileMenuOpen} onClick={() => setMobileMenuOpen(false)} />
      </div>
    </>
  )
}

export default SiteFrame
