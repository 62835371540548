import {
  TABLE_CELL_TYPE_EXTERNAL_LINK,
  // TABLE_CELL_TYPE_POPUP,
  TABLE_CELL_TYPE_STATUS,
  TABLE_CELL_TYPE_TEXT,
  TABLE_CELL_TYPE_DEADLINE
} from './constants'

const INFRA_MAINTENANCES_CLOSED_AT_LIMIT_DAYS = 7
const INFRA_MAINTENANCES_DEADLINE_IN_DAYS = 7

const infraMaintenanceStatusSummaryLabels = {
  'status.label.acute': 'Akuutti',
  'status.label.urgent': 'Kiireellinen',
  'status.label.not.urgent': 'Ei-kiireellinen',
  'status.label.no.priority': 'Ei prioriteettia'
}

const infraMaintenanceStatusSummaryLabelColors = {
  'status.label.acute.color': '#221D67',
  'status.label.urgent.color': '#29A2E7',
  'status.label.not.urgent.color': '#8DD8F8',
  'status.label.no.priority.color': '#E3EAF3'
}

const infraMaintenanceByDeadlineSummaryLabels = {
  'deadline.label.under.three.days': 'Alle 3 päivää',
  'deadline.label.three.to.seven.days': '3-7 päivää',
  'deadline.label.eight.to.fifteen.days': '8-15 päivää',
  'deadline.label.sixteen.to.sixty.days': '16-60 päivää',
  'deadline.label.late': 'Myöhässä'
}

const infraMaintenanceByDeadlineSummaryLabelColors = {
  'deadline.label.under.three.days.color': '#B6E7FC',
  'deadline.label.three.to.seven.days.color': '#797FBA',
  'deadline.label.eight.to.fifteen.days.color': '#3E4195',
  'deadline.label.sixteen.to.sixty.days.color': '#221D67',
  'deadline.label.late.color': '#EC5B56'
}

const infraMaintenanceCriticalMalfunctionsSummaryLabels = {
  'critical.malfunction.prevents.operating': 'Estää liikennöinnin',
  'critical.malfunction.limited.operation': 'Liikennöitävissä rajoituksin'
}

const infraMaintenanceCriticalMalfunctionsSummaryLabelColors = {
  'critical.malfunction.prevents.operating.color': '#221D67',
  'critical.malfunction.limited.operation.color': '#29A2E7'
}

const infraTableHeadCells = [
  { id: 'name', labelKey: 'operation', sortable: true },
  { id: 'domain', labelKey: 'target', sortable: true },
  { id: 'category', labelKey: 'category', sortable: true },
  { id: 'resourceType', labelKey: 'resource.type', sortable: true },
  { id: 'deadline', labelKey: 'deadline', sortable: true },
  { id: 'priority', labelKey: 'priority', sortable: true },
  { id: 'latestComment', labelKey: 'comment', sortable: true },
  { id: 'status', labelKey: 'status', sortable: true },
  // { id: 'coordinates', labelKey: 'location', sortable: false },
  { id: 'miproUrl', labelKey: 'additional.info', sortable: false }
]

const infraTableCells = [
  { id: 'name', type: TABLE_CELL_TYPE_TEXT },
  { id: 'domain', type: TABLE_CELL_TYPE_TEXT },
  { id: 'category', type: TABLE_CELL_TYPE_TEXT },
  { id: 'resourceType', type: TABLE_CELL_TYPE_TEXT },
  { id: 'deadline', type: TABLE_CELL_TYPE_DEADLINE },
  { id: 'priority', type: TABLE_CELL_TYPE_TEXT },
  { id: 'latestComment', type: TABLE_CELL_TYPE_TEXT },
  { id: 'status', type: TABLE_CELL_TYPE_STATUS },
  // { id: 'coordinates', type: TABLE_CELL_TYPE_POPUP },
  { id: 'miproUrl', type: TABLE_CELL_TYPE_EXTERNAL_LINK }
]

const infraTableFilters = [
  {
    id: 'name',
    legendKey: 'operation',
    filters: []
  },
  {
    id: 'category',
    legendKey: 'category',
    filters: []
  },
  {
    id: 'status',
    legendKey: 'status',
    filters: []
  }
]

const INFRA_MALFUNCTIONS_SUMMARY_CATEGORY_OPEN_MALFUNCTIONS = 'openMalfunctions'
const INFRA_MALFUNCTIONS_SUMMARY_CATEGORY_MALFUNCTIONS_COMPLETED_AT_LIMIT_DAYS = 'malfunctionsCompletedInClosedAtLimitDays'

const infraMalfunctionsSummaryCategoryFilters = {
  id: 'summaryCategories',
  legendKey: 'summary',
  filters: [
    INFRA_MALFUNCTIONS_SUMMARY_CATEGORY_OPEN_MALFUNCTIONS,
    INFRA_MALFUNCTIONS_SUMMARY_CATEGORY_MALFUNCTIONS_COMPLETED_AT_LIMIT_DAYS
  ]
}
const infraMalfunctionsTableFilters = [...infraTableFilters, infraMalfunctionsSummaryCategoryFilters]

const INFRA_MAINTENANCES_SUMMARY_CATEGORY_DEADLINE_IN_DAYS = 'maintenanceDeadlineInDays'
const INFRA_MAINTENANCES_SUMMARY_CATEGORY_OPEN_MALFUNCTIONS = 'openMalfunctions'
const INFRA_MAINTENANCES_SUMMARY_CATEGORY_DEADLINE_EXCEEDED = 'maintenanceDeadlineExceeded'
const INFRA_MAINTENANCES_SUMMARY_CATEGORY_MALFUNCTIONS_COMPLETED_AT_LIMIT_DAYS = 'malfunctionsCompletedInClosedAtLimitDays'
const INFRA_MAINTENANCES_SUMMARY_CATEGORY_MAINTENANCES_COMPLETED_AT_LIMIT_DAYS = 'maintenanceCompletedInClosedAtLimitDays'
const INFRA_MAINTENANCES_SUMMARY_CATEGORY_OTHERS_DEADLINE_IN_DAYS = 'othersDeadlineInDays'

const infraMaintenancesSummaryCategoryFilters = {
  id: 'summaryCategories',
  legendKey: 'summary',
  filters: [
    INFRA_MAINTENANCES_SUMMARY_CATEGORY_DEADLINE_IN_DAYS,
    INFRA_MAINTENANCES_SUMMARY_CATEGORY_OPEN_MALFUNCTIONS,
    INFRA_MAINTENANCES_SUMMARY_CATEGORY_DEADLINE_EXCEEDED,
    INFRA_MAINTENANCES_SUMMARY_CATEGORY_MALFUNCTIONS_COMPLETED_AT_LIMIT_DAYS,
    INFRA_MAINTENANCES_SUMMARY_CATEGORY_MAINTENANCES_COMPLETED_AT_LIMIT_DAYS,
    INFRA_MAINTENANCES_SUMMARY_CATEGORY_OTHERS_DEADLINE_IN_DAYS
  ]
}
const infraMaintenancesTableFilters = [...infraTableFilters, infraMaintenancesSummaryCategoryFilters]

export {
  INFRA_MAINTENANCES_CLOSED_AT_LIMIT_DAYS,
  INFRA_MAINTENANCES_DEADLINE_IN_DAYS,
  infraMaintenanceStatusSummaryLabels,
  infraMaintenanceStatusSummaryLabelColors,
  infraMaintenanceByDeadlineSummaryLabels,
  infraMaintenanceByDeadlineSummaryLabelColors,
  infraMaintenanceCriticalMalfunctionsSummaryLabels,
  infraMaintenanceCriticalMalfunctionsSummaryLabelColors,
  infraTableHeadCells,
  infraTableCells,
  infraMalfunctionsTableFilters,
  INFRA_MALFUNCTIONS_SUMMARY_CATEGORY_OPEN_MALFUNCTIONS,
  INFRA_MALFUNCTIONS_SUMMARY_CATEGORY_MALFUNCTIONS_COMPLETED_AT_LIMIT_DAYS,
  infraMaintenancesTableFilters,
  INFRA_MAINTENANCES_SUMMARY_CATEGORY_DEADLINE_IN_DAYS,
  INFRA_MAINTENANCES_SUMMARY_CATEGORY_OPEN_MALFUNCTIONS,
  INFRA_MAINTENANCES_SUMMARY_CATEGORY_DEADLINE_EXCEEDED,
  INFRA_MAINTENANCES_SUMMARY_CATEGORY_MALFUNCTIONS_COMPLETED_AT_LIMIT_DAYS,
  INFRA_MAINTENANCES_SUMMARY_CATEGORY_MAINTENANCES_COMPLETED_AT_LIMIT_DAYS,
  INFRA_MAINTENANCES_SUMMARY_CATEGORY_OTHERS_DEADLINE_IN_DAYS
}
