import { useContext, useEffect, useState } from 'react'
import MapContext from '../MapContext'
import OLVectorLayer from 'ol/layer/Vector'

const VectorLayer = ({ source, setLayer, visible, zIndex }) => {
  const { map } = useContext(MapContext)
  const [vectorLayer] = useState(new OLVectorLayer())

  useEffect(() => {
    if (!map) return
    map.addLayer(vectorLayer)
    if (setLayer) {
      setLayer(vectorLayer)
    }
    vectorLayer.setZIndex(zIndex)
    return () => {
      if (map) {
        map.removeLayer(vectorLayer)
        if (setLayer) {
          setLayer(null)
        }
      }
    }
  }, [map, vectorLayer, setLayer, zIndex])

  useEffect(() => {
    vectorLayer.setVisible(visible)
  }, [vectorLayer, visible])

  useEffect(() => {
    vectorLayer.setSource(source)
  }, [vectorLayer, source])

  return null
}
export default VectorLayer
