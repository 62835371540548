import { useContext, useEffect } from 'react'
import { OverviewMap } from 'ol/control'
import MapContext from '../MapContext'
const OverviewMapControl = () => {
  const { map } = useContext(MapContext)
  useEffect(() => {
    if (!map) return
    const overviewMapControl = new OverviewMap({})
    map.controls.push(overviewMapControl)

    return () => map.controls.remove(overviewMapControl)
  }, [map])
  return null
}
export default OverviewMapControl
