import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { EQUIPMENT_SLICE_ID } from '../../store'
import { EQUIPMENT_ID } from '../../store/equipment/reducers'
import {
  TOGGLE_EQUIPMENT_TABLE_ORDER,
  SET_EQUIPMENT_TABLE_COLUMN_TO_ORDER_BY,
  TOGGLE_EQUIPMENT_TABLE_DENSITY,
  SET_EQUIPMENT_TABLE_PAGE,
  SET_EQUIPMENT_TABLE_ROWS_PER_PAGE
} from '../../store/equipment/actionTypes'
import {
  setEquipmentData,
  setEquipmentSummaryData
} from '../../store/equipment/actions'
import equipmentService from '../../services/equipmentService'
import {
  getLocaleDateString,
  getLocaleTimeString
} from '../../util/commonUtil'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'
import ColumnContent from '../../components/ColumnContent/ColumnContent'
import ColumnTopbar from '../../components/ColumnTopbar/ColumnTopbar'
import DoughnutChart from '../../components/DoughnutChart/DoughnutChart'
import EnhancedTable from '../../components/EnhancedTable/EnhancedTable'
import ColumnTopbarHeadingButton from '../../components/ColumnTopbar/ColumnTopbarHeadingButton'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.error.main
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0
  },
  primaryListText: {
    fontWeight: 500,
    fontSize: 15,
    marginBottom: 2
  }
}))

function Equipment () {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const equipment = useSelector(state => state[EQUIPMENT_SLICE_ID][EQUIPMENT_ID].data)
  const equipmentSummary = useSelector(state => state[EQUIPMENT_SLICE_ID][EQUIPMENT_ID].summaryData)
  const equipmentQueryTimestamp = equipmentSummary.dataUpdateTimestamp

  useEffect(() => {
    dispatch(setEquipmentData(EQUIPMENT_ID))
    dispatch(setEquipmentSummaryData(EQUIPMENT_ID))
  }, [dispatch])

  return (
    <div className='view-container'>
      <Grid container spacing={4}>
        <Grid item xl={3} xs={12}>
          <ColumnContent>
            <ColumnTopbar>
              <ColumnTopbarHeadingButton
                heading={t('equipment.status')}
                updatedTimestamp={equipmentQueryTimestamp}
              />
            </ColumnTopbar>
            <Grid container item spacing={3}>
              <Grid item xl={12} md={equipmentService.getRestrictedEquipment(equipment).length > 0 ? 6 : 12} xs={12}>
                <DoughnutChart
                  data={equipmentService.getEquipmentStatusSummary(equipmentSummary)}
                  label={t('total') + ' ' + equipmentService.getEquipmentStatusSummaryAmount(equipmentSummary)}
                  legendPositionClass='legend-right'
                  showValuesInLegend
                />
              </Grid>
              {equipmentService.getRestrictedEquipment(equipment).length > 0 && (
                <Grid item xl={12} md={6} xs={12}>
                  <>
                    <h3 className='m-0'>{t('use.prohibited')}</h3>
                    <List disablePadding>
                      {equipmentService.getRestrictedEquipment(equipment).map((tram) => (
                        <ListItem
                          key={tram.tramId}
                          className={classes.listItem}
                          alignItems='flex-start'
                        >
                          <ListItemAvatar>
                            <Avatar className={classes.avatar}>{tram.tramId}</Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            classes={{ primary: classes.primaryListText }}
                            primary={
                              <>
                                {t('date.time.o.clock', { date: getLocaleDateString(tram.restrictionStartTimestamp), time: getLocaleTimeString(tram.restrictionStartTimestamp) })}  {t('starting.from')} ({t('day.count', { count: Math.floor(tram.restrictionDays) })}) {!tram.restrictionTimestampAccurate && <Chip label={t('tentative')} size='small' variant='outlined' />}
                              </>
                            }
                            secondary={tram.restrictionReasonDescription}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </>
                </Grid>
              )}
            </Grid>
          </ColumnContent>
        </Grid>
        <Grid item xl={9} xs={12}>
          <ColumnContent>
            <EnhancedTable
              stateId={EQUIPMENT_SLICE_ID}
              tableActionTypes={{
                toggleTableOrder: TOGGLE_EQUIPMENT_TABLE_ORDER,
                setTableColumnToOrderBy: SET_EQUIPMENT_TABLE_COLUMN_TO_ORDER_BY,
                toggleTableDensity: TOGGLE_EQUIPMENT_TABLE_DENSITY,
                setTablePage: SET_EQUIPMENT_TABLE_PAGE,
                setTableRowsPerPage: SET_EQUIPMENT_TABLE_ROWS_PER_PAGE
              }}
              headCells={equipmentService.getEquipmentTableHeadCells()}
              cells={equipmentService.getEquipmentTableCells()}
              dataId={EQUIPMENT_ID}
              data={equipment}
              collapsibleRows
            />
          </ColumnContent>
        </Grid>
      </Grid>
    </div>
  )
}

export default Equipment
