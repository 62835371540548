import { WS_CONNECT, WS_DISCONNECT } from './webSocket'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import { setTramData, setTramDataError } from '../store/map/actions'
import { TRAM_ID } from '../store/map/reducers'
import {
  SET_TRAM_WS_DATA_LOCATION,
  SET_TRAM_WS_DATA_PASSENGER,
  SET_TRAM_WS_DATA_LINE,
  EMPTY_TRAM_DATA
} from '../store/map/actionTypes'

const MAX_CONNECTION_REATTEMPT = 10

const webSocketMiddleware = () => {
  let connection = null

  const onMessage = store => (data) => {
    if (data.locationData) {
      store.dispatch(setTramData(TRAM_ID, SET_TRAM_WS_DATA_LOCATION, data.locationData))
    }

    if (data.passengerData) {
      store.dispatch(setTramData(TRAM_ID, SET_TRAM_WS_DATA_PASSENGER, data.passengerData))
    }

    if (data.lineData) {
      store.dispatch(setTramData(TRAM_ID, SET_TRAM_WS_DATA_LINE, data.lineData))
    }
  }

  const startConnection = (store, connectionAttempt) => {
    connection.start().then(() => {
      console.log('SignalR connection established')
      store.dispatch(setTramDataError(TRAM_ID, false))
    }).catch(error => {
      if (error && error.statusCode === 401) {
        console.log(`SignalR connection failed, status ${error.statusCode}`)
        store.dispatch(setTramDataError(TRAM_ID, true))
      } else {
        if (connectionAttempt < MAX_CONNECTION_REATTEMPT) {
          console.log(`SignalR connection failed, retry attempt ${connectionAttempt}`)
          connectionAttempt += 1
          setTimeout(() => startConnection(store, connectionAttempt), 5000)
        } else {
          store.dispatch(setTramDataError(TRAM_ID, true))
        }
      }
    })
  }

  // the middleware part of this function
  return store => next => action => {
    switch (action.type) {
      case WS_CONNECT:
        if (connection !== null) {
          connection.stop()
        }
        // Empty tram data on first application load
        store.dispatch(setTramData(TRAM_ID, EMPTY_TRAM_DATA))

        // connect to the remote host
        // TODO how to handle errors? Should we notify user if connection fails?
        // we could utilize connection.onreconnecting to notify on reconnect attempts
        // and connection.onclose(error to notify and stopped reconnecting, no default retry is 4 times
        connection = new HubConnectionBuilder()
          .withUrl(action.host, { accessTokenFactory: () => action.token })
          .configureLogging(LogLevel.Information)
          .withAutomaticReconnect()
          .build()

        // websocket handler
        connection.on('trams', onMessage(store))
        connection.onclose(error => {
          if (error) {
            store.dispatch(setTramDataError(TRAM_ID, true))
          }
        })
        connection.onreconnecting(error => {
          if (error && error.statusCode === 401) {
            console.log('Auth error')
            store.dispatch(setTramDataError(TRAM_ID, true))
          }
        })
        startConnection(store, 1)
        break

      case WS_DISCONNECT:
        if (connection !== null) {
          connection.stop()
        }
        connection = null
        console.log('SignalR connection closed')
        break
      default:
        return next(action)
    }
  }
}

export default webSocketMiddleware()
