export const loadState = (version) => {
  try {
    const serializedState = window.localStorage.getItem('state')
    if (serializedState) {
      const state = JSON.parse(serializedState)
      if (state.version !== version) {
        window.localStorage.clear()
      } else {
        return state
      }
    }
    return undefined
  } catch (err) {
    // Return undefined so that the app is not crashing
    return undefined
  }
}

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    window.localStorage.setItem('state', serializedState)
  } catch {
    // ignore write errors
  }
}
