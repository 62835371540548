import axios from 'axios'

const getPowerBIReports = async () => {
  const url = '/powerbi/token'
  const response = await axios.get(url)
  return response.data
}

const getReportById = (reports, id) => {
  return reports?.find((report) => report?.code === id)
}

const getReportPageById = (pages, id) => {
  return pages?.find((page) => page?.code === id)?.pageName
}

const getVisualNameById = (visuals, id) => {
  return visuals?.find((visual) => visual?.code === id)?.visualName
}

export default {
  getPowerBIReports,
  getReportById,
  getReportPageById,
  getVisualNameById
}
