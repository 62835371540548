import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import ProtectedRoute from './ProtectedRoute'
import FrontPage from '../views/FrontPage/FrontPage'
import Dashboard from '../views/Dashboard/Dashboard'
import Live from '../views/Live/Live'
import Equipment from '../views/Equipment/Equipment'
import Maintenances from '../views/Maintenances/Maintenances'
import Malfunctions from '../views/Malfunctions/Malfunctions'
import Deviations from '../views/Deviations/Deviations'
import Reports from '../views/Reports/Reports'
import News from '../views/News/News'
import PageNotFound from '../views/PageNotFound/PageNotFound'
import ErrorPage from '../views/Error/ErrorPage'

function Routes () {
  return (
    <Switch>
      <Route exact path='/' component={FrontPage} />
      <ProtectedRoute path='/dashboard' component={Dashboard} />
      <ProtectedRoute path='/live' component={Live} />
      <ProtectedRoute path='/kalusto' component={Equipment} />
      <ProtectedRoute path='/huollot' component={Maintenances} />
      <ProtectedRoute path='/hairiot-ja-viat' component={Malfunctions} />
      <ProtectedRoute path='/poikkeamat' component={Deviations} />
      <ProtectedRoute path='/raportit' component={Reports} />
      <ProtectedRoute path='/uutiset' component={News} />
      <Route path='/404' component={PageNotFound} />
      <Route path='/error' component={ErrorPage} />
      <Redirect from='*' to='/404' />
    </Switch>
  )
}

export default Routes
