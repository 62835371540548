import Chart from 'chart.js'

Chart.pluginService.register({
  beforeDraw: function (chart) {
    const hasTextCenterOption = chart.config.options.elements.center
    if (hasTextCenterOption) {
      // Get ctx from string
      const ctx = chart.chart.ctx

      const DEFAULT_FONT_STYLE = 'Roboto'
      const DEFAULT_FONT_COLOR = '#221d67'
      const DEFAULT_FONT_WEIGHT = 'bold'
      const DEFAULT_BREAK_SPACES = true
      const DEFAULT_BASE_FONT_SIZE = 30
      const DEFAULT_MIN_FONT_SIZE = 12
      const DEFAULT_MAX_FONT_SIZE = 30
      const DEFAULT_SIDE_PADDING = 0

      // Get options from the center object in options
      const centerConfig = chart.config.options.elements.center
      const fontStyle = centerConfig.fontStyle || DEFAULT_FONT_STYLE
      const txt = centerConfig.text.toString()
      const color = centerConfig.color || DEFAULT_FONT_COLOR
      const fontWeight = centerConfig.fontWeight || DEFAULT_FONT_WEIGHT
      const breakSpaces = centerConfig.breakSpaces || DEFAULT_BREAK_SPACES
      const maxFontSize = centerConfig.maxFontSize || DEFAULT_MAX_FONT_SIZE
      const sidePadding = centerConfig.sidePadding || DEFAULT_SIDE_PADDING
      const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
      // Start with a base font of 30px
      ctx.font = fontWeight + ' ' + DEFAULT_BASE_FONT_SIZE + 'px ' + fontStyle

      // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
      const stringWidth = ctx.measureText(txt).width
      const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated

      // Find out how much the font can grow in width.
      const widthRatio = elementWidth / stringWidth
      const newFontSize = Math.floor(DEFAULT_BASE_FONT_SIZE * widthRatio)
      const elementHeight = (chart.innerRadius * 2)

      // Pick a new font size so it will not be larger than the height of label.
      let fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize)
      let minFontSize = centerConfig.minFontSize
      const lineHeight = newFontSize * 1.4
      let wrapText = false

      if (minFontSize === null) {
        minFontSize = DEFAULT_MIN_FONT_SIZE
      }

      if (minFontSize && fontSizeToUse < minFontSize) {
        fontSizeToUse = minFontSize
        wrapText = true
      }

      // Set font settings to draw it correctly.
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2)
      let centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2)
      ctx.font = fontWeight + ' ' + fontSizeToUse + 'px ' + fontStyle
      ctx.fillStyle = color

      // Return the text as it is if its not meant to be broken or doesn't have any spaces
      if ((!wrapText && !breakSpaces) || !/\s/g.test(txt)) {
        ctx.fillText(txt, centerX, centerY)
        return
      }

      const words = txt.split(' ')
      let line = ''
      const lines = []

      // Break words up into multiple lines if necessary
      for (let n = 0; n < words.length; n++) {
        const testLine = line + words[n] + ' '
        const metrics = ctx.measureText(testLine)
        const testWidth = metrics.width
        if (breakSpaces) {
          lines.push(line)
          line = words[n] + ' '
        } else {
          if (testWidth > elementWidth && n > 0) {
            lines.push(line)
            line = words[n] + ' '
          } else {
            line = testLine
          }
        }
      }

      // Move the center up depending on line height and number of lines
      centerY -= (lines.length / 2) * lineHeight

      for (let n = 0; n < lines.length; n++) {
        ctx.fillText(lines[n], centerX, centerY)
        centerY += lineHeight
      }
      // Draw text in center
      ctx.fillText(line, centerX, centerY)
    }
  }
})
