import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isDataActive } from '../../util/commonUtil'
import {
  FILTER_GROUP_SUMMARY_CATEGORIES_ID,
  filterData,
  isTheOnlyActiveFilter
} from '../../util/filterUtil'
import {
  INFRA_MALFUNCTIONS_SUMMARY_CATEGORY_MALFUNCTIONS_COMPLETED_AT_LIMIT_DAYS,
  INFRA_MALFUNCTIONS_SUMMARY_CATEGORY_OPEN_MALFUNCTIONS
} from '../../util/infraUtil'
import infraService from '../../services/infraService'
import {
  EQUIPMENT_MALFUNCTIONS_SUMMARY_CATEGORY_RESTRICTION_LATE_24H,
  EQUIPMENT_MALFUNCTIONS_SUMMARY_CATEGORY_RESTRICTION_OOL_24H,
  EQUIPMENT_MALFUNCTIONS_SUMMARY_CATEGORY_RESTRICTION_OTHERS_24H
} from '../../util/equipmentUtil'
import equipmentService from '../../services/equipmentService'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import { MALFUNCTIONS_SLICE_ID } from '../../store'
import {
  activateData,
  setDataFilters,
  toggleDataFilter
} from '../../store/general/actions'
import {
  INFRA_ID,
  EQUIPMENT_ID
  // TRAFFIC_ID
} from '../../store/malfunctions/reducers'
import {
  ACTIVATE_MALFUNCTION_DATA,
  TOGGLE_MALFUNCTION_TABLE_ORDER,
  SET_MALFUNCTION_TABLE_COLUMN_TO_ORDER_BY,
  TOGGLE_MALFUNCTION_TABLE_DENSITY,
  SET_MALFUNCTION_TABLE_PAGE,
  SET_MALFUNCTION_TABLE_ROWS_PER_PAGE,
  TOGGLE_MALFUNCTION_DATA_FILTER,
  SET_MALFUNCTION_DATA_FILTERS
} from '../../store/malfunctions/actionTypes'
import {
  setInfraMalfunctionsData,
  setInfraMalfunctionsSummaryData,
  setEquipmentMalfunctionData,
  setEquipmentMalfunctionSummaryData
} from '../../store/malfunctions/actions'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import HighlightOff from '@material-ui/icons/HighlightOff'
import ArrowLeft from '@material-ui/icons/ArrowLeft'
import ArrowRight from '@material-ui/icons/ArrowRight'
import ColumnContent from '../../components/ColumnContent/ColumnContent'
import ColumnActivityIndicator from '../../components/ColumnActivityIndicator/ColumnActivityIndicator'
import ColumnTopbar from '../../components/ColumnTopbar/ColumnTopbar'
import ColumnTopbarHeadingButton from '../../components/ColumnTopbar/ColumnTopbarHeadingButton'
import ColumnTopbarTools from '../../components/ColumnTopbar/ColumnTopbarTools'
import RecordSummary from '../../components/RecordSummary/RecordSummary'
import RecordSummaryItem from '../../components/RecordSummary/RecordSummaryItem'
import DoughnutChart from '../../components/DoughnutChart/DoughnutChart'
import EnhancedTable from '../../components/EnhancedTable/EnhancedTable'
import FilterMenu from '../../components/EnhancedTable/Filtering/FilterMenu'
import FilterSelections from '../../components/EnhancedTable/Filtering/FilterSelections'
import DrawerToggle from '../../components/Drawer/DrawerToggle'
import Drawer from '../../components/Drawer/Drawer'
import DrawerMenu from '../../components/Drawer/DrawerMenu'
import DrawerMain from '../../components/Drawer/DrawerMain'

const MalfunctionsMobileTabs = ({ activeDataId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  return (
    <Box className='mobile-lg-visible' display='flex' justifyContent='space-between' mb={2}>
      {isDataActive(activeDataId, EQUIPMENT_ID) && (
        <Box>
          <Button
            variant='contained'
            color='primary'
            startIcon={<ArrowLeft />}
            onClick={() => dispatch(activateData(ACTIVATE_MALFUNCTION_DATA, INFRA_ID))}
          >
            {t('traffic.influencing.infra.malfunctions')}
          </Button>
        </Box>
      )}
      {isDataActive(activeDataId, INFRA_ID) && (
        <Box ml='auto'>
          <Button
            variant='contained'
            color='primary'
            endIcon={<ArrowRight />}
            onClick={() => dispatch(activateData(ACTIVATE_MALFUNCTION_DATA, EQUIPMENT_ID))}
          >
            {t('equipment.malfunctions')}
          </Button>
        </Box>
      )}
    </Box>
  )
}

const Malfunctions = () => {
  const { t } = useTranslation()
  const [filterMenuOpen, setFilterMenuOpen] = useState(false)
  const dispatch = useDispatch()
  const activeDataId = useSelector(state => state[MALFUNCTIONS_SLICE_ID].active)
  const activeData = useSelector(state => state[MALFUNCTIONS_SLICE_ID][activeDataId].data)
  const activeDataFilters = useSelector(state => state[MALFUNCTIONS_SLICE_ID][activeDataId].dataFilters)
  const infraDataFilters = useSelector(state => state[MALFUNCTIONS_SLICE_ID][INFRA_ID].dataFilters)
  const infraQueryTimestamp = useSelector(state => state[MALFUNCTIONS_SLICE_ID][INFRA_ID].dataTimestamp)
  const infraSummaryData = useSelector(state => state[MALFUNCTIONS_SLICE_ID][INFRA_ID].summaryData[0])
  const equipmentDataFilters = useSelector(state => state[MALFUNCTIONS_SLICE_ID][EQUIPMENT_ID].dataFilters)
  const equipmentQueryTimestamp = useSelector(state => state[MALFUNCTIONS_SLICE_ID][EQUIPMENT_ID].dataTimestamp)
  const equipmentSummaryData = useSelector(state => state[MALFUNCTIONS_SLICE_ID][EQUIPMENT_ID].summaryData)
  // Use constant for querying at least for now
  const limitDays = 7

  const toggleFilter = (dataId, filterGroupId, filter) => {
    dispatch(toggleDataFilter(TOGGLE_MALFUNCTION_DATA_FILTER, dataId, filterGroupId, filter))
  }

  const clearAllFilters = (dataId, filters) => {
    for (const filterGroupId in filters) {
      dispatch(setDataFilters(SET_MALFUNCTION_DATA_FILTERS, dataId, filterGroupId, []))
    }
  }

  const toggleSummaryCategoryFilter = (dataId, filters, filter) => {
    if (!isTheOnlyActiveFilter(filters, filter)) {
      clearAllFilters(dataId, filters)
      dispatch(toggleDataFilter(TOGGLE_MALFUNCTION_DATA_FILTER, dataId, FILTER_GROUP_SUMMARY_CATEGORIES_ID, filter))
    }
    if (!isDataActive(activeDataId, dataId)) {
      dispatch(activateData(ACTIVATE_MALFUNCTION_DATA, dataId))
    }
  }

  useEffect(() => {
    dispatch(setInfraMalfunctionsData(INFRA_ID, limitDays))
    dispatch(setInfraMalfunctionsSummaryData(INFRA_ID, limitDays))
    dispatch(setEquipmentMalfunctionData(EQUIPMENT_ID))
    dispatch(setEquipmentMalfunctionSummaryData(EQUIPMENT_ID))
  }, [dispatch, limitDays])

  return (
    <div className='view-container'>
      <MalfunctionsMobileTabs activeDataId={activeDataId} />
      <Grid container spacing={4}>
        <Grid item lg={4} xs={12} className={isDataActive(activeDataId, EQUIPMENT_ID) ? 'mobile-lg-hidden' : ''}>
          <ColumnContent>
            <ColumnTopbar>
              <ColumnTopbarHeadingButton
                heading={t('traffic.influencing.infra.malfunctions')}
                clickFn={() => dispatch(activateData(ACTIVATE_MALFUNCTION_DATA, INFRA_ID))}
                updatedTimestamp={infraQueryTimestamp}
              />
            </ColumnTopbar>
            <Grid container item spacing={3}>
              <Grid item md={6} xs={12}>
                <RecordSummary>
                  <RecordSummaryItem
                    label={t('open.malfunctions')}
                    color='orange'
                    icon={<ErrorOutline />}
                    amount={infraSummaryData ? infraSummaryData.malfunctionsOpen : ''}
                    active={isDataActive(activeDataId, INFRA_ID) &&
                            isTheOnlyActiveFilter(infraDataFilters, INFRA_MALFUNCTIONS_SUMMARY_CATEGORY_OPEN_MALFUNCTIONS)}
                    clickFn={() => toggleSummaryCategoryFilter(INFRA_ID, infraDataFilters, INFRA_MALFUNCTIONS_SUMMARY_CATEGORY_OPEN_MALFUNCTIONS)}
                  />
                  <RecordSummaryItem
                    label={t('fixed.malfunctions.last.xx.days', { days: limitDays })}
                    color='green'
                    icon={<CheckCircleOutline />}
                    amount={infraSummaryData ? infraSummaryData.malfunctionsCompletedInClosedAtLimitDays : ''}
                    active={isDataActive(activeDataId, INFRA_ID) &&
                            isTheOnlyActiveFilter(infraDataFilters, INFRA_MALFUNCTIONS_SUMMARY_CATEGORY_MALFUNCTIONS_COMPLETED_AT_LIMIT_DAYS)}
                    clickFn={() => toggleSummaryCategoryFilter(INFRA_ID, infraDataFilters, INFRA_MALFUNCTIONS_SUMMARY_CATEGORY_MALFUNCTIONS_COMPLETED_AT_LIMIT_DAYS)}
                  />
                </RecordSummary>
              </Grid>
              <Grid item md={6} xs={12}>
                <DoughnutChart
                  heading={t('critical.malfunctions.last.xx.days', { days: limitDays })}
                  data={infraService.getInfraMaintenanceCriticalMalfunctionsSummary(infraSummaryData)}
                  label={infraService.getInfraMaintenanceCriticalMalfunctionsAmount(infraSummaryData)}
                  legendPositionClass='legend-bottom'
                />
              </Grid>
            </Grid>
          </ColumnContent>
          {isDataActive(activeDataId, INFRA_ID) &&
            <ColumnActivityIndicator />}
        </Grid>
        <Grid item lg={4} xs={12} className={isDataActive(activeDataId, INFRA_ID) ? 'mobile-lg-hidden' : ''}>
          <ColumnContent>
            <ColumnTopbar>
              <ColumnTopbarHeadingButton
                heading={t('equipment.malfunctions')}
                clickFn={() => dispatch(activateData(ACTIVATE_MALFUNCTION_DATA, EQUIPMENT_ID))}
                updatedTimestamp={equipmentQueryTimestamp}
              />
            </ColumnTopbar>
            <RecordSummary columned>
              <RecordSummaryItem
                label={t('malfunction.type.broken.on.track.last.day')}
                color='red'
                icon={<HighlightOff />}
                amount={equipmentSummaryData.restrictionOutOfLineLast24h}
                active={isDataActive(activeDataId, EQUIPMENT_ID) &&
                        isTheOnlyActiveFilter(equipmentDataFilters, EQUIPMENT_MALFUNCTIONS_SUMMARY_CATEGORY_RESTRICTION_OOL_24H)}
                clickFn={() => toggleSummaryCategoryFilter(EQUIPMENT_ID, equipmentDataFilters, EQUIPMENT_MALFUNCTIONS_SUMMARY_CATEGORY_RESTRICTION_OOL_24H)}
              />
              <RecordSummaryItem
                label={t('malfunction.type.restricts.usage.last.day')}
                color='orange'
                icon={<ErrorOutline />}
                amount={equipmentSummaryData.restrictionOthersLast24h}
                active={isDataActive(activeDataId, EQUIPMENT_ID) &&
                        isTheOnlyActiveFilter(equipmentDataFilters, EQUIPMENT_MALFUNCTIONS_SUMMARY_CATEGORY_RESTRICTION_OTHERS_24H)}
                clickFn={() => toggleSummaryCategoryFilter(EQUIPMENT_ID, equipmentDataFilters, EQUIPMENT_MALFUNCTIONS_SUMMARY_CATEGORY_RESTRICTION_OTHERS_24H)}
              />
              <RecordSummaryItem
                label={t('malfunction.arrived.late.last.day')}
                color='orange'
                icon={<ErrorOutline />}
                amount={equipmentSummaryData.restrictionArrivingLateLast24h}
                active={isDataActive(activeDataId, EQUIPMENT_ID) &&
                        isTheOnlyActiveFilter(equipmentDataFilters, EQUIPMENT_MALFUNCTIONS_SUMMARY_CATEGORY_RESTRICTION_LATE_24H)}
                clickFn={() => toggleSummaryCategoryFilter(EQUIPMENT_ID, equipmentDataFilters, EQUIPMENT_MALFUNCTIONS_SUMMARY_CATEGORY_RESTRICTION_LATE_24H)}
              />
            </RecordSummary>
          </ColumnContent>
          {isDataActive(activeDataId, EQUIPMENT_ID) &&
            <ColumnActivityIndicator />}
        </Grid>
        {/* <Grid item lg={4} xs={12}> */}
        {/*  <ColumnContent> */}
        {/*    <ColumnTopbar> */}
        {/*      <ColumnTopbarHeadingButton */}
        {/*        heading={t('traffic.malfunctions')} */}
        {/*        clickFn={() => dispatch(activateData(ACTIVATE_MALFUNCTION_DATA, TRAFFIC_ID))} */}
        {/*        updatedTimestamp='' */}
        {/*      /> */}
        {/*    </ColumnTopbar> */}
        {/*    <div /> */}
        {/*  </ColumnContent> */}
        {/*  {isDataActive(activeDataId, TRAFFIC_ID) && */}
        {/*    <ColumnActivityIndicator />} */}
        {/* </Grid> */}
        <Grid item xs={12}>
          <ColumnContent>
            {isDataActive(activeDataId, INFRA_ID) && (
              <>
                <ColumnTopbar>
                  <h2 className='heading'>
                    {t('infra.maintenance')}
                  </h2>
                  <ColumnTopbarTools>
                    <DrawerToggle
                      text={t('filter')}
                      open={filterMenuOpen}
                      setOpen={setFilterMenuOpen}
                    />
                  </ColumnTopbarTools>
                </ColumnTopbar>
                <Drawer className='table-filter-menu'>
                  <DrawerMenu
                    anchor='top'
                    open={filterMenuOpen}
                  >
                    <FilterMenu
                      filters={infraService.getInfraMalfunctionsTableFilters(activeData)}
                      activeFilters={activeDataFilters}
                      toggleFn={toggleFilter}
                      dataId={activeDataId}
                    />
                  </DrawerMenu>
                  <DrawerMain open={filterMenuOpen}>
                    <FilterSelections
                      filters={activeDataFilters}
                      clearAllFn={clearAllFilters}
                      removeFn={toggleFilter}
                      dataId={activeDataId}
                    />
                    <EnhancedTable
                      stateId={MALFUNCTIONS_SLICE_ID}
                      tableActionTypes={{
                        toggleTableOrder: TOGGLE_MALFUNCTION_TABLE_ORDER,
                        setTableColumnToOrderBy: SET_MALFUNCTION_TABLE_COLUMN_TO_ORDER_BY,
                        toggleTableDensity: TOGGLE_MALFUNCTION_TABLE_DENSITY,
                        setTablePage: SET_MALFUNCTION_TABLE_PAGE,
                        setTableRowsPerPage: SET_MALFUNCTION_TABLE_ROWS_PER_PAGE
                      }}
                      headCells={infraService.getInfraTableHeadCells()}
                      cells={infraService.getInfraTableCells()}
                      data={filterData(activeData, activeDataFilters)}
                    />
                  </DrawerMain>
                </Drawer>
              </>
            )}
            {isDataActive(activeDataId, EQUIPMENT_ID) && (
              <>
                <ColumnTopbar>
                  <h2 className='heading'>
                    {t('equipment.malfunctions')}
                  </h2>
                  <ColumnTopbarTools>
                    <DrawerToggle
                      text={t('filter')}
                      open={filterMenuOpen}
                      setOpen={setFilterMenuOpen}
                    />
                  </ColumnTopbarTools>
                </ColumnTopbar>
                <Drawer className='table-filter-menu'>
                  <DrawerMenu
                    anchor='top'
                    open={filterMenuOpen}
                  >
                    <FilterMenu
                      filters={equipmentService.getEquipmentMalfunctionsTableFilters(activeData)}
                      activeFilters={activeDataFilters}
                      toggleFn={toggleFilter}
                      dataId={activeDataId}
                    />
                  </DrawerMenu>
                  <DrawerMain open={filterMenuOpen}>
                    <FilterSelections
                      filters={activeDataFilters}
                      clearAllFn={clearAllFilters}
                      removeFn={toggleFilter}
                      dataId={activeDataId}
                    />
                    <EnhancedTable
                      stateId={MALFUNCTIONS_SLICE_ID}
                      tableActionTypes={{
                        toggleTableOrder: TOGGLE_MALFUNCTION_TABLE_ORDER,
                        setTableColumnToOrderBy: SET_MALFUNCTION_TABLE_COLUMN_TO_ORDER_BY,
                        toggleTableDensity: TOGGLE_MALFUNCTION_TABLE_DENSITY,
                        setTablePage: SET_MALFUNCTION_TABLE_PAGE,
                        setTableRowsPerPage: SET_MALFUNCTION_TABLE_ROWS_PER_PAGE
                      }}
                      headCells={equipmentService.getEquipmentMalfunctionsTableHeadCells()}
                      cells={equipmentService.getEquipmentMalfunctionsTableCells()}
                      data={filterData(activeData, activeDataFilters)}
                    />
                  </DrawerMain>
                </Drawer>
              </>
            )}
            {/* {isDataActive(activeDataId, TRAFFIC_ID) && ( */}
            {/*  <> */}
            {/*    <ColumnTopbar> */}
            {/*      <h2 className='heading'> */}
            {/*        {t('traffic.malfunctions')} */}
            {/*      </h2> */}
            {/*      <ColumnTopbarTools> */}
            {/*        <Button variant='contained' color='primary'> */}
            {/*          <span className='btn-text'>{t('filter')}</span> */}
            {/*          <FilterListIcon /> */}
            {/*        </Button> */}
            {/*      </ColumnTopbarTools> */}
            {/*    </ColumnTopbar> */}
            {/*    <div /> */}
            {/*  </> */}
            {/* )} */}
          </ColumnContent>
        </Grid>
      </Grid>
    </div>
  )
}

export default Malfunctions
