import React from 'react'
import './Drawer.scss'

function Drawer ({ children, className }) {
  return (
    <div className={`drawer-root ${className}`}>
      {children}
    </div>
  )
}

export default Drawer
