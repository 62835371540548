import React from 'react'
import './App.scss'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from './routes/Routes'

function App () {
  return (
    <div className='app'>
      <Router>
        <Routes />
      </Router>
    </div>
  )
}

export default App
