import { toggleFilter } from '../../util/filterUtil'

export const activateData = (state, dataId) => {
  return {
    ...state,
    active: dataId
  }
}

export const activateDataSubset = (state, dataId, subsetId) => {
  return {
    ...state,
    [dataId]: {
      ...state[dataId],
      active: [
        ...state[dataId].active,
        subsetId
      ]
    }
  }
}

export const deactivateDataSubset = (state, dataId, subsetId) => {
  return {
    ...state,
    [dataId]: {
      ...state[dataId],
      active: state[dataId].active.filter(item => item !== subsetId)
    }
  }
}

export const setData = (state, dataId, data) => {
  return {
    ...state,
    [dataId]: {
      ...state[dataId],
      data: data
    }
  }
}

export const setDataWithDataTimestamp = (state, dataId, data, dataTimestamp) => {
  return {
    ...state,
    [dataId]: {
      ...state[dataId],
      data: data,
      dataTimestamp: dataTimestamp
    }
  }
}

export const setDataSubset = (state, dataId, subsetId, data) => {
  return {
    ...state,
    [dataId]: {
      ...state[dataId],
      [subsetId]: {
        ...state[dataId][subsetId],
        data: data
      }
    }
  }
}

export const setDataFilters = (state, dataId, filterGroupId, filters) => {
  return {
    ...state,
    [dataId]: {
      ...state[dataId],
      dataFilters: {
        ...state[dataId].dataFilters,
        [filterGroupId]: filters
      }
    }
  }
}

export const toggleDataFilter = (state, dataId, filterGroupId, filter) => {
  const updatedFilters = toggleFilter(state[dataId].dataFilters[filterGroupId], filter)
  return {
    ...state,
    [dataId]: {
      ...state[dataId],
      dataFilters: {
        ...state[dataId].dataFilters,
        [filterGroupId]: updatedFilters
      }
    }
  }
}

export const setSummaryData = (state, dataId, summaryData) => {
  return {
    ...state,
    [dataId]: {
      ...state[dataId],
      summaryData: summaryData
    }
  }
}

export const toggleTableOrder = (state, order) => {
  return {
    ...state,
    table: {
      ...state.table,
      order: order
    }
  }
}

export const setTableColumnToOrderBy = (state, orderBy) => {
  return {
    ...state,
    table: {
      ...state.table,
      orderBy: orderBy
    }
  }
}

export const toggleTableDensity = (state, dense) => {
  return {
    ...state,
    table: {
      ...state.table,
      dense: dense
    }
  }
}

export const setTablePage = (state, page) => {
  return {
    ...state,
    table: {
      ...state.table,
      page: page
    }
  }
}

export const setTableRowsPerPage = (state, rowsPerPage) => {
  return {
    ...state,
    table: {
      ...state.table,
      rowsPerPage: rowsPerPage
    }
  }
}
