import React from 'react'
import './ColumnContent.scss'

const ColumnContent = ({ children }) => {
  return (
    <div className='column-content'>
      {children}
    </div>
  )
}

export default ColumnContent
