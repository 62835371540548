import { SET_DEVIATION_DATA, SET_DEVIATION_DATA_TIME_FILTER } from './actionTypes'
import deviationService from '../../services/deviationService'

export const setDeviationData = (dataId, limitDays) => {
  return async dispatch => {
    const data = await deviationService.getObservations(dataId, limitDays)
    dispatch({
      type: SET_DEVIATION_DATA,
      payload: {
        dataId: dataId,
        data: data.data,
        dataTimestamp: data.dataTimestamp
      }
    })
  }
}

export const setDeviationDataTimeFilter = (dataId, limitDays) => ({
  type: SET_DEVIATION_DATA_TIME_FILTER,
  payload: {
    dataId: dataId,
    limitDays: limitDays
  }
})
