export const RELIABILITY_ID = 'RELIABILITY'
export const RELIABILITY_PAGE_RELIABILITY_ID = 'RELIABILITY'
export const RELIABILITY_PAGE_PUNCTUALITY_ID = 'PUNCTUALITY'
export const RELIABILITY_PAGE_MATRIX_ID = 'MATRIX'
export const REALIABILITY_VISUAL_DEPARTURE_ACCURACY_PERCENTAGE_ID = 'DEPARTURE_ACCURACY_PERCENTAGE'
export const REALIABILITY_VISUAL_DEPARTURE_ACCURACY_PERCENTAGE_PRESENT_YEAR_ID = 'DEPARTURE_ACCURACY_PERCENTAGE_PRESENT_YEAR'
export const REALIABILITY_VISUAL_DEPARTURE_ACCURACY_CHART_ID = 'DEPARTURE_ACCURACY_CHART'
export const REALIABILITY_VISUAL_SYSTEM_RELIABILITY_PERCENTAGE_ID = 'SYSTEM_RELIABILITY_PERCENTAGE'
export const REALIABILITY_VISUAL_SYSTEM_RELIABILITY_PERCENTAGE_PRESENT_YEAR_ID = 'SYSTEM_RELIABILITY_PERCENTAGE_PRESENT_YEAR'
export const REALIABILITY_VISUAL_SYSTEM_RELIABILITY_CHART_ID = 'SYSTEM_RELIABILITY_CHART'

export const CUSTOMER_SATISFACTION_ID = 'CUSTOMER_SATISFACTION'
export const CUSTOMER_SATISFACTION_VISUAL_NPS_ID = 'NPS'
export const CUSTOMER_SATISFACTION_VISUAL_CUSTOMER_SATISFACTION_AVERAGE_ID = 'CUSTOMER_SATISFACTION_AVERAGE'

export const PASSENGER_COUNTING_ID = 'PASSENGER_COUNTING'
export const PASSENGER_COUNTING_VISUAL_PASSENGER_COUNT_BY_MONTH_ID = 'PASSENGER_COUNT_BY_MONTH'
