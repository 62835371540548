import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  INFRA_MAINTENANCES_CLOSED_AT_LIMIT_DAYS,
  INFRA_MAINTENANCES_DEADLINE_IN_DAYS,
  INFRA_MAINTENANCES_SUMMARY_CATEGORY_DEADLINE_IN_DAYS,
  INFRA_MAINTENANCES_SUMMARY_CATEGORY_OPEN_MALFUNCTIONS,
  INFRA_MAINTENANCES_SUMMARY_CATEGORY_DEADLINE_EXCEEDED,
  INFRA_MAINTENANCES_SUMMARY_CATEGORY_MALFUNCTIONS_COMPLETED_AT_LIMIT_DAYS,
  INFRA_MAINTENANCES_SUMMARY_CATEGORY_MAINTENANCES_COMPLETED_AT_LIMIT_DAYS,
  INFRA_MAINTENANCES_SUMMARY_CATEGORY_OTHERS_DEADLINE_IN_DAYS
} from '../../util/infraUtil'
import infraService from '../../services/infraService'
import {
  EQUIPMENT_MAINTENANCES_SUMMARY_CATEGORY_COMPLETED_LAST_24H,
  EQUIPMENT_MAINTENANCES_SUMMARY_CATEGORY_OPEN_MALFUNCTIONS,
  EQUIPMENT_MAINTENANCES_SUMMARY_CATEGORY_PLANNED_NEXT_24H,
  EQUIPMENT_MAINTENANCES_SUMMARY_CATEGORY_REPAIRED_FAULTS_WITHIN_24H,
  EQUIPMENT_MAINTENANCES_SUMMARY_CATEGORY_RESTRICTED_TRAMS
} from '../../util/equipmentUtil'
import equipmentService from '../../services/equipmentService'
import { isDataActive } from '../../util/commonUtil'
import {
  FILTER_GROUP_SUMMARY_CATEGORIES_ID,
  filterData,
  isTheOnlyActiveFilter
} from '../../util/filterUtil'
import { useDispatch, useSelector } from 'react-redux'
import { MAINTENANCES_SLICE_ID } from '../../store'
import {
  INFRA_ID,
  EQUIPMENT_ID
} from '../../store/maintenances/reducers'
import {
  ACTIVATE_MAINTENANCE_DATA,
  TOGGLE_MAINTENANCE_TABLE_ORDER,
  SET_MAINTENANCE_TABLE_COLUMN_TO_ORDER_BY,
  TOGGLE_MAINTENANCE_TABLE_DENSITY,
  SET_MAINTENANCE_TABLE_PAGE,
  SET_MAINTENANCE_TABLE_ROWS_PER_PAGE,
  SET_MAINTENANCE_DATA_FILTERS,
  TOGGLE_MAINTENANCE_DATA_FILTER
} from '../../store/maintenances/actionTypes'
import {
  activateData,
  setDataFilters,
  toggleDataFilter
} from '../../store/general/actions'
import {
  setEquipmentMaintenanceSummaryData,
  setMaintenanceData,
  setMaintenanceSummaryData,
  setEquipmentMaintenanceData
} from '../../store/maintenances/actions'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import HighlightOff from '@material-ui/icons/HighlightOff'
import ArrowLeft from '@material-ui/icons/ArrowLeft'
import ArrowRight from '@material-ui/icons/ArrowRight'
import ColumnContent from '../../components/ColumnContent/ColumnContent'
import ColumnActivityIndicator from '../../components/ColumnActivityIndicator/ColumnActivityIndicator'
import ColumnTopbar from '../../components/ColumnTopbar/ColumnTopbar'
import ColumnTopbarHeadingButton from '../../components/ColumnTopbar/ColumnTopbarHeadingButton'
import ColumnTopbarTools from '../../components/ColumnTopbar/ColumnTopbarTools'
import RecordSummary from '../../components/RecordSummary/RecordSummary'
import RecordSummaryItem from '../../components/RecordSummary/RecordSummaryItem'
import DoughnutChart from '../../components/DoughnutChart/DoughnutChart'
import EnhancedTable from '../../components/EnhancedTable/EnhancedTable'
import FilterMenu from '../../components/EnhancedTable/Filtering/FilterMenu'
import FilterSelections from '../../components/EnhancedTable/Filtering/FilterSelections'
import DrawerToggle from '../../components/Drawer/DrawerToggle'
import Drawer from '../../components/Drawer/Drawer'
import DrawerMenu from '../../components/Drawer/DrawerMenu'
import DrawerMain from '../../components/Drawer/DrawerMain'

const MaintenancesMobileTabs = ({ activeDataId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  return (
    <Box className='mobile-lg-visible' display='flex' justifyContent='space-between' mb={2}>
      {isDataActive(activeDataId, EQUIPMENT_ID) && (
        <Box>
          <Button
            variant='contained'
            color='primary'
            startIcon={<ArrowLeft />}
            onClick={() => dispatch(activateData(ACTIVATE_MAINTENANCE_DATA, INFRA_ID))}
          >
            {t('infra.maintenance')}
          </Button>
        </Box>
      )}
      {isDataActive(activeDataId, INFRA_ID) && (
        <Box ml='auto'>
          <Button
            variant='contained'
            color='primary'
            endIcon={<ArrowRight />}
            onClick={() => dispatch(activateData(ACTIVATE_MAINTENANCE_DATA, EQUIPMENT_ID))}
          >
            {t('equipment.maintenance')}
          </Button>
        </Box>
      )}
    </Box>
  )
}

function Maintenances () {
  const { t } = useTranslation()
  const [filterMenuOpen, setFilterMenuOpen] = useState(false)
  const dispatch = useDispatch()
  const activeDataId = useSelector(state => state[MAINTENANCES_SLICE_ID].active)
  const activeData = useSelector(state => state[MAINTENANCES_SLICE_ID][activeDataId].data)
  const activeDataFilters = useSelector(state => state[MAINTENANCES_SLICE_ID][activeDataId].dataFilters)
  const infraDataFilters = useSelector(state => state[MAINTENANCES_SLICE_ID][INFRA_ID].dataFilters)
  const infraQueryTimestamp = useSelector(state => state[MAINTENANCES_SLICE_ID][INFRA_ID].dataTimestamp)
  const infraSummaryData = useSelector(state => state[MAINTENANCES_SLICE_ID][INFRA_ID].summaryData[0])
  const equipmentDataFilters = useSelector(state => state[MAINTENANCES_SLICE_ID][EQUIPMENT_ID].dataFilters)
  const equipmentSummaryData = useSelector(state => state[MAINTENANCES_SLICE_ID][EQUIPMENT_ID].summaryData)
  const equipmentDataTimestamp = useSelector(state => state[MAINTENANCES_SLICE_ID][EQUIPMENT_ID].dataTimestamp)

  const toggleFilter = (dataId, filterGroupId, filter) => {
    dispatch(toggleDataFilter(TOGGLE_MAINTENANCE_DATA_FILTER, dataId, filterGroupId, filter))
  }

  const clearAllFilters = (dataId, filters) => {
    for (const filterGroupId in filters) {
      dispatch(setDataFilters(SET_MAINTENANCE_DATA_FILTERS, dataId, filterGroupId, []))
    }
  }

  const toggleSummaryCategoryFilter = (dataId, filters, filter) => {
    if (!isTheOnlyActiveFilter(filters, filter)) {
      clearAllFilters(dataId, filters)
      dispatch(toggleDataFilter(TOGGLE_MAINTENANCE_DATA_FILTER, dataId, FILTER_GROUP_SUMMARY_CATEGORIES_ID, filter))
    }
    if (!isDataActive(activeDataId, dataId)) {
      dispatch(activateData(ACTIVATE_MAINTENANCE_DATA, dataId))
    }
  }

  useEffect(() => {
    dispatch(setMaintenanceData(INFRA_ID, INFRA_MAINTENANCES_CLOSED_AT_LIMIT_DAYS, INFRA_MAINTENANCES_DEADLINE_IN_DAYS))
    dispatch(setMaintenanceSummaryData(INFRA_ID, INFRA_MAINTENANCES_CLOSED_AT_LIMIT_DAYS, INFRA_MAINTENANCES_DEADLINE_IN_DAYS))
    dispatch(setEquipmentMaintenanceSummaryData(EQUIPMENT_ID))
    dispatch(setEquipmentMaintenanceData(EQUIPMENT_ID))
  }, [dispatch])

  return (
    <div className='view-container'>
      <MaintenancesMobileTabs activeDataId={activeDataId} />
      <Grid container spacing={4}>
        <Grid item lg={6} xs={12} className={isDataActive(activeDataId, EQUIPMENT_ID) ? 'mobile-lg-hidden' : ''}>
          <ColumnContent>
            <ColumnTopbar>
              <ColumnTopbarHeadingButton
                heading={t('infra.maintenance')}
                clickFn={() => dispatch(activateData(ACTIVATE_MAINTENANCE_DATA, INFRA_ID))}
                updatedTimestamp={infraQueryTimestamp}
              />
            </ColumnTopbar>
            <RecordSummary columned>
              <RecordSummaryItem
                label={t('expiring.maintenances.and.inspections.next.xx.days', { days: INFRA_MAINTENANCES_DEADLINE_IN_DAYS })}
                color='orange'
                icon={<ErrorOutline />}
                amount={infraSummaryData ? infraSummaryData.maintenanceDeadlineInDays : ''}
                trend=''
                reverseIndicator
                active={isDataActive(activeDataId, INFRA_ID) &&
                        isTheOnlyActiveFilter(infraDataFilters, INFRA_MAINTENANCES_SUMMARY_CATEGORY_DEADLINE_IN_DAYS)}
                clickFn={() => toggleSummaryCategoryFilter(INFRA_ID, infraDataFilters, INFRA_MAINTENANCES_SUMMARY_CATEGORY_DEADLINE_IN_DAYS)}
              />
              <RecordSummaryItem
                label={t('open.malfunctions')}
                color='orange'
                icon={<ErrorOutline />}
                amount={infraSummaryData ? infraSummaryData.malfunctionsOpen : ''}
                trend=''
                reverseIndicator
                active={isDataActive(activeDataId, INFRA_ID) &&
                        isTheOnlyActiveFilter(infraDataFilters, INFRA_MAINTENANCES_SUMMARY_CATEGORY_OPEN_MALFUNCTIONS)}
                clickFn={() => toggleSummaryCategoryFilter(INFRA_ID, infraDataFilters, INFRA_MAINTENANCES_SUMMARY_CATEGORY_OPEN_MALFUNCTIONS)}
              />
              <RecordSummaryItem
                label={t('expired.maintenances.and.inspections')}
                color='red'
                icon={<HighlightOff />}
                amount={infraSummaryData ? infraSummaryData.maintenanceDeadlineExceeded : ''}
                trend=''
                reverseIndicator
                active={isDataActive(activeDataId, INFRA_ID) &&
                        isTheOnlyActiveFilter(infraDataFilters, INFRA_MAINTENANCES_SUMMARY_CATEGORY_DEADLINE_EXCEEDED)}
                clickFn={() => toggleSummaryCategoryFilter(INFRA_ID, infraDataFilters, INFRA_MAINTENANCES_SUMMARY_CATEGORY_DEADLINE_EXCEEDED)}
              />
              <RecordSummaryItem
                label={t('fixed.malfunctions.last.xx.days', { days: INFRA_MAINTENANCES_CLOSED_AT_LIMIT_DAYS })}
                color='green'
                icon={<CheckCircleOutline />}
                amount={infraSummaryData ? infraSummaryData.malfunctionsCompletedInClosedAtLimitDays : ''}
                trend=''
                active={isDataActive(activeDataId, INFRA_ID) &&
                        isTheOnlyActiveFilter(infraDataFilters, INFRA_MAINTENANCES_SUMMARY_CATEGORY_MALFUNCTIONS_COMPLETED_AT_LIMIT_DAYS)}
                clickFn={() => toggleSummaryCategoryFilter(INFRA_ID, infraDataFilters, INFRA_MAINTENANCES_SUMMARY_CATEGORY_MALFUNCTIONS_COMPLETED_AT_LIMIT_DAYS)}
              />
              <RecordSummaryItem
                label={t('completed.maintenances.and.inspections.last.xx.days', { days: INFRA_MAINTENANCES_CLOSED_AT_LIMIT_DAYS })}
                color='green'
                icon={<CheckCircleOutline />}
                amount={infraSummaryData ? infraSummaryData.maintenanceCompletedInClosedAtLimitDays : ''}
                trend=''
                active={isDataActive(activeDataId, INFRA_ID) &&
                        isTheOnlyActiveFilter(infraDataFilters, INFRA_MAINTENANCES_SUMMARY_CATEGORY_MAINTENANCES_COMPLETED_AT_LIMIT_DAYS)}
                clickFn={() => toggleSummaryCategoryFilter(INFRA_ID, infraDataFilters, INFRA_MAINTENANCES_SUMMARY_CATEGORY_MAINTENANCES_COMPLETED_AT_LIMIT_DAYS)}
              />
              <RecordSummaryItem
                label={t('expiring.other.operations.next.xx.days', { days: INFRA_MAINTENANCES_DEADLINE_IN_DAYS })}
                color='orange'
                icon={<ErrorOutline />}
                amount={infraSummaryData ? infraSummaryData.othersDeadlineInDays : ''}
                trend=''
                active={isDataActive(activeDataId, INFRA_ID) &&
                        isTheOnlyActiveFilter(infraDataFilters, INFRA_MAINTENANCES_SUMMARY_CATEGORY_OTHERS_DEADLINE_IN_DAYS)}
                clickFn={() => toggleSummaryCategoryFilter(INFRA_ID, infraDataFilters, INFRA_MAINTENANCES_SUMMARY_CATEGORY_OTHERS_DEADLINE_IN_DAYS)}
              />
            </RecordSummary>
          </ColumnContent>
          {isDataActive(activeDataId, INFRA_ID) &&
            <ColumnActivityIndicator />}
        </Grid>
        <Grid item lg={6} xs={12} className={isDataActive(activeDataId, INFRA_ID) ? 'mobile-lg-hidden' : ''}>
          <ColumnContent>
            <ColumnTopbar>
              <ColumnTopbarHeadingButton
                heading={t('equipment.maintenance')}
                clickFn={() => dispatch(activateData(ACTIVATE_MAINTENANCE_DATA, EQUIPMENT_ID))}
                updatedTimestamp={equipmentDataTimestamp}
              />
            </ColumnTopbar>
            <RecordSummary columned>
              <RecordSummaryItem
                label={t('maintenances.planned.next.day')}
                color='gray'
                icon={<ErrorOutline />}
                amount={equipmentSummaryData ? equipmentSummaryData.plannedMaintenanceNext24h : ''}
                trend=''
                active={isDataActive(activeDataId, EQUIPMENT_ID) &&
                        isTheOnlyActiveFilter(equipmentDataFilters, EQUIPMENT_MAINTENANCES_SUMMARY_CATEGORY_PLANNED_NEXT_24H)}
                clickFn={() => toggleSummaryCategoryFilter(EQUIPMENT_ID, equipmentDataFilters, EQUIPMENT_MAINTENANCES_SUMMARY_CATEGORY_PLANNED_NEXT_24H)}
              />
              <RecordSummaryItem
                label={t('maintenances.done.last.day')}
                color='green'
                icon={<CheckCircleOutline />}
                amount={equipmentSummaryData ? equipmentSummaryData.completedMaintenanceLast24h : ''}
                trend=''
                active={isDataActive(activeDataId, EQUIPMENT_ID) &&
                        isTheOnlyActiveFilter(equipmentDataFilters, EQUIPMENT_MAINTENANCES_SUMMARY_CATEGORY_COMPLETED_LAST_24H)}
                clickFn={() => toggleSummaryCategoryFilter(EQUIPMENT_ID, equipmentDataFilters, EQUIPMENT_MAINTENANCES_SUMMARY_CATEGORY_COMPLETED_LAST_24H)}
              />
              <RecordSummaryItem
                label={t('open.malfunctions')}
                color='orange'
                icon={<ErrorOutline />}
                amount={equipmentSummaryData ? equipmentSummaryData.openFaultsCount : ''}
                trend=''
                active={isDataActive(activeDataId, EQUIPMENT_ID) &&
                        isTheOnlyActiveFilter(equipmentDataFilters, EQUIPMENT_MAINTENANCES_SUMMARY_CATEGORY_OPEN_MALFUNCTIONS)}
                clickFn={() => toggleSummaryCategoryFilter(EQUIPMENT_ID, equipmentDataFilters, EQUIPMENT_MAINTENANCES_SUMMARY_CATEGORY_OPEN_MALFUNCTIONS)}
              />
              <RecordSummaryItem
                label={t('use.prohibited')}
                color='red'
                icon={<HighlightOff />}
                amount={equipmentSummaryData ? equipmentSummaryData.restrictedTramsCount : ''}
                trend=''
                active={isDataActive(activeDataId, EQUIPMENT_ID) &&
                        isTheOnlyActiveFilter(equipmentDataFilters, EQUIPMENT_MAINTENANCES_SUMMARY_CATEGORY_RESTRICTED_TRAMS)}
                clickFn={() => toggleSummaryCategoryFilter(EQUIPMENT_ID, equipmentDataFilters, EQUIPMENT_MAINTENANCES_SUMMARY_CATEGORY_RESTRICTED_TRAMS)}
              />
              <RecordSummaryItem
                label={t('malfunctions.fixed.last.day')}
                color='green'
                icon={<CheckCircleOutline />}
                amount={equipmentSummaryData ? equipmentSummaryData.repairedFaultsWithin24h : ''}
                trend=''
                active={isDataActive(activeDataId, EQUIPMENT_ID) &&
                        isTheOnlyActiveFilter(equipmentDataFilters, EQUIPMENT_MAINTENANCES_SUMMARY_CATEGORY_REPAIRED_FAULTS_WITHIN_24H)}
                clickFn={() => toggleSummaryCategoryFilter(EQUIPMENT_ID, equipmentDataFilters, EQUIPMENT_MAINTENANCES_SUMMARY_CATEGORY_REPAIRED_FAULTS_WITHIN_24H)}
              />
            </RecordSummary>
          </ColumnContent>
          {isDataActive(activeDataId, EQUIPMENT_ID) &&
            <ColumnActivityIndicator />}
        </Grid>

        {isDataActive(activeDataId, INFRA_ID) && (
          <>
            <Grid item lg={3} xs={12}>
              <ColumnContent>
                <DoughnutChart
                  heading={t('open.maintenances.and.inspections')}
                  data={infraService.getInfraMaintenanceStatusSummary(infraSummaryData)}
                  label={infraService.getInfraMaintenanceStatusSummaryAmount(infraSummaryData)}
                  legendPositionClass='legend-right'
                />
                <DoughnutChart
                  heading={t('maintenances.and.inspections.by.deadline')}
                  data={infraService.getInfraMaintenanceByDeadlineSummary(infraSummaryData)}
                  label={infraService.getInfraMaintenanceByDeadlineSummaryAmount(infraSummaryData)}
                  legendPositionClass='legend-right'
                />
                <DoughnutChart
                  heading={t('critical.malfunctions.last.xx.days', { days: INFRA_MAINTENANCES_CLOSED_AT_LIMIT_DAYS })}
                  data={infraService.getInfraMaintenanceCriticalMalfunctionsSummary(infraSummaryData)}
                  label={infraService.getInfraMaintenanceCriticalMalfunctionsAmount(infraSummaryData)}
                  legendPositionClass='legend-right'
                />
              </ColumnContent>
            </Grid>
            <Grid item lg={9} xs={12}>
              <ColumnContent>
                <ColumnTopbar>
                  <h2 className='heading'>
                    {t('infra.maintenance')}
                  </h2>
                  <ColumnTopbarTools>
                    <DrawerToggle
                      text={t('filter')}
                      open={filterMenuOpen}
                      setOpen={setFilterMenuOpen}
                    />
                  </ColumnTopbarTools>
                </ColumnTopbar>
                <Drawer className='table-filter-menu'>
                  <DrawerMenu
                    anchor='top'
                    open={filterMenuOpen}
                  >
                    <FilterMenu
                      filters={infraService.getInfraMaintenancesTableFilters(activeData)}
                      activeFilters={activeDataFilters}
                      toggleFn={toggleFilter}
                      dataId={activeDataId}
                    />
                  </DrawerMenu>
                  <DrawerMain open={filterMenuOpen}>
                    <FilterSelections
                      filters={activeDataFilters}
                      clearAllFn={clearAllFilters}
                      removeFn={toggleFilter}
                      dataId={activeDataId}
                    />
                    <EnhancedTable
                      stateId={MAINTENANCES_SLICE_ID}
                      tableActionTypes={{
                        toggleTableOrder: TOGGLE_MAINTENANCE_TABLE_ORDER,
                        setTableColumnToOrderBy: SET_MAINTENANCE_TABLE_COLUMN_TO_ORDER_BY,
                        toggleTableDensity: TOGGLE_MAINTENANCE_TABLE_DENSITY,
                        setTablePage: SET_MAINTENANCE_TABLE_PAGE,
                        setTableRowsPerPage: SET_MAINTENANCE_TABLE_ROWS_PER_PAGE
                      }}
                      headCells={infraService.getInfraTableHeadCells()}
                      cells={infraService.getInfraTableCells()}
                      data={filterData(activeData, activeDataFilters)}
                    />
                  </DrawerMain>
                </Drawer>
              </ColumnContent>
            </Grid>
          </>
        )}
        {isDataActive(activeDataId, EQUIPMENT_ID) && (
          <Grid item xs={12}>
            <ColumnContent>
              <ColumnTopbar>
                <h2 className='heading'>
                  {t('equipment.maintenance')}
                </h2>
                <ColumnTopbarTools>
                  <DrawerToggle
                    text={t('filter')}
                    open={filterMenuOpen}
                    setOpen={setFilterMenuOpen}
                  />
                </ColumnTopbarTools>
              </ColumnTopbar>
              <Drawer className='table-filter-menu'>
                <DrawerMenu
                  anchor='top'
                  open={filterMenuOpen}
                >
                  <FilterMenu
                    filters={equipmentService.getEquipmentMaintenancesTableFilters(activeData)}
                    activeFilters={activeDataFilters}
                    toggleFn={toggleFilter}
                    dataId={activeDataId}
                  />
                </DrawerMenu>
                <DrawerMain open={filterMenuOpen}>
                  <FilterSelections
                    filters={activeDataFilters}
                    clearAllFn={clearAllFilters}
                    removeFn={toggleFilter}
                    dataId={activeDataId}
                  />
                  <EnhancedTable
                    stateId={MAINTENANCES_SLICE_ID}
                    tableActionTypes={{
                      toggleTableOrder: TOGGLE_MAINTENANCE_TABLE_ORDER,
                      setTableColumnToOrderBy: SET_MAINTENANCE_TABLE_COLUMN_TO_ORDER_BY,
                      toggleTableDensity: TOGGLE_MAINTENANCE_TABLE_DENSITY,
                      setTablePage: SET_MAINTENANCE_TABLE_PAGE,
                      setTableRowsPerPage: SET_MAINTENANCE_TABLE_ROWS_PER_PAGE
                    }}
                    headCells={equipmentService.getEquipmentMaintenancesTableHeadCells()}
                    cells={equipmentService.getEquipmentMaintenancesTableCells()}
                    data={filterData(activeData, activeDataFilters)}
                  />
                </DrawerMain>
              </Drawer>
            </ColumnContent>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default Maintenances
