import {
  TABLE_CELL_TYPE_TEXT,
  TABLE_CELL_TYPE_DATE,
  // TABLE_CELL_TYPE_LINK,
  TABLE_CELL_TYPE_STATUS,
  // TABLE_CELL_TYPE_POPUP,
  TABLE_CELL_TYPE_EXTERNAL_LINK
} from './constants'

const deviationSummaryLabels = {
  'deviation.label.crash': 'Törmäys',
  'deviation.label.passenger.accident': 'Matkustajavahinko',
  'deviation.label.fall': 'Suistuminen',
  'deviation.label.electricity.accident': 'Sähkötapaturma',
  'deviation.label.infra.failures': 'Turvallisuuteen vaikuttavat infran viat',
  'deviation.label.dangers': 'Vaaratilanteet',
  'deviation.label.traffic.failures': 'Turvallisuuteen vaikuttavat liikenteenohjauksen häiriöt',
  'deviation.label.disturbances': 'Järjestyshäiriöt',
  'deviation.label.equipment.flaws': 'Turvallisuuteen vaikuttavat kaluston viat',
  'deviation.label.other.accident': 'Muu onnettomuus'
}

const deviationSummaryLabelColors = {
  'deviation.label.crash.color': '#B6E7FC',
  'deviation.label.passenger.accident.color': '#5BC8F8',
  'deviation.label.fall.color': '#29A2E7',
  'deviation.label.electricity.accident.color': '#1A5D9C',
  'deviation.label.infra.failures.color': '#F9DD86',
  'deviation.label.dangers.color': '#F6C637',
  'deviation.label.traffic.failures.color': '#F39E1D',
  'deviation.label.disturbances.color': '#A5A5EF',
  'deviation.label.equipment.flaws.color': '#221d67',
  'deviation.label.other.accident.color': '#00003c'
}

const observationSummaryLabels = {
  'observation.label.safety.observation': 'Turvallisuushavainto',
  'observation.label.danger': 'Vaaratilanne',
  'observation.label.improvement.proposal': 'Parannusehdotus',
  'observation.label.environment.observation': 'Ympäristöhavainto',
  'observation.label.positive.observation': 'Positiivinen havainto'
}

const observationSummaryLabelColors = {
  'observation.label.safety.observation.color': '#5BC8F8',
  'observation.label.danger.color': '#29A2E7',
  'observation.label.improvement.proposal.color': '#A5A5EF',
  'observation.label.environment.observation.color': '#221d67',
  'observation.label.positive.observation.color': '#00003c'
}

const observationTableHeadCells = [
  { id: 'eventCategory', labelKey: 'event.type', sortable: true },
  { id: 'domain', labelKey: 'target', sortable: true },
  { id: 'eventTimestamp', labelKey: 'event.date', sortable: true },
  { id: 'description', labelKey: 'description', sortable: true },
  // { id: 'assignments', labelKey: 'assignment', sortable: true },
  { id: 'status', labelKey: 'handling.status', sortable: true },
  // { id: 'location', labelKey: 'location', sortable: false },
  { id: 'quenticUrl', labelKey: 'additional.info', sortable: false }
]

const observationTableCells = [
  { id: 'eventCategory', type: TABLE_CELL_TYPE_TEXT },
  { id: 'domain', type: TABLE_CELL_TYPE_TEXT },
  { id: 'eventTimestamp', type: TABLE_CELL_TYPE_DATE },
  { id: 'description', type: TABLE_CELL_TYPE_TEXT },
  // { id: 'assignments', type: TABLE_CELL_TYPE_LINK },
  { id: 'status', type: TABLE_CELL_TYPE_STATUS },
  // { id: 'location', type: TABLE_CELL_TYPE_POPUP },
  { id: 'quenticUrl', type: TABLE_CELL_TYPE_EXTERNAL_LINK }
]

const DEVIATION_SUMMARY_CATEGORY_OPEN = 'open'
const DEVIATION_SUMMARY_CATEGORY_ASSIGNED = 'assigned'
const DEVIATION_SUMMARY_CATEGORY_PROCESSED = 'processed'
const DEVIATION_SUMMARY_CATEGORY_OPEN_ASSIGNMENTS = 'openAssignments'

const deviationTableFilters = [
  {
    id: 'eventCategory',
    legendKey: 'event.type',
    filters: []
  },
  {
    id: 'domain',
    legendKey: 'target',
    filters: []
  },
  {
    id: 'status',
    legendKey: 'handling.status',
    filters: []
  },
  {
    id: 'summaryCategories',
    legendKey: 'summary',
    filters: [
      DEVIATION_SUMMARY_CATEGORY_OPEN,
      DEVIATION_SUMMARY_CATEGORY_ASSIGNED,
      DEVIATION_SUMMARY_CATEGORY_PROCESSED
      // DEVIATION_SUMMARY_CATEGORY_OPEN_ASSIGNMENTS
    ]
  }
]

export {
  deviationSummaryLabels,
  deviationSummaryLabelColors,
  observationSummaryLabels,
  observationSummaryLabelColors,
  observationTableHeadCells,
  observationTableCells,
  deviationTableFilters,
  DEVIATION_SUMMARY_CATEGORY_OPEN,
  DEVIATION_SUMMARY_CATEGORY_ASSIGNED,
  DEVIATION_SUMMARY_CATEGORY_PROCESSED,
  DEVIATION_SUMMARY_CATEGORY_OPEN_ASSIGNMENTS
}
