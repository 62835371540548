export const ACTIVATE_MALFUNCTION_DATA = 'malfunctions/activateMalfunctionData'
export const SET_INFRA_MALFUNCTION_DATA = 'malfunctions/setInfraMalfunctionData'
export const SET_INFRA_MALFUNCTION_SUMMARY_DATA = 'malfunctions/setInfraMalfunctionSummaryData'
export const SET_EQUIPMENT_MALFUNCTION_DATA = 'malfunctions/setEquipmentMalfunctionData'
export const SET_EQUIPMENT_MALFUNCTION_SUMMARY_DATA = 'malfunctions/setEquipmentMalfunctionSummaryData'
export const SET_MALFUNCTION_DATA_FILTERS = 'malfunctions/setMalfunctionDataFilters'
export const TOGGLE_MALFUNCTION_DATA_FILTER = 'malfunctions/toggleMalfunctionDataFilter'
export const TOGGLE_MALFUNCTION_TABLE_ORDER = 'malfunctions/toggleMalfunctionTableOrder'
export const SET_MALFUNCTION_TABLE_COLUMN_TO_ORDER_BY = 'malfunctions/setMalfunctionTableColumnToOrderBy'
export const TOGGLE_MALFUNCTION_TABLE_DENSITY = 'malfunctions/toggleMalfunctionTableDensity'
export const SET_MALFUNCTION_TABLE_PAGE = 'malfunctions/setMalfunctionTablePage'
export const SET_MALFUNCTION_TABLE_ROWS_PER_PAGE = 'malfunctions/setMalfunctionTableRowsPerPage'
