import {
  ONLINE_STATUS_PROHIBITED_FROM_TRAFFIC,
  STATUS_COLOR_GREEN,
  STATUS_COLOR_ORANGE,
  STATUS_COLOR_RED
} from '../util/constants'
import {
  equipmentTableHeadCells,
  equipmentTableCells,
  equipmentMalfunctionsTableHeadCells,
  equipmentMalfunctionsTableCells,
  equipmentMalfunctionsTableFilters,
  equipmentMaintenancesTableHeadCells,
  equipmentMaintenancesTableCells,
  equipmentMaintenancesTableFilters,
  equipmentStatusSummaryLabels,
  equipmentStatusSummaryLabelColors,
  EQUIPMENT_OPEN_MALFUNCTIONS_STATUS_OK_COUNT,
  EQUIPMENT_OPEN_MALFUNCTIONS_STATUS_WARNING_COUNT
} from '../util/equipmentUtil'
import { createFilterList } from '../util/filterUtil'
import axios from 'axios'

const getData = async (url) => {
  const response = await axios.get(url)
  return response.data
}

const getEquipmentData = async () => {
  return getData('/tram-status-data')
}

const getEquipmentSummaryData = async () => {
  return getData('/tram-status-summary')
}

const getEquipmentMalfunctionsData = async () => {
  return getData('/tram-malfunctions')
}

const getEquipmentMalfunctionsSummaryData = async () => {
  return getData('/tram-malfunctions-summary')
}

const getEquipmentMaintenanceSummaryData = async () => {
  return getData('/tram-maintenance-summary')
}

const getEquipmentMaintenanceData = async () => {
  return getData('/tram-maintenance-data')
}

const constructSummaryForLabel = (retValue, amount, label, color) => {
  if (amount > 0) {
    retValue.data.labels.push(label)
    retValue.data.datasets[0].data.push(amount)
    retValue.colors.push(color)
  }
}

const getEquipmentStatusSummary = (data) => {
  const equipmentStatusDataSummary = { data: { labels: [], datasets: [{ data: [] }] }, colors: [] }
  constructSummaryForLabel(equipmentStatusDataSummary, data && data?.tramStatusInTraffic,
    equipmentStatusSummaryLabels['status.label.in.traffic'], equipmentStatusSummaryLabelColors['status.label.in.traffic.color'])
  constructSummaryForLabel(equipmentStatusDataSummary, data && data?.tramStatusRestricted,
    equipmentStatusSummaryLabels['status.label.restricted'], equipmentStatusSummaryLabelColors['status.label.restricted.color'])
  constructSummaryForLabel(equipmentStatusDataSummary, data && data?.tramStatusInMaintenance,
    equipmentStatusSummaryLabels['status.label.in.maintenance'], equipmentStatusSummaryLabelColors['status.label.in.maintenance.color'])
  constructSummaryForLabel(equipmentStatusDataSummary, data && data?.tramStatusAvailableForTraffic,
    equipmentStatusSummaryLabels['status.label.available.for.traffic'], equipmentStatusSummaryLabelColors['status.label.available.for.traffic.color'])

  return equipmentStatusDataSummary
}

const getEquipmentStatusSummaryAmount = (data) => {
  if (!data || !data.tramsTotal) {
    return 0
  }

  return data.tramsTotal
}

const getEquipmentTableHeadCells = () => {
  return equipmentTableHeadCells
}

const getEquipmentTableCells = () => {
  return equipmentTableCells
}

const getEquipmentMalfunctionsTableHeadCells = () => {
  return equipmentMalfunctionsTableHeadCells
}

const getEquipmentMalfunctionsTableCells = () => {
  return equipmentMalfunctionsTableCells
}

const getEquipmentMalfunctionsTableFilters = (data) => {
  return createFilterList(data, equipmentMalfunctionsTableFilters)
}

const getEquipmentMaintenancesTableHeadCells = () => {
  return equipmentMaintenancesTableHeadCells
}

const getEquipmentMaintenancesTableCells = () => {
  return equipmentMaintenancesTableCells
}

const getEquipmentMaintenancesTableFilters = (data) => {
  return createFilterList(data, equipmentMaintenancesTableFilters)
}

const getEquipmentOpenMalfunctionsCountStatusColor = (count) => {
  let color = ''
  const countInt = parseInt(count)
  if ((countInt > EQUIPMENT_OPEN_MALFUNCTIONS_STATUS_OK_COUNT && countInt < EQUIPMENT_OPEN_MALFUNCTIONS_STATUS_WARNING_COUNT)) {
    color = STATUS_COLOR_ORANGE
  } else if (countInt >= EQUIPMENT_OPEN_MALFUNCTIONS_STATUS_WARNING_COUNT) {
    color = STATUS_COLOR_RED
  } else if (countInt === EQUIPMENT_OPEN_MALFUNCTIONS_STATUS_OK_COUNT) {
    color = STATUS_COLOR_GREEN
  }
  return color
}

const getRestrictedEquipment = (equipment) => {
  return equipment.filter(tram => tram.status === ONLINE_STATUS_PROHIBITED_FROM_TRAFFIC)
}

export default {
  getEquipmentData,
  getEquipmentSummaryData,
  getEquipmentStatusSummary,
  getEquipmentStatusSummaryAmount,
  getEquipmentMalfunctionsData,
  getEquipmentMaintenanceSummaryData,
  getEquipmentMaintenanceData,
  getEquipmentMalfunctionsSummaryData,
  getEquipmentTableHeadCells,
  getEquipmentTableCells,
  getEquipmentMalfunctionsTableHeadCells,
  getEquipmentMalfunctionsTableCells,
  getEquipmentMalfunctionsTableFilters,
  getEquipmentMaintenancesTableHeadCells,
  getEquipmentMaintenancesTableCells,
  getEquipmentMaintenancesTableFilters,
  getEquipmentOpenMalfunctionsCountStatusColor,
  getRestrictedEquipment
}
