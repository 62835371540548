import React from 'react'
import { useTranslation } from 'react-i18next'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import ConditionalWrapper from '../ConditionalWrapper/ConditionalWrapper'

function RecordSummary (props) {
  const { t } = useTranslation()
  const { label, color, icon, amount, trend, reverseIndicator, active, clickFn } = props

  const onClickFn = () => {
    clickFn()
  }

  const getTrendIndicator = (reverseIndicator, trend) => {
    if (trend < 0) {
      if (reverseIndicator) {
        return <ArrowDownward className='positive' />
      } else {
        return <ArrowUpward className='negative' />
      }
    } else {
      if (reverseIndicator) {
        return <ArrowUpward className='positive' />
      } else {
        return <ArrowDownward className='negative' />
      }
    }
  }

  return (
    <div className='record-summary-item'>
      <ConditionalWrapper
        condition={clickFn}
        wrapper={children => <button type='button' onClick={() => onClickFn()}>{children}</button>}
      >
        <>
          <span className='record-container'>
            <span className={`record-icon ${color} ${active ? 'active' : ''}`}>
              {icon}
            </span>
            <span className='record-info'>
              <span className='record-values'>
                <span className='record-value'>{amount}</span>
                {trend && (
                  <span className='record-trend-info'>
                    {getTrendIndicator(reverseIndicator, trend)}
                    <span className='record-trend text-muted'>{t('percentage.past.day', { trend: trend })}</span><br />
                  </span>
                )}
              </span>
              <span className='record-label'>{label}</span>
            </span>
          </span>
        </>
      </ConditionalWrapper>
    </div>
  )
}

export default RecordSummary
