import axios from 'axios'
import { getTramDataExpirationTimerTimeOut } from '../util/tramUtil'
import { setTramDataExpiryStatus } from '../store/map/actions'
import { TRAM_ID } from '../store/map/reducers'

const getTrams = async () => {
  const response = await axios.get('/trams')
  return response.data
}

const tramDataTimers = {}

const setTramDataTimers = (trams, dispatch) => {
  if (trams && trams.length > 0) {
    Object.keys(trams).forEach(function (key) {
      const tram = trams[key]
      const tramId = tram.tramId
      const tramDataExpiryStatus = tram.dataExpired
      if (!tramDataExpiryStatus || tramDataExpiryStatus === false) {
        const timeOutMilliseconds = getTramDataExpirationTimerTimeOut(tram.dataFetchTimestamp, tram.timestamp)
        if (tramId in tramDataTimers) {
          clearTimeout(tramDataTimers[tramId].timer)
          tramDataTimers[tramId].timer = setTimeout(() => {
            dispatch(setTramDataExpiryStatus(TRAM_ID, tramId, true))
          }, timeOutMilliseconds)
        } else {
          tramDataTimers[tramId] = {
            timer: setTimeout(() => {
              dispatch(setTramDataExpiryStatus(TRAM_ID, tramId, true))
            }, timeOutMilliseconds)
          }
        }
      }
    })
  }
}

const getTramsPassengerCount = async () => {
  const url = '/trams-passenger-count'
  const response = await axios.get(url)
  const result = response.data
  for (const key in response.data.data) {
    result.data[key] = response?.data?.data?.[key] ? response.data?.data?.[key]?.map(mapPassengerDataToChartDataset) : []
  }
  return result
}

const mapPassengerDataToChartDataset = (item) => {
  return {
    t: item.timestamp,
    y: item.passengerCount
  }
}

const getTramsPassengerCountOnAverage = (data) => {
  return Math.round(data?.map(item => item.y).reduce((a, b) => a + b, 0) / data?.length)
}

const getTramsPassengerCountTotal = (data) => {
  return Math.round(data?.map(item => item.y).reduce((a, b) => a + b, 0))
}

export default {
  getTrams,
  setTramDataTimers,
  getTramsPassengerCount,
  getTramsPassengerCountOnAverage,
  getTramsPassengerCountTotal
}
