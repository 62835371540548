import React from 'react'
import './ColumnTopbar.scss'

const ColumnTopbar = ({ children }) => {
  return (
    <div className='column-topbar'>
      {children}
    </div>
  )
}

export default ColumnTopbar
