import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import getUser from '../services/userService'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { wsConnect, wsDisconnect } from '../middleware/webSocket'
import SiteFrame from '../components/SiteFrame/SiteFrame'

function ProtectedRoute ({ component: Component, ...props }) {
  const [user, setUser] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isAuthError, setIsAuthError] = useState(false)
  const [isError, setIsError] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  // Set defaults for axios to call secured APIs in servers
  axios.interceptors.response.use(config => {
    return config
  }, error => {
    if (error.response && error.response.status === 401) {
      console.log('Auth error, redirecting to authentication endpoint')
      setIsAuthError(true)
      return Promise.reject(error)
    }
    // TODO should we handle other errors globally or case by case?
    console.log(error)
    return Promise.reject(error)
  })
  axios.defaults.headers.common['x-functions-key'] = process.env.REACT_APP_API_KEY
  axios.defaults.baseURL = process.env.REACT_APP_API

  const setToken = (token) => {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  }

  useEffect(() => {
    setIsLoading(true)
    getUser().then(response => {
      // First setting token, then proceed with secured component
      setToken(response.token)
      // Connect to web socket with authentication
      dispatch(wsConnect(process.env.REACT_APP_WS_HOST, response.token))
      setUser(response)
      setIsLoading(false)
    }).catch(error => {
      if (error.response && error.response.status === 401) {
        console.log('Auth error, redirecting to authentication endpoint')
        setIsAuthError(true)
      } else {
        // Handle error, this is fatal as we could not get token
        setIsError(true)
      }
      console.log(error)
    })
    return () => {
      // on clean up disconnect the web socket
      dispatch(wsDisconnect(process.env.REACT_APP_WS_HOST))
    }
  }, [dispatch])

  if (isAuthError) {
    window.location.href = `${process.env.REACT_APP_LOGIN_URL}?post_login_redirect_url=${props.location.pathname}`
  }
  if (isError) {
    return (
      <Redirect to='/error' />
    )
  }

  return (
    <>
      {isLoading ? (
        <div>{t('page.loading')}</div>
      ) : (
        <>
          <SiteFrame user={user}>
            <Component token={user.token} />
          </SiteFrame>
        </>
      )}
    </>
  )
}

export default ProtectedRoute
