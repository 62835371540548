import React from 'react'
import './Drawer.scss'

function DrawerMain ({ children, open }) {
  return (
    <main className={`drawer-main ${open ? 'open' : ''}`}>
      {children}
    </main>
  )
}

export default DrawerMain
