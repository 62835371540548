import createTheme from '@material-ui/core/styles/createTheme'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1425
    }
  },
  palette: {
    primary: {
      light: '#A5A5EF',
      main: '#221d67',
      dark: '#00003c',
      contrastText: '#fff'
    },
    secondary: {
      light: '#c1ffff',
      main: '#8dd8f8',
      dark: '#59a7c5',
      contrastText: '#000'
    },
    success: {
      main: '#72C140',
      contrastText: '#fff'
    },
    info: {
      main: '#2BA3E7',
      dark: '#1A5D9C',
      contrastText: '#fff'
    },
    warning: {
      light: '#F9DD86',
      main: '#F6C637',
      dark: '#F4772E',
      contrastText: '#fff'
    },
    error: {
      main: '#F83245',
      contrastText: '#fff'
    }
  }
})

export default theme
