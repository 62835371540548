export const FILTER_GROUP_SUMMARY_CATEGORIES_ID = 'summaryCategories'

export const toggleFilter = (filters, filter) => {
  if (filters.includes(filter)) {
    return filters.filter(curFilter => curFilter !== filter)
  } else {
    return filters.concat(filter)
  }
}

export const hasActiveFilters = (filters) => {
  for (const filterGroupId in filters) {
    if (filters[filterGroupId].length > 0) {
      return true
    }
  }
}

export const isTheOnlyActiveFilter = (filters, filter) => {
  const filtersGrouped = []
  for (const filterGroupId in filters) {
    if (filters[filterGroupId].length > 0) {
      filtersGrouped.push(...filters[filterGroupId])
    }
    if (filtersGrouped.length > 1) {
      return false
    }
  }
  return filtersGrouped.includes(filter)
}

export const createFilterList = (data, filters) => {
  filters.forEach((filterGroup) => {
    if (filterGroup.filters.length === 0) {
      const filterOptions = data.map((item) => {
        return item[filterGroup.id]
      }).filter((item) => item)
      filterGroup.filters = [...new Set(filterOptions)] // removes duplicates
    }
  })
  return filters
}

export const filterData = (data, filters) => {
  let filteredData = data
  for (const filterGroupId in filters) {
    if (filters[filterGroupId].length > 0) {
      filteredData = filteredData.filter((item) => {
        if (Array.isArray(item[filterGroupId])) {
          return item[filterGroupId].some(childItem => filters[filterGroupId].includes(childItem))
        } else {
          return filters[filterGroupId].includes(item[filterGroupId])
        }
      })
    }
  }
  return filteredData
}
