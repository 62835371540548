import React from 'react'
import { useTranslation } from 'react-i18next'
import Tram from '@material-ui/icons/Tram'
import Speed from '@material-ui/icons/Speed'
import CallSplit from '@material-ui/icons/CallSplit'
import AccessTime from '@material-ui/icons/AccessTime'
import TimelapseOutlinedIcon from '@material-ui/icons/TimelapseOutlined'
import {
  getLocaleDateString,
  getLocaleTimeString,
  getLocaleTimeWithSecondsString,
  millisToMinutesAndSeconds
} from '../../../../util/commonUtil'

const TramPopup = (props) => {
  const { t } = useTranslation()
  const { data } = props
  const tramId = data.get('tramId')
  const tramName = data.get('tramName')
  const tramLine = data.get('tramLine')
  const capacity = data.get('capacity')
  const passengerCountTotal = data.get('passengerCountTotal') ? data.get('passengerCountTotal') : 0
  const speed = data.get('speed')
  const tramLocationTimestamp = data.get('tramLocationTimestamp')
  const tramLocationTimestampDateString = tramLocationTimestamp ? getLocaleDateString(tramLocationTimestamp) : '?'
  const tramLocationTimestampTimeString = tramLocationTimestamp ? getLocaleTimeWithSecondsString(tramLocationTimestamp) : '?'
  const passengerCountingTimestamp = data.get('passengerCountingTimestamp')
  const passengerCountingTimestampDateString = passengerCountingTimestamp ? getLocaleDateString(passengerCountingTimestamp) : '?'
  const passengerCountingTimestampTimeString = passengerCountingTimestamp ? getLocaleTimeString(passengerCountingTimestamp) : '?'
  const prevStop = data.get('prevStop') || '?'
  const nextStop = data.get('nextStop') || '?'
  const delayMs = data.get('delayMs')
  const delay = millisToMinutesAndSeconds(delayMs)

  return (
    <>
      <div className='ol-popup-topbar'>
        <div className='topbar-row'>
          <div className='topbar-row-item graphic-data-item'>
            <CallSplit />
            <span>{tramLine ? t('tram.line.with.value', { line: tramLine }) : t('tram.not.in.line')}</span>
          </div>
          <div className='topbar-row-item graphic-data-item'>
            <Tram />
            <span>{tramId} {tramName}</span>
          </div>
        </div>
        <div className='text-muted'>{tramLocationTimestampDateString + ' ' + t('time.o.clock', { time: tramLocationTimestampTimeString })}</div>
      </div>
      <div className='ol-popup-content'>
        <div className='graphic-data-item'>
          <AccessTime />
          <span className='label'>{t('schedule')}:</span>
          <span>{delay}</span>
        </div>
        <div className='graphic-data-item multiline'>
          <TimelapseOutlinedIcon />
          <span>
            <span className='label'>{t('capacity.usage')}:</span>
            <span>{capacity} % ({passengerCountTotal + ' ' + t('passenger', { count: passengerCountTotal })})</span>
            <br />
            <span className='text-muted'>{prevStop + ' - ' + nextStop}</span>
            <br />
            <span className='text-muted'>{passengerCountingTimestampDateString + ' ' + t('time.o.clock', { time: passengerCountingTimestampTimeString })}</span>
          </span>
        </div>
        <div className='graphic-data-item'>
          <Speed />
          <span className='label'>{t('speed')}:</span>
          <span>{speed + ' km/h'}</span>
        </div>
      </div>
    </>
  )
}

export default TramPopup
