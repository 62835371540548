import { useContext, useEffect } from 'react'
import { ZoomSlider } from 'ol/control'
import MapContext from '../MapContext'
const ZoomSliderControl = () => {
  const { map } = useContext(MapContext)
  useEffect(() => {
    if (!map) return
    const zoomSliderControl = new ZoomSlider({})
    map.controls.push(zoomSliderControl)

    return () => map.controls.remove(zoomSliderControl)
  }, [map])
  return null
}
export default ZoomSliderControl
