import { SET_PASSENGER_COUNT, SET_PASSENGER_COUNT_FILTER } from './actionsTypes'
import { setDataWithDataTimestamp } from '../general/mutations'
import { LINE_FILTER_TOTAL } from '../../util/passengerCountUtil'

export const PASSENGER_COUNT_ID = 'passenger_count'

const initialState = {
  [PASSENGER_COUNT_ID]: {
    data: [],
    filter: LINE_FILTER_TOTAL,
    dataTimestamp: null
  }
}

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case SET_PASSENGER_COUNT_FILTER: {
      const { dataId, filter } = action.payload
      return {
        ...state,
        [dataId]: {
          ...state[dataId],
          filter: filter
        }
      }
    }
    case SET_PASSENGER_COUNT: {
      const { dataId, data, dataTimestamp } = action.payload
      return setDataWithDataTimestamp(state, dataId, data, dataTimestamp)
    }
    default:
      return state
  }
}

export default dashboard
