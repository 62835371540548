export const activateData = (type, dataId) => ({
  type: type,
  payload: { dataId }
})

export const activateDataSubset = (type, dataId, subsetId) => ({
  type: type,
  payload: {
    dataId: dataId,
    subsetId: subsetId
  }
})

export const deactivateDataSubset = (type, dataId, subsetId) => ({
  type: type,
  payload: {
    dataId: dataId,
    subsetId: subsetId
  }
})

export const setDataFilters = (type, dataId, filterGroupId, filters) => ({
  type: type,
  payload: {
    dataId: dataId,
    filterGroupId: filterGroupId,
    filters: filters
  }
})

export const toggleDataFilter = (type, dataId, filterGroupId, filter) => ({
  type: type,
  payload: {
    dataId: dataId,
    filterGroupId: filterGroupId,
    filter: filter
  }
})

export const toggleTableOrder = (type, order) => ({
  type: type,
  payload: {
    order: order
  }
})

export const setTableColumnToOrderBy = (type, orderBy) => ({
  type: type,
  payload: {
    orderBy: orderBy
  }
})

export const toggleTableDensity = (type, dense) => ({
  type: type,
  payload: {
    dense: dense
  }
})

export const setTablePage = (type, page) => ({
  type: type,
  payload: {
    page: page
  }
})

export const setTableRowsPerPage = (type, rowsPerPage) => ({
  type: type,
  payload: {
    rowsPerPage: rowsPerPage
  }
})
