import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MapContext from '../MapContext'
import Overlay from 'ol/Overlay'
import './PopupOverlay.scss'
import Cancel from '@material-ui/icons/Cancel'
import TramPopup from './Popup/TramPopup'
import { POINT_TYPE_ID_KEY } from '../../../util/mapUtil'
import { TRAM_ID, INFRA_ID } from '../../../store/map/reducers'
import InfraPopup from './Popup/InfraPopup'

const PopupOverlay = (props) => {
  const { t } = useTranslation()
  const { livePopups } = props
  const { map } = useContext(MapContext)
  const popupContainer = useRef(null)
  const popupContainerElement = popupContainer.current
  const popupCloser = useRef(null)
  const popupCloserElement = popupCloser.current
  const [popupOverlay, setPopupOverlay] = useState(null)
  const [openedPointTypeId, setOpenedPointTypeId] = useState(null)
  const [openedPointId, setOpenedPointId] = useState(null)
  const [openedPoint, setOpenedPoint] = useState(null)

  useEffect(() => {
    if (!map) return

    const overlay = new Overlay({
      element: popupContainerElement
    })
    map.addOverlay(overlay)
    setPopupOverlay(overlay)

    map.on('singleclick', function (event) {
      if (map.hasFeatureAtPixel(event.pixel)) {
        const feature = map.forEachFeatureAtPixel(event.pixel, function (feature) {
          return feature
        })
        const typeId = feature.get(POINT_TYPE_ID_KEY)
        const id = feature.getId()
        const coordinate = feature.getGeometry() && feature.getGeometry().getCoordinates()
        if (typeId) {
          setOpenedPointTypeId(typeId)
          setOpenedPointId(id)
          setOpenedPoint(feature)
          overlay.setPosition(coordinate)
        }
      } else {
        setOpenedPointTypeId(null)
        setOpenedPointId(null)
        setOpenedPoint(null)
        overlay.setPosition(undefined)
        popupCloserElement.blur()
      }
    })

    popupCloserElement.onclick = function () {
      setOpenedPointTypeId(null)
      setOpenedPointId(null)
      setOpenedPoint(null)
      overlay.setPosition(undefined)
      popupCloserElement.blur()
    }

    return () => {
      if (map) {
        setOpenedPointTypeId(null)
        setOpenedPointId(null)
        setOpenedPoint(null)
        overlay.setPosition(undefined)
        map.removeOverlay(overlay)
      }
    }
  }, [map, popupContainerElement, popupCloserElement])

  useEffect(() => {
    const tramPoints = livePopups.tramPoints || []
    if (openedPointTypeId === TRAM_ID && tramPoints && openedPointId) {
      const updatedOpenedPoint = tramPoints.getFeatureById(openedPointId)
      const coordinate = updatedOpenedPoint.getGeometry() && updatedOpenedPoint.getGeometry().getCoordinates()
      popupOverlay.setPosition(coordinate)
      setOpenedPoint(updatedOpenedPoint)
    }
  }, [livePopups, openedPointId, openedPointTypeId, popupOverlay])

  return (
    <div className={`ol-popup ${openedPointTypeId}`} ref={popupContainer}>
      <button type='button' className='ol-popup-closer' ref={popupCloser} aria-label={t('close.popup')}><Cancel /></button>
      <div className='ol-popup-wrapper'>
        {openedPointTypeId === TRAM_ID && openedPoint && (
          <TramPopup data={openedPoint} />
        )}
        {openedPointTypeId === INFRA_ID && openedPoint && (
          <InfraPopup data={openedPoint} />
        )}
      </div>
    </div>
  )
}
export default PopupOverlay
