import axios from 'axios'
import { DATA_CATEGORY_UNCATEGORIZED, DATA_CATEGORY_UNCATEGORIZED_COLOR } from '../util/constants'
import {
  deviationSummaryLabels, deviationSummaryLabelColors,
  observationSummaryLabels, observationSummaryLabelColors,
  observationTableHeadCells, observationTableCells, deviationTableFilters
} from '../util/deviationUtil'
import { createFilterList } from '../util/filterUtil'

const getObservations = async (dataId, limitDays) => {
  let url = `/observations?type=${dataId}`
  if (limitDays) {
    url += `&limitDays=${limitDays}`
  }
  const response = await axios.get(url)
  return response.data
}

const filterDataAndGetAmount = (data, fieldKey, filter, labels) => {
  if (filter === DATA_CATEGORY_UNCATEGORIZED) {
    for (const key in labels) {
      data = data.filter(item => item[fieldKey] !== labels[key])
    }
    return data.length
  }
  return data.filter(item => item[fieldKey] === filter).length
}

const constructSummaryForLabel = (retValue, label, color, data, labels) => {
  const categoryKey = 'eventCategory'
  const amount = filterDataAndGetAmount(data, categoryKey, label, labels)

  if (amount > 0) {
    retValue.data.labels.push(label)
    retValue.data.datasets[0].data.push(amount)
    retValue.colors.push(color)
  }
}

const getTramTrafficDeviationsSummary = (data) => {
  const deviationDataSummary = { data: { labels: [], datasets: [{ data: [] }] }, colors: [] }
  constructSummaryForLabel(deviationDataSummary, deviationSummaryLabels['deviation.label.crash'],
    deviationSummaryLabelColors['deviation.label.crash.color'], data)
  constructSummaryForLabel(deviationDataSummary, deviationSummaryLabels['deviation.label.passenger.accident'],
    deviationSummaryLabelColors['deviation.label.passenger.accident.color'], data)
  constructSummaryForLabel(deviationDataSummary, deviationSummaryLabels['deviation.label.fall'],
    deviationSummaryLabelColors['deviation.label.fall.color'], data)
  constructSummaryForLabel(deviationDataSummary, deviationSummaryLabels['deviation.label.electricity.accident'],
    deviationSummaryLabelColors['deviation.label.electricity.accident.color'], data)
  constructSummaryForLabel(deviationDataSummary, deviationSummaryLabels['deviation.label.infra.failures'],
    deviationSummaryLabelColors['deviation.label.infra.failures.color'], data)
  constructSummaryForLabel(deviationDataSummary, deviationSummaryLabels['deviation.label.dangers'],
    deviationSummaryLabelColors['deviation.label.dangers.color'], data)
  constructSummaryForLabel(deviationDataSummary, deviationSummaryLabels['deviation.label.traffic.failures'],
    deviationSummaryLabelColors['deviation.label.traffic.failures.color'], data)
  constructSummaryForLabel(deviationDataSummary, deviationSummaryLabels['deviation.label.disturbances'],
    deviationSummaryLabelColors['deviation.label.disturbances.color'], data)
  constructSummaryForLabel(deviationDataSummary, deviationSummaryLabels['deviation.label.equipment.flaws'],
    deviationSummaryLabelColors['deviation.label.equipment.flaws.color'], data)
  constructSummaryForLabel(deviationDataSummary, deviationSummaryLabels['deviation.label.other.accident'],
    deviationSummaryLabelColors['deviation.label.other.accident.color'], data)
  constructSummaryForLabel(deviationDataSummary, DATA_CATEGORY_UNCATEGORIZED, DATA_CATEGORY_UNCATEGORIZED_COLOR, data, deviationSummaryLabels)

  return deviationDataSummary
}

const getSafetyObservationsSummary = (data) => {
  const observationDataSummary = { data: { labels: [], datasets: [{ data: [] }] }, colors: [] }
  constructSummaryForLabel(observationDataSummary, observationSummaryLabels['observation.label.safety.observation'],
    observationSummaryLabelColors['observation.label.safety.observation.color'], data)
  constructSummaryForLabel(observationDataSummary, observationSummaryLabels['observation.label.danger'],
    observationSummaryLabelColors['observation.label.danger.color'], data)
  constructSummaryForLabel(observationDataSummary, observationSummaryLabels['observation.label.improvement.proposal'],
    observationSummaryLabelColors['observation.label.improvement.proposal.color'], data)
  constructSummaryForLabel(observationDataSummary, observationSummaryLabels['observation.label.environment.observation'],
    observationSummaryLabelColors['observation.label.environment.observation.color'], data)
  constructSummaryForLabel(observationDataSummary, observationSummaryLabels['observation.label.positive.observation'],
    observationSummaryLabelColors['observation.label.positive.observation.color'], data)
  constructSummaryForLabel(observationDataSummary, DATA_CATEGORY_UNCATEGORIZED, DATA_CATEGORY_UNCATEGORIZED_COLOR, data, observationSummaryLabels)

  return observationDataSummary
}

const getObservationTableHeadCells = () => {
  return observationTableHeadCells
}

const getObservationTableCells = () => {
  return observationTableCells
}

const getDeviationTableFilters = (data) => {
  return createFilterList(data, deviationTableFilters)
}

const getNumberOfAssignments = (data) => {
  let numberOfAssignments = 0
  data.forEach(item => {
    numberOfAssignments = numberOfAssignments + item.assignments.length
  })
  return numberOfAssignments
}

export default {
  filterDataAndGetAmount,
  getObservations,
  getTramTrafficDeviationsSummary,
  getSafetyObservationsSummary,
  getNumberOfAssignments,
  getObservationTableHeadCells,
  getObservationTableCells,
  getDeviationTableFilters
}
