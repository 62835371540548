import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Close from '@material-ui/icons/Close'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import AccordionMenu from '../../components/AccordionMenu/AccordionMenu'
import FormGroup from '@material-ui/core/FormGroup'
import Drawer from '../../components/Drawer/Drawer'
import DrawerMenu from '../../components/Drawer/DrawerMenu'
import DrawerMain from '../../components/Drawer/DrawerMain'
import DrawerToggle from '../../components/Drawer/DrawerToggle'
import Map from '../../components/Map/Map'
import {
  Layers,
  TileLayer,
  VectorLayer
} from '../../components/Map/Layers'
import PopupOverlay from '../../components/Map/PopupOverlay/PopupOverlay'
import {
  Controls,
  FullScreenControl,
  OverviewMapControl,
  ScaleLineControl,
  ZoomSliderControl
} from '../../components/Map/Controls'
import LayerVisibilityToggle from '../../components/Map/FilterControls/LayerVisibilityToggle'
import {
  INFRA_RESOURCE_TYPE_TRAM_STOPS,
  INFRA_RESOURCE_TYPE_RAIL_SWITCHES,
  INFRA_RESOURCE_TYPE_RAIL_INTERSECTIONS,
  INFRA_RESOURCE_TYPE_CHARGING_STATIONS,
  INFRA_RESOURCE_TYPE_TRAM_STOP_CENTER,
  INFRA_RESOURCE_TYPE_RAIL_SWITCH_HEATING_CENTER,
  INFRA_RESOURCE_TYPE_RAIL_SWITCH_CONTROL_CENTER,
  INFRA_RESOURCE_TYPE_BRIDGES,
  INFRA_RESOURCE_TYPE_RETAINING_WALLS,
  INFRA_RESOURCE_TYPE_CAPACITORS,
  INFRA_RESOURCE_TYPE_DISCONNECTORS
} from '../../util/mapUtil'
import { isSubsetDataActive } from '../../util/commonUtil'
import mapService from '../../services/mapService'
import { useDispatch, useSelector } from 'react-redux'
import {
  ACTIVATE_MAP_DATA_SUBSET,
  DEACTIVATE_MAP_DATA_SUBSET
} from '../../store/map/actionTypes'
import {
  setMapTramData,
  setMapInfraData,
  toggleMapDataActivity
} from '../../store/map/actions'
import {
  activateDataSubset,
  deactivateDataSubset
} from '../../store/general/actions'
import { MAP_SLICE_ID } from '../../store'
import {
  TRAM_ID,
  INFRA_ID,
  INFRA_TRAM_STOP_ID,
  INFRA_RAIL_SWITCH_ID,
  INFRA_RAIL_INTERSECTION_ID,
  INFRA_CHARGING_STATION_ID,
  INFRA_TRAM_STOP_CENTER_ID,
  INFRA_RAIL_SWITCH_HEATING_CENTER_ID,
  INFRA_RAIL_SWITCH_CONTROL_CENTER_ID,
  INFRA_BRIDGE_ID,
  INFRA_RETAINING_WALL_ID,
  INFRA_CAPACITOR_ID,
  INFRA_DISCONNECTOR_ID
} from '../../store/map/reducers'

function Live () {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [filterMenuOpen, setFilterMenuOpen] = useState(false)

  // TODO: TEMPORARY UNTIL TRO-289
  const tramsActive = useSelector(state => state[MAP_SLICE_ID][TRAM_ID].active)

  const activeInfras = useSelector(state => state[MAP_SLICE_ID][INFRA_ID].active)
  const activeInfrasCount = useSelector(state => state[MAP_SLICE_ID][INFRA_ID].active).length
  const tramLocations = useSelector(state => state[MAP_SLICE_ID][TRAM_ID].locationData)
  const tramPassengers = useSelector(state => state[MAP_SLICE_ID][TRAM_ID].passengerData)
  const tramLines = useSelector(state => state[MAP_SLICE_ID][TRAM_ID].lineData)
  const tramMetadata = useSelector(state => state[MAP_SLICE_ID][TRAM_ID].tramMetadata)
  const showError = useSelector(state => state[MAP_SLICE_ID][TRAM_ID].connectionError)

  const [tramPoints, setTramPoints] = useState(null)
  const [tramLayer, setTramLayer] = useState(null)
  const [tramStopPoints, setTramStopPoints] = useState(null)
  const [tramStopLayer, setTramStopLayer] = useState(null)
  const [railSwitchPoints, setRailSwitchPoints] = useState(null)
  const [railSwitchLayer, setRailSwitchLayer] = useState(null)
  const [railIntersectionPoints, setRailIntersectionPoints] = useState(null)
  const [railIntersectionLayer, setRailIntersectionLayer] = useState(null)
  const [chargingStationPoints, setChargingStationPoints] = useState(null)
  const [chargingStationLayer, setChargingStationLayer] = useState(null)
  const [tramStopCenterPoints, setTramStopCenterPoints] = useState(null)
  const [tramStopCenterLayer, setTramStopCenterLayer] = useState(null)
  const [railSwitchHeatingCenterPoints, setRailSwitchHeatingCenterPoints] = useState(null)
  const [railSwitchHeatingCenterLayer, setRailSwitchHeatingCenterLayer] = useState(null)
  const [railSwitchControlCenterPoints, setRailSwitchControlCenterPoints] = useState(null)
  const [railSwitchControlCenterLayer, setRailSwitchControlCenterLayer] = useState(null)
  const [bridgePoints, setBridgePoints] = useState(null)
  const [bridgeLayer, setBridgeLayer] = useState(null)
  const [retainingWallPoints, setRetainingWallPoints] = useState(null)
  const [retainingWallLayer, setRetainingWallLayer] = useState(null)
  const [capacitorPoints, setCapacitorPoints] = useState(null)
  const [capacitorLayer, setCapacitorLayer] = useState(null)
  const [disconnectorPoints, setDisconnectorPoints] = useState(null)
  const [disconnectorLayer, setDisconnectorLayer] = useState(null)

  useEffect(() => {
    dispatch(setMapTramData(TRAM_ID))
    dispatch(setMapInfraData(INFRA_ID, INFRA_TRAM_STOP_ID, INFRA_RESOURCE_TYPE_TRAM_STOPS)).then((data) => {
      setTramStopPoints(mapService.getInfraPoints(data, INFRA_TRAM_STOP_ID))
    })
    dispatch(setMapInfraData(INFRA_ID, INFRA_RAIL_SWITCH_ID, INFRA_RESOURCE_TYPE_RAIL_SWITCHES)).then((data) => {
      setRailSwitchPoints(mapService.getInfraPoints(data, INFRA_RAIL_SWITCH_ID))
    })
    dispatch(setMapInfraData(INFRA_ID, INFRA_RAIL_INTERSECTION_ID, INFRA_RESOURCE_TYPE_RAIL_INTERSECTIONS)).then((data) => {
      setRailIntersectionPoints(mapService.getInfraPoints(data, INFRA_RAIL_INTERSECTION_ID))
    })
    dispatch(setMapInfraData(INFRA_ID, INFRA_CHARGING_STATION_ID, INFRA_RESOURCE_TYPE_CHARGING_STATIONS)).then((data) => {
      setChargingStationPoints(mapService.getInfraPoints(data, INFRA_CHARGING_STATION_ID))
    })
    dispatch(setMapInfraData(INFRA_ID, INFRA_TRAM_STOP_CENTER_ID, INFRA_RESOURCE_TYPE_TRAM_STOP_CENTER)).then((data) => {
      setTramStopCenterPoints(mapService.getInfraPoints(data, INFRA_TRAM_STOP_CENTER_ID))
    })
    dispatch(setMapInfraData(INFRA_ID, INFRA_RAIL_SWITCH_HEATING_CENTER_ID, INFRA_RESOURCE_TYPE_RAIL_SWITCH_HEATING_CENTER)).then((data) => {
      setRailSwitchHeatingCenterPoints(mapService.getInfraPoints(data, INFRA_RAIL_SWITCH_HEATING_CENTER_ID))
    })
    dispatch(setMapInfraData(INFRA_ID, INFRA_RAIL_SWITCH_CONTROL_CENTER_ID, INFRA_RESOURCE_TYPE_RAIL_SWITCH_CONTROL_CENTER)).then((data) => {
      setRailSwitchControlCenterPoints(mapService.getInfraPoints(data, INFRA_RAIL_SWITCH_CONTROL_CENTER_ID))
    })
    dispatch(setMapInfraData(INFRA_ID, INFRA_BRIDGE_ID, INFRA_RESOURCE_TYPE_BRIDGES)).then((data) => {
      setBridgePoints(mapService.getInfraPoints(data, INFRA_BRIDGE_ID))
    })
    dispatch(setMapInfraData(INFRA_ID, INFRA_RETAINING_WALL_ID, INFRA_RESOURCE_TYPE_RETAINING_WALLS)).then((data) => {
      setRetainingWallPoints(mapService.getInfraPoints(data, INFRA_RETAINING_WALL_ID))
    })
    dispatch(setMapInfraData(INFRA_ID, INFRA_CAPACITOR_ID, INFRA_RESOURCE_TYPE_CAPACITORS)).then((data) => {
      setCapacitorPoints(mapService.getInfraPoints(data, INFRA_CAPACITOR_ID))
    })
    dispatch(setMapInfraData(INFRA_ID, INFRA_DISCONNECTOR_ID, INFRA_RESOURCE_TYPE_DISCONNECTORS)).then((data) => {
      setDisconnectorPoints(mapService.getInfraPoints(data, INFRA_DISCONNECTOR_ID))
    })
  }, [dispatch])

  useEffect(() => {
    setTramPoints(mapService.getTramPoints(tramLocations, tramPassengers, tramLines, tramMetadata))
  }, [tramLocations, tramPassengers, tramLines, tramMetadata])

  return (
    <div className='map-container'>
      <Drawer className='anchor-left'>
        <DrawerMenu
          anchor='left'
          open={filterMenuOpen}
        >
          <Typography align='right'>
            <Button className='close-fiter-menu-toggle' onClick={() => setFilterMenuOpen(false)}>
              <Close />
            </Button>
          </Typography>
          <Divider />
          <AccordionMenu id={TRAM_ID} heading={t('trams')}>
            <FormGroup>
              {tramLayer &&
                <LayerVisibilityToggle
                  label={t('trams')}
                  checked={tramsActive}
                  activateFn={() => dispatch(toggleMapDataActivity(TRAM_ID))}
                  deactivateFn={() => dispatch(toggleMapDataActivity(TRAM_ID))}
                />}
            </FormGroup>
          </AccordionMenu>
          <AccordionMenu id={INFRA_ID} heading={t('infra')} suffix={activeInfrasCount}>
            <FormGroup>
              {tramStopLayer &&
                <LayerVisibilityToggle
                  label={t('tram.stops')}
                  checked={isSubsetDataActive(activeInfras, INFRA_TRAM_STOP_ID)}
                  activateFn={() => dispatch(activateDataSubset(ACTIVATE_MAP_DATA_SUBSET, INFRA_ID, INFRA_TRAM_STOP_ID))}
                  deactivateFn={() => dispatch(deactivateDataSubset(DEACTIVATE_MAP_DATA_SUBSET, INFRA_ID, INFRA_TRAM_STOP_ID))}
                />}
              {railSwitchLayer &&
                <LayerVisibilityToggle
                  label={t('rail.switches')}
                  checked={isSubsetDataActive(activeInfras, INFRA_RAIL_SWITCH_ID)}
                  activateFn={() => dispatch(activateDataSubset(ACTIVATE_MAP_DATA_SUBSET, INFRA_ID, INFRA_RAIL_SWITCH_ID))}
                  deactivateFn={() => dispatch(activateDataSubset(DEACTIVATE_MAP_DATA_SUBSET, INFRA_ID, INFRA_RAIL_SWITCH_ID))}
                />}
              {railIntersectionLayer &&
                <LayerVisibilityToggle
                  label={t('rail.intersections')}
                  checked={isSubsetDataActive(activeInfras, INFRA_RAIL_INTERSECTION_ID)}
                  activateFn={() => dispatch(activateDataSubset(ACTIVATE_MAP_DATA_SUBSET, INFRA_ID, INFRA_RAIL_INTERSECTION_ID))}
                  deactivateFn={() => dispatch(deactivateDataSubset(DEACTIVATE_MAP_DATA_SUBSET, INFRA_ID, INFRA_RAIL_INTERSECTION_ID))}
                />}
              {chargingStationLayer &&
                <LayerVisibilityToggle
                  label={t('charging.stations')}
                  checked={isSubsetDataActive(activeInfras, INFRA_CHARGING_STATION_ID)}
                  activateFn={() => dispatch(activateDataSubset(ACTIVATE_MAP_DATA_SUBSET, INFRA_ID, INFRA_CHARGING_STATION_ID))}
                  deactivateFn={() => dispatch(deactivateDataSubset(DEACTIVATE_MAP_DATA_SUBSET, INFRA_ID, INFRA_CHARGING_STATION_ID))}
                />}
              {tramStopCenterLayer &&
                <LayerVisibilityToggle
                  label={t('tram.stop.center')}
                  checked={isSubsetDataActive(activeInfras, INFRA_TRAM_STOP_CENTER_ID)}
                  activateFn={() => dispatch(activateDataSubset(ACTIVATE_MAP_DATA_SUBSET, INFRA_ID, INFRA_TRAM_STOP_CENTER_ID))}
                  deactivateFn={() => dispatch(deactivateDataSubset(DEACTIVATE_MAP_DATA_SUBSET, INFRA_ID, INFRA_TRAM_STOP_CENTER_ID))}
                />}
              {railSwitchHeatingCenterLayer &&
                <LayerVisibilityToggle
                  label={t('rail.switch.heating.center')}
                  checked={isSubsetDataActive(activeInfras, INFRA_RAIL_SWITCH_HEATING_CENTER_ID)}
                  activateFn={() => dispatch(activateDataSubset(ACTIVATE_MAP_DATA_SUBSET, INFRA_ID, INFRA_RAIL_SWITCH_HEATING_CENTER_ID))}
                  deactivateFn={() => dispatch(deactivateDataSubset(DEACTIVATE_MAP_DATA_SUBSET, INFRA_ID, INFRA_RAIL_SWITCH_HEATING_CENTER_ID))}
                />}
              {railSwitchControlCenterLayer &&
                <LayerVisibilityToggle
                  label={t('rail.switch.control.center')}
                  checked={isSubsetDataActive(activeInfras, INFRA_RAIL_SWITCH_CONTROL_CENTER_ID)}
                  activateFn={() => dispatch(activateDataSubset(ACTIVATE_MAP_DATA_SUBSET, INFRA_ID, INFRA_RAIL_SWITCH_CONTROL_CENTER_ID))}
                  deactivateFn={() => dispatch(deactivateDataSubset(DEACTIVATE_MAP_DATA_SUBSET, INFRA_ID, INFRA_RAIL_SWITCH_CONTROL_CENTER_ID))}
                />}
              {bridgeLayer &&
                <LayerVisibilityToggle
                  label={t('bridges')}
                  checked={isSubsetDataActive(activeInfras, INFRA_BRIDGE_ID)}
                  activateFn={() => dispatch(activateDataSubset(ACTIVATE_MAP_DATA_SUBSET, INFRA_ID, INFRA_BRIDGE_ID))}
                  deactivateFn={() => dispatch(deactivateDataSubset(DEACTIVATE_MAP_DATA_SUBSET, INFRA_ID, INFRA_BRIDGE_ID))}
                />}
              {retainingWallLayer &&
                <LayerVisibilityToggle
                  label={t('retaining.walls')}
                  checked={isSubsetDataActive(activeInfras, INFRA_RETAINING_WALL_ID)}
                  activateFn={() => dispatch(activateDataSubset(ACTIVATE_MAP_DATA_SUBSET, INFRA_ID, INFRA_RETAINING_WALL_ID))}
                  deactivateFn={() => dispatch(deactivateDataSubset(DEACTIVATE_MAP_DATA_SUBSET, INFRA_ID, INFRA_RETAINING_WALL_ID))}
                />}
              {capacitorLayer &&
                <LayerVisibilityToggle
                  label={t('capacitors')}
                  checked={isSubsetDataActive(activeInfras, INFRA_CAPACITOR_ID)}
                  activateFn={() => dispatch(activateDataSubset(ACTIVATE_MAP_DATA_SUBSET, INFRA_ID, INFRA_CAPACITOR_ID))}
                  deactivateFn={() => dispatch(deactivateDataSubset(DEACTIVATE_MAP_DATA_SUBSET, INFRA_ID, INFRA_CAPACITOR_ID))}
                />}
              {disconnectorLayer &&
                <LayerVisibilityToggle
                  label={t('disconnectors')}
                  checked={isSubsetDataActive(activeInfras, INFRA_DISCONNECTOR_ID)}
                  activateFn={() => dispatch(activateDataSubset(ACTIVATE_MAP_DATA_SUBSET, INFRA_ID, INFRA_DISCONNECTOR_ID))}
                  deactivateFn={() => dispatch(deactivateDataSubset(DEACTIVATE_MAP_DATA_SUBSET, INFRA_ID, INFRA_DISCONNECTOR_ID))}
                />}
            </FormGroup>
          </AccordionMenu>
        </DrawerMenu>
        <DrawerMain open={filterMenuOpen}>
          <DrawerToggle
            text={t('filter.view')}
            open={filterMenuOpen}
            setOpen={setFilterMenuOpen}
            altStyle
          />
          <Map>
            <Layers>
              <TileLayer />
              {tramPoints &&
                <VectorLayer
                  source={tramPoints}
                  setLayer={setTramLayer}
                  visible={tramsActive}
                  zIndex={1}
                />}
              {tramStopPoints &&
                <VectorLayer
                  source={tramStopPoints}
                  setLayer={setTramStopLayer}
                  visible={isSubsetDataActive(activeInfras, INFRA_TRAM_STOP_ID)}
                />}
              {railSwitchPoints &&
                <VectorLayer
                  source={railSwitchPoints}
                  setLayer={setRailSwitchLayer}
                  visible={isSubsetDataActive(activeInfras, INFRA_RAIL_SWITCH_ID)}
                />}
              {railIntersectionPoints &&
                <VectorLayer
                  source={railIntersectionPoints}
                  setLayer={setRailIntersectionLayer}
                  visible={isSubsetDataActive(activeInfras, INFRA_RAIL_INTERSECTION_ID)}
                />}
              {chargingStationPoints &&
                <VectorLayer
                  source={chargingStationPoints}
                  setLayer={setChargingStationLayer}
                  visible={isSubsetDataActive(activeInfras, INFRA_CHARGING_STATION_ID)}
                />}
              {tramStopCenterPoints &&
                <VectorLayer
                  source={tramStopCenterPoints}
                  setLayer={setTramStopCenterLayer}
                  visible={isSubsetDataActive(activeInfras, INFRA_TRAM_STOP_CENTER_ID)}
                />}
              {railSwitchHeatingCenterPoints &&
                <VectorLayer
                  source={railSwitchHeatingCenterPoints}
                  setLayer={setRailSwitchHeatingCenterLayer}
                  visible={isSubsetDataActive(activeInfras, INFRA_RAIL_SWITCH_HEATING_CENTER_ID)}
                />}
              {railSwitchControlCenterPoints &&
                <VectorLayer
                  source={railSwitchControlCenterPoints}
                  setLayer={setRailSwitchControlCenterLayer}
                  visible={isSubsetDataActive(activeInfras, INFRA_RAIL_SWITCH_CONTROL_CENTER_ID)}
                />}
              {bridgePoints &&
                <VectorLayer
                  source={bridgePoints}
                  setLayer={setBridgeLayer}
                  visible={isSubsetDataActive(activeInfras, INFRA_BRIDGE_ID)}
                />}
              {retainingWallPoints &&
                <VectorLayer
                  source={retainingWallPoints}
                  setLayer={setRetainingWallLayer}
                  visible={isSubsetDataActive(activeInfras, INFRA_RETAINING_WALL_ID)}
                />}
              {capacitorPoints &&
                <VectorLayer
                  source={capacitorPoints}
                  setLayer={setCapacitorLayer}
                  visible={isSubsetDataActive(activeInfras, INFRA_CAPACITOR_ID)}
                />}
              {disconnectorPoints &&
                <VectorLayer
                  source={disconnectorPoints}
                  setLayer={setDisconnectorLayer}
                  visible={isSubsetDataActive(activeInfras, INFRA_DISCONNECTOR_ID)}
                />}
            </Layers>
            <PopupOverlay
              livePopups={{
                tramPoints: tramPoints
              }}
            />
            {showError && <div className='error-text'>{t('live.connection.error')}</div>}
            <Controls>
              <ZoomSliderControl />
              <ScaleLineControl />
              <OverviewMapControl />
              <FullScreenControl />
            </Controls>
          </Map>
        </DrawerMain>
      </Drawer>
    </div>
  )
}

export default Live
