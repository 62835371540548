import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'

function AspectRatioWrapper ({ children, width, height }) {
  const useStyles = makeStyles({
    aspectRatio: {
      position: 'relative',
      '&:before': {
        display: 'block',
        content: '""',
        width: 100 + '%',
        paddingTop: `calc(${height} / ${width} * 100%)`
      }
    },
    content: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }
  })
  const classes = useStyles()

  return (
    <div className={classes.aspectRatio}>
      <div className={classes.content}>
        {children}
      </div>
    </div>
  )
}

export default AspectRatioWrapper
