import Chart from 'chart.js'

const RadiusBackground = function () {
  const self = this
  const DEFAULT_BACKGROUND_COLOR = '#d1d1d1'

  self.draw = function (chartInstance) {
    const hasRadiusBackgroundOption = chartInstance.options.radiusBackground
    if (hasRadiusBackgroundOption) {
      const x = chartInstance.chart.canvas.clientWidth / 2
      const y = chartInstance.chart.canvas.clientHeight / 2
      const ctx = chartInstance.chart.ctx
      ctx.beginPath()
      ctx.arc(x, y, chartInstance.outerRadius - (chartInstance.radiusLength / 2), 0, 2 * Math.PI)
      ctx.lineWidth = chartInstance.radiusLength
      ctx.strokeStyle = chartInstance.options.radiusBackground.color || DEFAULT_BACKGROUND_COLOR
      ctx.stroke()
    }
  }
  return {
    beforeDatasetsDraw: self.draw,
    onResize: self.draw
  }
}

Chart.plugins.register(new RadiusBackground())
