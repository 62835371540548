import infraService from '../../services/infraService'
import equipmentService from '../../services/equipmentService'
import {
  SET_INFRA_MALFUNCTION_DATA,
  SET_INFRA_MALFUNCTION_SUMMARY_DATA,
  SET_EQUIPMENT_MALFUNCTION_DATA,
  SET_EQUIPMENT_MALFUNCTION_SUMMARY_DATA
} from '../malfunctions/actionTypes'

export const setInfraMalfunctionsData = (dataId, closedAtLimitDays, deadlineInDays) => {
  // TODO get parameter from the summary item filters, using now just basic all status query
  const status = 'all'
  const deadlineExceeded = false
  return async dispatch => {
    const data = await infraService.getInfraMaintenanceData('malfunctions', status, deadlineInDays,
      deadlineExceeded, closedAtLimitDays)
    dispatch({
      type: SET_INFRA_MALFUNCTION_DATA,
      payload: {
        dataId: dataId,
        data: data.data,
        dataTimestamp: data.dataTimestamp
      }
    })
  }
}

export const setInfraMalfunctionsSummaryData = (dataId, closedAtLimitDays) => {
  return async dispatch => {
    const data = await infraService.getInfraMalfunctionsSummary(closedAtLimitDays)
    dispatch({
      type: SET_INFRA_MALFUNCTION_SUMMARY_DATA,
      payload: {
        dataId: dataId,
        summaryData: data.data
      }
    })
  }
}

export const setEquipmentMalfunctionData = (dataId) => {
  return async dispatch => {
    const data = await equipmentService.getEquipmentMalfunctionsData()
    dispatch({
      type: SET_EQUIPMENT_MALFUNCTION_DATA,
      payload: {
        dataId: dataId,
        data: data.data,
        dataTimestamp: data.dataTimestamp
      }
    })
  }
}

export const setEquipmentMalfunctionSummaryData = (dataId) => {
  return async dispatch => {
    const data = await equipmentService.getEquipmentMalfunctionsSummaryData()
    dispatch({
      type: SET_EQUIPMENT_MALFUNCTION_SUMMARY_DATA,
      payload: {
        dataId: dataId,
        summaryData: data.data
      }
    })
  }
}
