import React, { useState } from 'react'
import './Pdf.scss'
import { useTranslation } from 'react-i18next'
import AspectRatioWrapper from '../AspectRatioWrapper/AspectRatioWrapper'
import Loader from '../Loader/Loader'
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ArrowLeft from '@material-ui/icons/ArrowLeft'
import ArrowRight from '@material-ui/icons/ArrowRight'

function Pdf ({ file, startPage, aspectRatioWidth, aspectRatioHeight, showNavigation }) {
  const { t } = useTranslation()
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(startPage || 1)

  function onDocumentLoadSuccess ({ numPages }) {
    setNumPages(numPages)
  }

  return (
    <div className='pdf-container'>
      <AspectRatioWrapper width={aspectRatioWidth} height={aspectRatioHeight}>
        <Document
          file={file}
          renderMode='svg'
          error={t('file.load.failed')}
          noData={t('file.not.specified')}
          loading={<Loader />}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page
            pageNumber={pageNumber}
            error={t('page.load.failed')}
            loading={<Loader />}
            noData={t('page.not.specified')}
          />
        </Document>
      </AspectRatioWrapper>
      {showNavigation && numPages && (
        <Box className='pdf-navigation' display='flex' justifyContent='center' mt={3}>
          <Box className='pdf-navigation-container' display='inline-block'>
            <Button
              size='small'
              aria-label={t('previous.page')}
              onClick={() => setPageNumber(pageNumber - 1)}
              disabled={pageNumber === 1}
            >
              <ArrowLeft fontSize='large' />
            </Button>
            <span className='page-info'>
              {pageNumber} / {numPages}
            </span>
            <Button
              size='small'
              aria-label={t('next.page')}
              onClick={() => setPageNumber(pageNumber + 1)}
              disabled={pageNumber === numPages}
            >
              <ArrowRight fontSize='large' />
            </Button>
          </Box>
        </Box>
      )}
    </div>
  )
}

export default Pdf
