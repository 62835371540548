export const SET_TRAM_WS_DATA_LOCATION = 'map/setTramWsDataLocation'
export const SET_TRAM_WS_DATA_PASSENGER = 'map/setTramWsDataPassenger'
export const SET_TRAM_DATA = 'map/setTramData'
export const EMPTY_TRAM_DATA = 'map/emptyTramData'
export const SET_TRAM_WS_DATA_LINE = 'map/setTramWsDataLine'
export const SET_TRAM_DATA_CONNECTION_ERROR = 'map/setTramDataConnectionError'
export const SET_TRAM_DATA_EXPIRY_STATUS = 'map/setTramDataExpriryStatus'
export const SET_MAP_DATA_SUBSET = 'map/setMapDataSubset'
export const ACTIVATE_MAP_DATA_SUBSET = 'map/activateMapDataSubset'
export const DEACTIVATE_MAP_DATA_SUBSET = 'map/deactivateMapDataSubset'

// TODO: TEMPORARY UNTIL TRO-289
export const TOGGLE_MAP_DATA_ACTIVITY = 'map/toggleMapDataAcitivity'
