import React from 'react'
import './ColumnActivityIndicator.scss'

function ColumnActivityIndicator () {
  return (
    <div className='activity-indicator'>
      <div className='arrow'>
        <div className='diamond-narrow' />
      </div>
    </div>
  )
}

export default ColumnActivityIndicator
