import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { NEWS_PDF_FILE_PATH } from '../../util/constants'
import { getPdfFileData } from '../../util/commonUtil'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import ArrowLeft from '@material-ui/icons/ArrowLeft'
import ColumnTopbar from '../../components/ColumnTopbar/ColumnTopbar'
import ColumnContent from '../../components/ColumnContent/ColumnContent'
import Pdf from '../../components/Pdf/Pdf'

function News ({ token }) {
  const { t } = useTranslation()
  const newsPdf = useMemo(() => (getPdfFileData(NEWS_PDF_FILE_PATH, process.env.REACT_APP_API_KEY, token)), [token])
  const history = useHistory()

  const handleLinkClick = (url) => {
    history.push(url)
  }

  return (
    <div className='view-container'>
      <Grid container spacing={4} justify='center'>
        <Grid item xl={9} xs={12}>
          <Box mb={2}>
            <Button
              variant='contained'
              color='primary'
              startIcon={<ArrowLeft />}
              onClick={() => handleLinkClick('/dashboard')}
            >
              {t('to.dashboard')}
            </Button>
          </Box>
          <ColumnContent>
            <ColumnTopbar>
              <h1 className='heading'>{t('news')}</h1>
            </ColumnTopbar>
            <Pdf
              file={newsPdf}
              aspectRatioWidth={16}
              aspectRatioHeight={9}
              showNavigation
            />
          </ColumnContent>
        </Grid>
      </Grid>
    </div>
  )
}

export default News
