import { useContext, useEffect } from 'react'
import MapContext from '../MapContext'
import OLTileLayer from 'ol/layer/Tile'
import { XYZ as XYZSource } from 'ol/source'

const TileLayer = () => {
  const { map } = useContext(MapContext)
  useEffect(() => {
    if (!map) return

    const tileLayer = new OLTileLayer({
      source: new XYZSource({
        url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        projection: 'EPSG:900913'
      })
    })
    map.addLayer(tileLayer)
    return () => {
      if (map) {
        map.removeLayer(tileLayer)
      }
    }
  }, [map])
  return null
}
export default TileLayer
