import React, { useRef, useState, useEffect } from 'react'
import './Map.scss'
import MapContext from './MapContext'
import * as ol from 'ol'
import { fromLonLat } from 'ol/proj'

const MAP_DEFAULT_CENTER = [23.82181, 61.473929]
const MAP_DEFAULT_ZOOM = 13

function calculateDimensions () {
  const SITE_TOPBAR_HEIGHT = 90
  const body = document.body
  const html = document.documentElement
  const pageHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
  return pageHeight - SITE_TOPBAR_HEIGHT
}

const Map = ({ children, zoom, center }) => {
  const mapRef = useRef()
  const [map, setMap] = useState(null)
  const [height, setHeight] = useState(null)
  const updateDimensions = () => {
    setHeight(calculateDimensions)
  }

  useEffect(() => {
    const options = {
      view: new ol.View(),
      layers: [],
      controls: [],
      overlays: []
    }
    const mapObject = new ol.Map(options)
    mapObject.setTarget(mapRef.current)
    setMap(mapObject)
    setHeight(calculateDimensions)
    window.addEventListener('resize', updateDimensions)
    return () => {
      mapObject.setTarget(undefined)
      window.removeEventListener('resize', updateDimensions)
    }
  }, [])

  // height change handler
  useEffect(() => {
    if (!map) return
    map.updateSize()
  }, [map, height])

  // zoom change handler
  useEffect(() => {
    if (!map) return
    map.getView().setZoom(zoom || MAP_DEFAULT_ZOOM)
  }, [map, zoom])

  // center change handler
  useEffect(() => {
    if (!map) return
    map.getView().setCenter(fromLonLat(center || MAP_DEFAULT_CENTER))
  }, [map, center])

  const style = {
    width: '100%',
    height: height
  }

  return (
    <MapContext.Provider value={{ map }}>
      <div ref={mapRef} className='ol-map' style={style}>
        {children}
      </div>
    </MapContext.Provider>
  )
}
export default Map
