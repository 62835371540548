import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { useTranslation } from 'react-i18next'

const DataFilter = (props) => {
  const { t } = useTranslation()
  const { changeFn, dataId, currentFilter, filters } = props
  return (
    <FormControl>
      <Select native value={currentFilter} onChange={(e) => changeFn(dataId, e.target.value)}>
        {filters.map((option, index) => {
          return <option key={index} value={option.value}>{Array.isArray(option.labelKey) ? t(...option.labelKey) : t(option.labelKey)}</option>
        })}
      </Select>
    </FormControl>
  )
}

export default DataFilter
