import React from 'react'
import { useTranslation } from 'react-i18next'
import { getLocaleDateString, getLocaleTimeString } from '../../util/commonUtil'

const ColumnTopbarHeadingButton = (props) => {
  const { heading, clickFn, updatedTimestamp, subtext } = props
  const onClickFunction = () => {
    clickFn()
  }
  const { t } = useTranslation()
  const dateString = updatedTimestamp ? getLocaleDateString(updatedTimestamp) : '?'
  const timeString = updatedTimestamp ? getLocaleTimeString(updatedTimestamp) : '?'

  return (
    <div className='heading'>
      <h2>
        {clickFn
          ? <button type='button' onClick={() => onClickFunction()}>{heading}</button>
          : heading}
      </h2>
      {updatedTimestamp &&
        <div className='text-muted'>{t('data.updated.at', { date: dateString, time: timeString })}</div>}
      {subtext &&
        <div className='text-muted'>{subtext}</div>}
    </div>
  )
}

export default ColumnTopbarHeadingButton
