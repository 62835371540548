import React from 'react'
import './Topbar.scss'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import ProfileMenu from '../ProfileMenu/ProfileMenu'
import Box from '@material-ui/core/Box'
import { NavLink } from 'react-router-dom'
import logo from '../../images/logo.svg'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/icons/Menu'
import { useTranslation } from 'react-i18next'

function Topbar ({ user, isMobileMenuOpen, toggleMobileMenuFn }) {
  const { t } = useTranslation()

  const toggleMobileMenu = (open) => {
    toggleMobileMenuFn(!open)
  }

  return (
    <>
      <AppBar id='topbar'>
        <Box className='logo-container'>
          <NavLink to='/dashboard'>
            <img src={logo} alt='logo' />
          </NavLink>
        </Box>
        <Toolbar id='toolbar'>
          <Button
            id='mobile-menu-toggle'
            aria-label={isMobileMenuOpen ? t('close.menu') : t('open.menu')}
            onClick={() => toggleMobileMenu(isMobileMenuOpen)}
          >
            <Menu fontSize='large' />
          </Button>
          <ProfileMenu name={user} picture='' />
        </Toolbar>
      </AppBar>
    </>
  )
}

export default Topbar
