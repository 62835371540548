import React from 'react'
import logo from '../../images/215_tunnus.png'
import './FrontPage.scss'
import { useTranslation } from 'react-i18next'

function FrontPage () {
  const { t } = useTranslation()
  return (
    <div className='front-page main-container'>
      <header className='App-header'>
        <img src={logo} alt='logo' />
        <h1>{t('frontpage.title')}</h1>
        <p>
          <a href={`${process.env.REACT_APP_LOGIN_URL}?post_login_redirect_url=/dashboard`}>
            {t('login')}
          </a>
        </p>
      </header>
    </div>
  )
}

export default FrontPage
