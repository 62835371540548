import React from 'react'
import './AccordionMenu.scss'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

function AccordionMenu ({ children, id, heading, suffix }) {
  return (
    <Accordion className='accordion-menu' square>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={id + '-accordion-content'}
        id={id + '-accordion-heading'}
        className='accordion-heading'
      >
        <Typography>
          {heading}
          {suffix ? <span className='suffix'>{suffix}</span> : ''}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export default AccordionMenu
