import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import fi from './locales/fi.json'

i18n
  .use(initReactI18next)
  .init({
    debug: false,
    lng: 'fi',
    fallbackLng: 'fi',
    keySeparator: false,
    resources: {
      fi: {
        translations: fi
      }
    },
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations'
  })

export default i18n
