import {
  SET_EQUIPMENT_DATA,
  SET_EQUIPMENT_SUMMARY_DATA,
  TOGGLE_EQUIPMENT_TABLE_ORDER,
  SET_EQUIPMENT_TABLE_COLUMN_TO_ORDER_BY,
  TOGGLE_EQUIPMENT_TABLE_DENSITY,
  SET_EQUIPMENT_TABLE_PAGE,
  SET_EQUIPMENT_TABLE_ROWS_PER_PAGE
} from './actionTypes'
import {
  setDataWithDataTimestamp,
  setSummaryData,
  setTableColumnToOrderBy,
  setTablePage,
  setTableRowsPerPage,
  toggleTableDensity,
  toggleTableOrder
} from '../general/mutations'

export const EQUIPMENT_ID = 'equipment'

const initialState = {
  [EQUIPMENT_ID]: {
    data: [],
    summaryData: [],
    dataTimestamp: null
  },
  table: {
    order: '',
    orderBy: '',
    dense: false,
    page: 0,
    rowsPerPage: 5
  }
}

const equipment = (state = initialState, action) => {
  switch (action.type) {
    case SET_EQUIPMENT_DATA: {
      const { dataId, data, dataTimestamp } = action.payload
      return setDataWithDataTimestamp(state, dataId, data, dataTimestamp)
    }
    case SET_EQUIPMENT_SUMMARY_DATA: {
      const { dataId, summaryData } = action.payload
      return setSummaryData(state, dataId, summaryData)
    }
    case TOGGLE_EQUIPMENT_TABLE_ORDER: {
      const { order } = action.payload
      return toggleTableOrder(state, order)
    }
    case SET_EQUIPMENT_TABLE_COLUMN_TO_ORDER_BY: {
      const { orderBy } = action.payload
      return setTableColumnToOrderBy(state, orderBy)
    }
    case TOGGLE_EQUIPMENT_TABLE_DENSITY: {
      const { dense } = action.payload
      return toggleTableDensity(state, dense)
    }
    case SET_EQUIPMENT_TABLE_PAGE: {
      const { page } = action.payload
      return setTablePage(state, page)
    }
    case SET_EQUIPMENT_TABLE_ROWS_PER_PAGE: {
      const { rowsPerPage } = action.payload
      return setTableRowsPerPage(state, rowsPerPage)
    }
    default:
      return state
  }
}

export default equipment
