import {
  SET_EQUIPMENT_DATA,
  SET_EQUIPMENT_SUMMARY_DATA
} from './actionTypes'
import equipmentService from '../../services/equipmentService'

export const setEquipmentData = (dataId) => {
  return async dispatch => {
    const data = await equipmentService.getEquipmentData()
    dispatch({
      type: SET_EQUIPMENT_DATA,
      payload: {
        dataId: dataId,
        data: data.data,
        dataTimestamp: data.dataTimestamp
      }
    })
  }
}

export const setEquipmentSummaryData = (dataId) => {
  return async dispatch => {
    const data = await equipmentService.getEquipmentSummaryData()
    dispatch({
      type: SET_EQUIPMENT_SUMMARY_DATA,
      payload: {
        dataId: dataId,
        summaryData: data.data
      }
    })
  }
}
