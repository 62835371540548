import React from 'react'
import { useTranslation } from 'react-i18next'
import './FilterSelections.scss'
import Chip from '@material-ui/core/Chip'
import Link from '@material-ui/core/Link'
import { hasActiveFilters } from '../../../util/filterUtil'

const FilterSelections = ({ filters, clearAllFn, removeFn, dataId }) => {
  const { t } = useTranslation()
  return (
    <div className='filter-selections'>
      {hasActiveFilters(filters) &&
        <Link
          component='button'
          className='clear-filters-btn'
          variant='body2'
          onClick={() => clearAllFn(dataId, filters)}
        >
          {t('clear.selections')}
        </Link>}
      {filters && Object.keys(filters).map((filterGroupKey) => (
        filters[filterGroupKey].map((filter, index) => (
          <Chip
            className='filter-selection'
            key={index}
            label={t(filter) || t('item.undefined')}
            onDelete={() => removeFn(dataId, filterGroupKey, filter)}
          />
        ))
      ))}
    </div>
  )
}

export default FilterSelections
