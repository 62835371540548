export const ACTIVATE_MAINTENANCE_DATA = 'maintenances/activateMaintenanceData'
export const SET_MAINTENANCE_DATA = 'maintenances/setMaintenanceData'
export const SET_MAINTENANCE_SUMMARY_DATA = 'maintenances/setMaintenanceSummaryData'
export const SET_EQUIPMENT_MAINTENANCE_SUMMARY_DATA = 'maintenances/setEquipmentMaintenanceSummaryData'
export const SET_EQUIPMENT_MAINTENANCE_DATA = 'maintenances/setEquipmentMaintenanceData'
export const SET_MAINTENANCE_DATA_FILTERS = 'malfunctions/setMaintenanceDataFilters'
export const TOGGLE_MAINTENANCE_DATA_FILTER = 'malfunctions/toggleMaintenanceDataFilter'
export const TOGGLE_MAINTENANCE_TABLE_ORDER = 'maintenances/toggleMaintenanceTableOrder'
export const SET_MAINTENANCE_TABLE_COLUMN_TO_ORDER_BY = 'maintenances/setMaintenanceTableColumnToOrderBy'
export const TOGGLE_MAINTENANCE_TABLE_DENSITY = 'maintenances/toggleMaintenanceTableDensity'
export const SET_MAINTENANCE_TABLE_PAGE = 'maintenances/setMaintenanceTablePage'
export const SET_MAINTENANCE_TABLE_ROWS_PER_PAGE = 'maintenances/setMaintenanceTableRowsPerPage'
