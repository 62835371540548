import { SET_TRAM_DATA, SET_TRAM_WS_DATA_LOCATION } from '../store/map/actionTypes'
import tramService from '../services/tramService'

const tramDataTimeoutMiddleware = store => next => action => {
  switch (action.type) {
    case SET_TRAM_DATA: {
      next(action)
      const { data } = action.payload
      tramService.setTramDataTimers(data.locationData, store.dispatch)
      break
    }
    case SET_TRAM_WS_DATA_LOCATION: {
      next(action)
      const { data } = action.payload
      tramService.setTramDataTimers(data, store.dispatch)
      break
    }
    default:
      return next(action)
  }
}

export default tramDataTimeoutMiddleware
