import React from 'react'
import { useTranslation } from 'react-i18next'
import './Navigation.scss'
import Dashboard from '@material-ui/icons/Dashboard'
import Map from '@material-ui/icons/Map'
import Highlight from '@material-ui/icons/Highlight'
import Warning from '@material-ui/icons/Warning'
import Build from '@material-ui/icons/Build'
import Tram from '@material-ui/icons/Tram'
import PieChart from '@material-ui/icons/PieChart'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { NavLink } from 'react-router-dom'
import Description from '@material-ui/icons/Description'

function Navigation () {
  const { t } = useTranslation()

  return (
    <>
      <Box className='main-menu'>
        <List component='nav'>
          <ListItem button className='menu-button'>
            <NavLink to='/dashboard'>
              <Dashboard />
              <span className='button-text'>{t('dashboard')}</span>
            </NavLink>
          </ListItem>
          <ListItem button className='menu-button'>
            <NavLink to='/live'>
              <Map />
              <span className='button-text'>{t('live')}</span>
            </NavLink>
          </ListItem>
          <ListItem button className='menu-button'>
            <NavLink to='/raportit'>
              <PieChart />
              <span className='button-text'>{t('reports')}</span>
            </NavLink>
          </ListItem>
          <ListItem button className='menu-button'>
            <NavLink to='/uutiset'>
              <Description />
              <span className='button-text'>{t('news')}</span>
            </NavLink>
          </ListItem>
          <ListItem button className='menu-button'>
            <NavLink to='/kalusto'>
              <Tram />
              <span className='button-text'>{t('equipment')}</span>
            </NavLink>
          </ListItem>
          <ListItem button className='menu-button'>
            <NavLink to='/huollot'>
              <Build />
              <span className='button-text'>{t('maintenances')}</span>
            </NavLink>
          </ListItem>
          <ListItem button className='menu-button'>
            <NavLink to='/hairiot-ja-viat'>
              <Warning />
              <span className='button-text'>{t('malfunctions')}</span>
            </NavLink>
          </ListItem>
          <ListItem button className='menu-button'>
            <NavLink to='/poikkeamat'>
              <Highlight />
              <span className='button-text'>{t('deviations')}</span>
            </NavLink>
          </ListItem>
        </List>
      </Box>
    </>
  )
}

export default Navigation
