import {
  ACTIVATE_DEVIATION_DATA,
  SET_DEVIATION_DATA,
  SET_DEVIATION_DATA_TIME_FILTER,
  TOGGLE_DEVIATION_TABLE_ORDER,
  SET_DEVIATION_TABLE_COLUMN_TO_ORDER_BY,
  TOGGLE_DEVIATION_TABLE_DENSITY,
  SET_DEVIATION_TABLE_PAGE,
  SET_DEVIATION_TABLE_ROWS_PER_PAGE,
  SET_DEVIATION_DATA_FILTERS, TOGGLE_DEVIATION_DATA_FILTER
} from './actionTypes'
import {
  activateData, toggleTableOrder, setTableColumnToOrderBy,
  toggleTableDensity, setTablePage, setTableRowsPerPage,
  setDataWithDataTimestamp, setDataFilters, toggleDataFilter
} from '../general/mutations'

export const DEVIATION_ID = 'deviation'
export const OBSERVATION_ID = 'observation'

const initialState = {
  active: DEVIATION_ID,
  [DEVIATION_ID]: {
    data: [],
    dataFilters: {
      eventCategory: [],
      domain: [],
      status: [],
      summaryCategories: []
    },
    dataTimestamp: null,
    limitDays: 30
  },
  [OBSERVATION_ID]: {
    data: [],
    dataFilters: {
      eventCategory: [],
      domain: [],
      status: [],
      summaryCategories: []
    },
    dataTimestamp: null,
    limitDays: 30
  },
  table: {
    order: '',
    orderBy: '',
    dense: false,
    page: 0,
    rowsPerPage: 5
  }
}

const deviations = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVATE_DEVIATION_DATA: {
      const { dataId } = action.payload
      return activateData(state, dataId)
    }
    case SET_DEVIATION_DATA: {
      const { dataId, data, dataTimestamp } = action.payload
      return setDataWithDataTimestamp(state, dataId, data, dataTimestamp)
    }
    case SET_DEVIATION_DATA_TIME_FILTER: {
      const { dataId, limitDays } = action.payload
      return {
        ...state,
        [dataId]: {
          ...state[dataId],
          limitDays: limitDays
        }
      }
    }
    case SET_DEVIATION_DATA_FILTERS: {
      const { dataId, filterGroupId, filters } = action.payload
      return setDataFilters(state, dataId, filterGroupId, filters)
    }
    case TOGGLE_DEVIATION_DATA_FILTER: {
      const { dataId, filterGroupId, filter } = action.payload
      return toggleDataFilter(state, dataId, filterGroupId, filter)
    }
    case TOGGLE_DEVIATION_TABLE_ORDER: {
      const { order } = action.payload
      return toggleTableOrder(state, order)
    }
    case SET_DEVIATION_TABLE_COLUMN_TO_ORDER_BY: {
      const { orderBy } = action.payload
      return setTableColumnToOrderBy(state, orderBy)
    }
    case TOGGLE_DEVIATION_TABLE_DENSITY: {
      const { dense } = action.payload
      return toggleTableDensity(state, dense)
    }
    case SET_DEVIATION_TABLE_PAGE: {
      const { page } = action.payload
      return setTablePage(state, page)
    }
    case SET_DEVIATION_TABLE_ROWS_PER_PAGE: {
      const { rowsPerPage } = action.payload
      return setTableRowsPerPage(state, rowsPerPage)
    }
    default:
      return state
  }
}

export default deviations
