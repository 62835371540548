import React from 'react'
import { useTranslation } from 'react-i18next'

function ErrorPage () {
  const { t } = useTranslation()

  return <h1>{t('error.description')}</h1>
}

export default ErrorPage
