import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import dashboard from './dashboard/reducers'
import deviations from './deviations/reducers'
import maintenances from './maintenances/reducers'
import malfunctions from './malfunctions/reducers'
import map from './map/reducers'
import equipment from './equipment/reducers'
import reports from './reports/reducers'
import { loadState, saveState } from '../util/localStorage'
import throttle from 'lodash/throttle'
import webSocketMiddleware from '../middleware/webSocketMiddleware'
import tramDataTimeoutMiddleware from '../middleware/tramDataTimeoutMiddleware'

// Remember to update version if state structure is changed!
const stateVersion = 29
const version = (state = stateVersion, action) => state

export const DASHBOARD_SLICE_ID = 'dashboard'
export const DEVIATIONS_SLICE_ID = 'deviations'
export const MAINTENANCES_SLICE_ID = 'maintenances'
export const MALFUNCTIONS_SLICE_ID = 'malfunctions'
export const MAP_SLICE_ID = 'map'
export const EQUIPMENT_SLICE_ID = 'equipment'
export const REPORTS_SLICE_ID = 'reports'

const middleware = [
  thunk,
  webSocketMiddleware,
  tramDataTimeoutMiddleware
]

const reducer = combineReducers({
  [DASHBOARD_SLICE_ID]: dashboard,
  [DEVIATIONS_SLICE_ID]: deviations,
  [MAINTENANCES_SLICE_ID]: maintenances,
  [MALFUNCTIONS_SLICE_ID]: malfunctions,
  [MAP_SLICE_ID]: map,
  [EQUIPMENT_SLICE_ID]: equipment,
  [REPORTS_SLICE_ID]: reports,
  version: version
})

const store = createStore(
  reducer,
  loadState(stateVersion),
  applyMiddleware(...middleware)
)

// use throttle to prevent calling the expensive localstorage saving too many times at a time
store.subscribe(throttle(() => {
  saveState(store.getState())
}, 1000))

export default store
