const dataExpirationLimitInMilliseconds = process.env.REACT_APP_TRAM_DATA_EXPIRY_TIMEOUT

export const hasTramLocationTimestampExpired = (dateStringToCompareTo, dateStringToCheckForExpiration) => {
  const dateToCompareTo = new Date(dateStringToCompareTo)
  const dateToCheckForExpiration = new Date(dateStringToCheckForExpiration)
  const differenceInMilliseconds = dateToCompareTo - dateToCheckForExpiration
  return differenceInMilliseconds >= dataExpirationLimitInMilliseconds
}

export const getTramDataExpirationTimerTimeOut = (dateStringToCompareTo, dateStringToCheckForExpiration) => {
  const dateToCompareTo = new Date(dateStringToCompareTo)
  const dateToCheckForExpiration = new Date(dateStringToCheckForExpiration)
  const differenceInMilliseconds = dateToCompareTo - dateToCheckForExpiration
  const timeoutInMilliseconds = dataExpirationLimitInMilliseconds - differenceInMilliseconds
  return timeoutInMilliseconds > 0 ? timeoutInMilliseconds : 0
}
