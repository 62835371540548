import React from 'react'
import { useTranslation } from 'react-i18next'
import './SimpleTable.scss'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import EnhancedTableCellContent from '../EnhancedTable/EnhancedTableCellContent'

function SimpleTable (props) {
  const { t } = useTranslation()
  const { headingKey, headCells, cells, data, scrollable } = props

  return (
    <div className='simple-table'>
      {headingKey &&
        <h4>{t(headingKey)}</h4>}
      <TableContainer className={`table-container ${scrollable ? 'scrollable' : ''}`}>
        <Table stickyHeader={scrollable}>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell key={headCell.id}>
                  {t(headCell.labelKey)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                {cells.map((cell) => (
                  <TableCell key={cell.id}>
                    <EnhancedTableCellContent row={row} cell={cell} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default SimpleTable
