import React, { useState, useEffect } from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import EnhancedTableCellContent from './EnhancedTableCellContent'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

function EnhanceTableRow (props) {
  const { dataId, row, cells, collapsibleRows } = props
  const [open, setOpen] = useState(false)
  const [currentRow, setCurrentRow] = useState(row)

  const tableCells = cells.filter(item => !item.collapseCell)
  // These go under the collapse
  const tableCollapseCells = cells.filter(item => item.collapseCell)

  useEffect(() => {
    if (currentRow !== row) {
      if (open) {
        setOpen(false)
      }
      setCurrentRow(row)
    }
  }, [currentRow, row, open])

  return (
    <>
      <TableRow tabIndex={-1}>
        {tableCells.map((cell) => (
          <TableCell key={cell.id}>
            <EnhancedTableCellContent dataId={dataId} row={row} cell={cell} />
          </TableCell>
        ))}
        {collapsibleRows && (
          <TableCell>
            <IconButton className='collapse-toggle' size='small' onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      {collapsibleRows && (
        <TableRow className='collapse-row' tabIndex={-1}>
          <TableCell className={`collapse-row-cell ${open ? 'open' : ''}`} colSpan={cells.length + 1}>
            <Collapse className='collapse' in={open} timeout={0} unmountOnExit>
              <div className='collapse-row-cell-wrapper'>
                {tableCollapseCells.map((cell) => (
                  <div className='collapse-row-cell-item' key={cell.id}>
                    <EnhancedTableCellContent dataId={dataId} row={row} cell={cell} />
                  </div>
                ))}
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export default EnhanceTableRow
