import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  STATUS_FIELD_KEY,
  STATUS_OPEN,
  STATUS_ASSIGNED,
  STATUS_PROCESSED
} from '../../util/constants'
import deviationService from '../../services/deviationService'
import {
  isDataActive,
  timeFilterOptions
} from '../../util/commonUtil'
import {
  filterData,
  isTheOnlyActiveFilter,
  FILTER_GROUP_SUMMARY_CATEGORIES_ID
} from '../../util/filterUtil'
import {
  DEVIATION_SUMMARY_CATEGORY_ASSIGNED,
  DEVIATION_SUMMARY_CATEGORY_OPEN,
  // DEVIATION_SUMMARY_CATEGORY_OPEN_ASSIGNMENTS,
  DEVIATION_SUMMARY_CATEGORY_PROCESSED
} from '../../util/deviationUtil'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import { DEVIATIONS_SLICE_ID } from '../../store'
import {
  DEVIATION_ID,
  OBSERVATION_ID
} from '../../store/deviations/reducers'
import {
  ACTIVATE_DEVIATION_DATA,
  TOGGLE_DEVIATION_TABLE_ORDER,
  SET_DEVIATION_TABLE_COLUMN_TO_ORDER_BY,
  TOGGLE_DEVIATION_TABLE_DENSITY,
  SET_DEVIATION_TABLE_PAGE,
  SET_DEVIATION_TABLE_ROWS_PER_PAGE,
  SET_DEVIATION_DATA_FILTERS,
  TOGGLE_DEVIATION_DATA_FILTER
} from '../../store/deviations/actionTypes'
import {
  activateData,
  setDataFilters,
  toggleDataFilter
} from '../../store/general/actions'
import {
  setDeviationData,
  setDeviationDataTimeFilter
} from '../../store/deviations/actions'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import AssignmentIndOutlined from '@material-ui/icons/AssignmentIndOutlined'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import ArrowRight from '@material-ui/icons/ArrowRight'
import ArrowLeft from '@material-ui/icons/ArrowLeft'
// import ListAlt from '@material-ui/icons/ListAlt'
import ColumnContent from '../../components/ColumnContent/ColumnContent'
import ColumnTopbar from '../../components/ColumnTopbar/ColumnTopbar'
import ColumnTopbarTools from '../../components/ColumnTopbar/ColumnTopbarTools'
import DataFilter from '../../components/DataFilter/DataFilter'
import ColumnTopbarHeadingButton from '../../components/ColumnTopbar/ColumnTopbarHeadingButton'
import ColumnActivityIndicator from '../../components/ColumnActivityIndicator/ColumnActivityIndicator'
import RecordSummary from '../../components/RecordSummary/RecordSummary'
import RecordSummaryItem from '../../components/RecordSummary/RecordSummaryItem'
import DoughnutChart from '../../components/DoughnutChart/DoughnutChart'
import EnhancedTable from '../../components/EnhancedTable/EnhancedTable'
import Drawer from '../../components/Drawer/Drawer'
import DrawerMenu from '../../components/Drawer/DrawerMenu'
import DrawerMain from '../../components/Drawer/DrawerMain'
import DrawerToggle from '../../components/Drawer/DrawerToggle'
import FilterMenu from '../../components/EnhancedTable/Filtering/FilterMenu'
import FilterSelections from '../../components/EnhancedTable/Filtering/FilterSelections'

const DeviationsRecordSummary = ({ data, dataId, active }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const filters = useSelector(state => state[DEVIATIONS_SLICE_ID][dataId].dataFilters)

  const toggleSummaryCategoryFilter = (filter) => {
    if (!isTheOnlyActiveFilter(filters, filter)) {
      for (const filterGroupId in filters) {
        dispatch(setDataFilters(SET_DEVIATION_DATA_FILTERS, dataId, filterGroupId, []))
      }
      dispatch(toggleDataFilter(TOGGLE_DEVIATION_DATA_FILTER, dataId, FILTER_GROUP_SUMMARY_CATEGORIES_ID, filter))
    }
    if (!active) {
      dispatch(activateData(ACTIVATE_DEVIATION_DATA, dataId))
    }
  }

  return (
    <RecordSummary>
      <RecordSummaryItem
        label={t('open')}
        color='orange'
        icon={<ErrorOutline />}
        amount={deviationService.filterDataAndGetAmount(data, STATUS_FIELD_KEY, STATUS_OPEN)}
        active={active && isTheOnlyActiveFilter(filters, DEVIATION_SUMMARY_CATEGORY_OPEN)}
        clickFn={() => toggleSummaryCategoryFilter(DEVIATION_SUMMARY_CATEGORY_OPEN)}
      />
      <RecordSummaryItem
        label={t('assigned')}
        color='blue'
        icon={<AssignmentIndOutlined />}
        amount={deviationService.filterDataAndGetAmount(data, STATUS_FIELD_KEY, STATUS_ASSIGNED)}
        active={active && isTheOnlyActiveFilter(filters, DEVIATION_SUMMARY_CATEGORY_ASSIGNED)}
        clickFn={() => toggleSummaryCategoryFilter(DEVIATION_SUMMARY_CATEGORY_ASSIGNED)}
      />
      <RecordSummaryItem
        label={t('processed')}
        color='green'
        icon={<CheckCircleOutline />}
        amount={deviationService.filterDataAndGetAmount(data, STATUS_FIELD_KEY, STATUS_PROCESSED)}
        active={active && isTheOnlyActiveFilter(filters, DEVIATION_SUMMARY_CATEGORY_PROCESSED)}
        clickFn={() => toggleSummaryCategoryFilter(DEVIATION_SUMMARY_CATEGORY_PROCESSED)}
      />
      {/* <RecordSummaryItem */}
      {/*  label={t('open.assignments')} */}
      {/*  color='gray' */}
      {/*  icon={<ListAlt />} */}
      {/*  amount={deviationService.getNumberOfAssignments(data)} */}
      {/*  active={active && isTheOnlyActiveFilter(filters, DEVIATION_SUMMARY_CATEGORY_OPEN_ASSIGNMENTS)} */}
      {/*  clickFn={() => toggleSummaryCategoryFilter(DEVIATION_SUMMARY_CATEGORY_OPEN_ASSIGNMENTS)} */}
      {/* /> */}
    </RecordSummary>
  )
}

const DeviationsMobileTabs = ({ activeDataId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  return (
    <Box className='mobile-lg-visible' display='flex' justifyContent='space-between' mb={2}>
      {isDataActive(activeDataId, OBSERVATION_ID) && (
        <Box>
          <Button
            variant='contained'
            color='primary'
            startIcon={<ArrowLeft />}
            onClick={() => dispatch(
              activateData(ACTIVATE_DEVIATION_DATA, DEVIATION_ID))}
          >
            {t('tram.traffic.deviations')}
          </Button>
        </Box>
      )}
      {isDataActive(activeDataId, DEVIATION_ID) && (
        <Box ml='auto'>
          <Button
            variant='contained'
            color='primary'
            endIcon={<ArrowRight />}
            onClick={() => dispatch(activateData(ACTIVATE_DEVIATION_DATA, OBSERVATION_ID))}
          >
            {t('safety.observations')}
          </Button>
        </Box>
      )}
    </Box>
  )
}

function Deviations () {
  const { t } = useTranslation()
  const [filterMenuOpen, setFilterMenuOpen] = useState(false)
  const dispatch = useDispatch()
  const deviations = useSelector(state => state[DEVIATIONS_SLICE_ID][DEVIATION_ID].data)
  const deviationsTotal = deviations.length
  const deviationsTimeFilter = useSelector(state => state[DEVIATIONS_SLICE_ID][DEVIATION_ID].limitDays)
  const deviationQueryTimestamp = useSelector(state => state[DEVIATIONS_SLICE_ID][DEVIATION_ID].dataTimestamp)
  const observations = useSelector(state => state[DEVIATIONS_SLICE_ID][OBSERVATION_ID].data)
  const observationsTotal = observations.length
  const observationsTimeFilter = useSelector(state => state[DEVIATIONS_SLICE_ID][OBSERVATION_ID].limitDays)
  const observationQueryTimestamp = useSelector(state => state[DEVIATIONS_SLICE_ID][OBSERVATION_ID].dataTimestamp)
  const activeDataId = useSelector(state => state[DEVIATIONS_SLICE_ID].active)
  const activeData = useSelector(state => state[DEVIATIONS_SLICE_ID][activeDataId].data)
  const activeDataFilters = useSelector(state => state[DEVIATIONS_SLICE_ID][activeDataId].dataFilters)
  const activeDataTimeFilter = useSelector(state => state[DEVIATIONS_SLICE_ID][activeDataId].limitDays)

  const filterDataByTime = (dataId, value) => {
    dispatch(setDeviationDataTimeFilter(dataId, value))
    if (!isDataActive(activeDataId, dataId)) {
      dispatch(activateData(ACTIVATE_DEVIATION_DATA, dataId))
    }
  }

  const toggleFilter = (dataId, filterGroupId, filter) => {
    dispatch(toggleDataFilter(TOGGLE_DEVIATION_DATA_FILTER, dataId, filterGroupId, filter))
  }

  const clearAllFilters = (dataId, filters) => {
    for (const filterGroupId in filters) {
      dispatch(setDataFilters(SET_DEVIATION_DATA_FILTERS, dataId, filterGroupId, []))
    }
  }

  useEffect(() => {
    dispatch(setDeviationData(DEVIATION_ID, deviationsTimeFilter))
    dispatch(setDeviationData(OBSERVATION_ID, observationsTimeFilter))
  }, [dispatch, deviationsTimeFilter, observationsTimeFilter])

  return (
    <div className='view-container'>
      <DeviationsMobileTabs activeDataId={activeDataId} />
      <Grid container spacing={4}>
        <Grid item lg={6} xs={12} className={isDataActive(activeDataId, OBSERVATION_ID) ? 'mobile-lg-hidden' : ''}>
          <ColumnContent>
            <ColumnTopbar>
              <ColumnTopbarHeadingButton
                heading={t('tram.traffic.deviations')}
                clickFn={() => dispatch(activateData(ACTIVATE_DEVIATION_DATA, DEVIATION_ID))}
                updatedTimestamp={deviationQueryTimestamp}
              />
              <ColumnTopbarTools>
                <DataFilter
                  changeFn={filterDataByTime}
                  dataId={DEVIATION_ID}
                  currentFilter={deviationsTimeFilter}
                  filters={timeFilterOptions}
                />
              </ColumnTopbarTools>
            </ColumnTopbar>
            <Grid container item spacing={3} alignItems='center'>
              <Grid item md={3} xs={12}>
                <DeviationsRecordSummary
                  data={deviations}
                  dataId={DEVIATION_ID}
                  active={isDataActive(activeDataId, DEVIATION_ID)}
                />
              </Grid>
              <Grid item md={9} xs={12}>
                <DoughnutChart
                  data={deviationService.getTramTrafficDeviationsSummary(deviations)}
                  label={t('total.deviations') + ' ' + deviationsTotal}
                  legendPositionClass='legend-right'
                />
              </Grid>
            </Grid>
          </ColumnContent>
          {isDataActive(activeDataId, DEVIATION_ID) &&
            <ColumnActivityIndicator />}
        </Grid>
        <Grid item lg={6} xs={12} className={isDataActive(activeDataId, DEVIATION_ID) ? 'mobile-lg-hidden' : ''}>
          <ColumnContent>
            <ColumnTopbar>
              <ColumnTopbarHeadingButton
                heading={t('safety.observations')}
                clickFn={() => dispatch(activateData(ACTIVATE_DEVIATION_DATA, OBSERVATION_ID))}
                updatedTimestamp={observationQueryTimestamp}
              />
              <ColumnTopbarTools>
                <DataFilter
                  changeFn={filterDataByTime}
                  dataId={OBSERVATION_ID}
                  currentFilter={observationsTimeFilter}
                  filters={timeFilterOptions}
                />
              </ColumnTopbarTools>
            </ColumnTopbar>
            <Grid container item spacing={3} alignItems='center'>
              <Grid item md={3} xs={12}>
                <DeviationsRecordSummary
                  data={observations}
                  dataId={OBSERVATION_ID}
                  active={isDataActive(activeDataId, OBSERVATION_ID)}
                />
              </Grid>
              <Grid item md={9} xs={12}>
                <DoughnutChart
                  data={deviationService.getSafetyObservationsSummary(observations)}
                  label={t('total.observations') + ' ' + observationsTotal}
                  legendPositionClass='legend-right'
                />
              </Grid>
            </Grid>
          </ColumnContent>
          {isDataActive(activeDataId, OBSERVATION_ID) &&
            <ColumnActivityIndicator />}
        </Grid>
        <Grid item xs={12}>
          <ColumnContent>
            <ColumnTopbar>
              <h2 className='heading'>
                {t('deviations.table.heading.' + activeDataId)}
              </h2>
              <ColumnTopbarTools>
                <DataFilter
                  changeFn={filterDataByTime}
                  dataId={activeDataId}
                  currentFilter={activeDataTimeFilter}
                  filters={timeFilterOptions}
                />
                <DrawerToggle
                  text={t('filter')}
                  open={filterMenuOpen}
                  setOpen={setFilterMenuOpen}
                />
                {/* <Button variant='outlined' color='primary'> */}
                {/*  <span className='btn-text'>{t('show.on.map')}</span> */}
                {/* </Button> */}
              </ColumnTopbarTools>
            </ColumnTopbar>
            <Drawer className='table-filter-menu'>
              <DrawerMenu
                anchor='top'
                open={filterMenuOpen}
              >
                <FilterMenu
                  filters={deviationService.getDeviationTableFilters(activeData)}
                  activeFilters={activeDataFilters}
                  toggleFn={toggleFilter}
                  dataId={activeDataId}
                />
              </DrawerMenu>
              <DrawerMain open={filterMenuOpen}>
                <FilterSelections
                  filters={activeDataFilters}
                  clearAllFn={clearAllFilters}
                  removeFn={toggleFilter}
                  dataId={activeDataId}
                />
                <EnhancedTable
                  stateId={DEVIATIONS_SLICE_ID}
                  tableActionTypes={{
                    toggleTableOrder: TOGGLE_DEVIATION_TABLE_ORDER,
                    setTableColumnToOrderBy: SET_DEVIATION_TABLE_COLUMN_TO_ORDER_BY,
                    toggleTableDensity: TOGGLE_DEVIATION_TABLE_DENSITY,
                    setTablePage: SET_DEVIATION_TABLE_PAGE,
                    setTableRowsPerPage: SET_DEVIATION_TABLE_ROWS_PER_PAGE
                  }}
                  headCells={deviationService.getObservationTableHeadCells()}
                  cells={deviationService.getObservationTableCells()}
                  data={filterData(activeData, activeDataFilters)}
                />
              </DrawerMain>
            </Drawer>
          </ColumnContent>
        </Grid>
      </Grid>
    </div>
  )
}

export default Deviations
