export const ACTIVATE_DEVIATION_DATA = 'deviations/activateDeviationData'
export const SET_DEVIATION_DATA = 'deviations/setDeviationData'
export const SET_DEVIATION_DATA_TIME_FILTER = 'deviations/setDeviationDataTimeFilter'
export const SET_DEVIATION_DATA_FILTERS = 'deviations/setDeviationDataFilters'
export const TOGGLE_DEVIATION_DATA_FILTER = 'deviations/toggleDeviationDataFilter'
export const TOGGLE_DEVIATION_TABLE_ORDER = 'deviations/toggleDeviationTableOrder'
export const SET_DEVIATION_TABLE_COLUMN_TO_ORDER_BY = 'deviations/setDeviationTableColumnToOrderBy'
export const TOGGLE_DEVIATION_TABLE_DENSITY = 'deviations/toggleDeviationTableDensity'
export const SET_DEVIATION_TABLE_PAGE = 'deviations/setDeviationTablePage'
export const SET_DEVIATION_TABLE_ROWS_PER_PAGE = 'deviations/setDeviationTableRowsPerPage'
