import React from 'react'
import { useTranslation } from 'react-i18next'
import './FilterMenu.scss'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'

const FilterMenu = ({ filters, activeFilters, toggleFn, dataId }) => {
  const { t } = useTranslation()
  return (
    <div className='filter-menu'>
      {filters.map((filterGroup) => (
        filterGroup.filters.length > 0 && (
          <FormControl
            key={filterGroup.id}
            component='fieldset'
            fullWidth
          >
            <FormLabel component='legend'>{t(filterGroup.legendKey)}</FormLabel>
            <FormGroup>
              {filterGroup.filters.map((filter, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={activeFilters && activeFilters[filterGroup.id].includes(filter)}
                      onChange={(e) => toggleFn(dataId, filterGroup.id, filter)}
                      name={filterGroup.id}
                      color='primary'
                      value={filter}
                    />
                  }
                  label={t(filter) || t('item.undefined')}
                />
              ))}
            </FormGroup>
          </FormControl>
        )
      ))}
    </div>
  )
}

export default FilterMenu
