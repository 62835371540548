import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  toggleTableOrder,
  setTableColumnToOrderBy,
  toggleTableDensity,
  setTablePage,
  setTableRowsPerPage
} from '../../store/general/actions'
import './EnhancedTable.scss'
import EnhancedTableHead from './EnhancedTableHead'
import EnhanceTableRow from './EnhancedTableRow'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

const ORDER_DESC = 'desc'
const ORDER_ASC = 'asc'
const DEFAULT_RADIX = 10

function descendingComparator (a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator (order, orderBy) {
  return order === ORDER_DESC
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort (array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function EnhancedTable (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { stateId, tableActionTypes, headCells, cells, dataId, data, collapsibleRows } = props
  const rows = data
  const dense = useSelector(state => state[stateId].table.dense)
  const rowsPerPage = useSelector(state => state[stateId].table.rowsPerPage)
  const page = useSelector(state => state[stateId].table.page)
  const order = useSelector(state => state[stateId].table.order)
  const orderBy = useSelector(state => state[stateId].table.orderBy)

  useEffect(() => {
    dispatch(setTablePage(tableActionTypes.setTablePage, 0))
  }, [data, dispatch, tableActionTypes.setTablePage])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === ORDER_ASC
    dispatch(toggleTableOrder(tableActionTypes.toggleTableOrder, isAsc ? ORDER_DESC : ORDER_ASC))
    dispatch(setTableColumnToOrderBy(tableActionTypes.setTableColumnToOrderBy, property))
  }

  const handleChangePage = (event, newPage) => {
    dispatch(setTablePage(tableActionTypes.setTablePage, newPage))
  }

  const handleChangeRowsPerPage = (event) => {
    dispatch(setTableRowsPerPage(tableActionTypes.setTableRowsPerPage, parseInt(event.target.value, DEFAULT_RADIX)))
    dispatch(setTablePage(tableActionTypes.setTablePage, 0))
  }

  const handleChangeDense = (event) => {
    dispatch(toggleTableDensity(tableActionTypes.toggleTableDensity, event.target.checked))
  }

  return (
    <div className='enhanced-table'>
      <TableContainer>
        <Table
          size={dense ? 'small' : 'medium'}
        >
          <EnhancedTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            collapsibleRows={collapsibleRows}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return <EnhanceTableRow dataId={dataId} key={index} row={row} cells={cells} collapsibleRows={collapsibleRows} />
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <div className='table-view-controllers'>
        <FormControlLabel
          className='density-switch'
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label={t('dense.view')}
        />
        <TablePagination
          className='table-pagination'
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={rows.length}
          labelRowsPerPage={t('show.rows') + ':'}
          rowsPerPage={rowsPerPage}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  )
}

export default EnhancedTable
