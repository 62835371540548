import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const LayerVisibilityToggle = ({ label, checked, activateFn, deactivateFn }) => {
  const toggleLayer = function () {
    if (checked) {
      deactivateFn()
    } else {
      activateFn()
    }
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={toggleLayer}
          name={label}
          color='primary'
        />
      }
      label={label}
    />
  )
}

export default LayerVisibilityToggle
