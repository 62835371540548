import React from 'react'
import './Menubar.scss'

import Navigation from '../Navigation/Navigation'

function Menubar () {
  return (
    <div className='menubar'>
      <Navigation />
    </div>
  )
}

export default Menubar
