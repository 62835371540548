import {
  SET_TRAM_DATA,
  SET_MAP_DATA_SUBSET,
  TOGGLE_MAP_DATA_ACTIVITY,
  SET_TRAM_DATA_CONNECTION_ERROR,
  SET_TRAM_DATA_EXPIRY_STATUS
} from './actionTypes'
import tramService from '../../services/tramService'
import infraService from '../../services/infraService'

export const setMapTramData = (dataId) => {
  return async dispatch => {
    const data = await tramService.getTrams()
    dispatch({
      type: SET_TRAM_DATA,
      payload: {
        dataId: dataId,
        data: data
      }
    })
  }
}

export const setTramData = (dataId, type, data) => {
  return dispatch => dispatch({
    type: type,
    payload: {
      dataId: dataId,
      data: data
    }
  })
}

export const setTramDataError = (dataId, isError) => {
  return dispatch => dispatch({
    type: SET_TRAM_DATA_CONNECTION_ERROR,
    payload: {
      dataId: dataId,
      isError: isError
    }
  })
}

export const setMapInfraData = (dataId, subsetId, resourceType) => {
  return async dispatch => {
    const data = await infraService.getInfraByResourceType(resourceType)
    return new Promise((resolve) => {
      dispatch({
        type: SET_MAP_DATA_SUBSET,
        payload: {
          dataId: dataId,
          subsetId: subsetId,
          data: data.data
        }
      })
      resolve(data.data)
    })
  }
}

export const setTramDataExpiryStatus = (dataId, tramId, status) => ({
  type: SET_TRAM_DATA_EXPIRY_STATUS,
  payload: {
    dataId: dataId,
    tramId: tramId,
    status: status
  }
})

// TODO: TEMPORARY UNTIL TRO-289
export const toggleMapDataActivity = (dataId) => ({
  type: TOGGLE_MAP_DATA_ACTIVITY,
  payload: {
    dataId: dataId
  }
})
