export const STATUS_FIELD_KEY = 'status'
export const STATUS_COLOR_GREEN = 'green'
export const STATUS_COLOR_ORANGE = 'orange'
export const STATUS_COLOR_RED = 'red'
export const STATUS_COLOR_BLUE = 'blue'

export const STATUS_OPEN = 'Avoin'
export const STATUS_ASSIGNED = 'Vastuutettu'
export const STATUS_PROCESSED = 'Valmis'
export const STATUS_LATE = 'Myöhässä'
export const STATUS_READY_FOR_WORK = 'Aloituskelpoinen'
export const STATUS_PROCESSED_UNSIGNED = 'Valmis/kirjaamatta'
export const STATUS_PLANNED = 'Suunniteltu'
export const STATUS_IN_PROGRESS = 'Aloitettu'

export const ONLINE_STATUS_IN_TRAFFIC = 'Liikenteessä'
export const ONLINE_STATUS_READY_FOR_TRAFFIC = 'Liikennekelpoinen'
export const ONLINE_STATUS_OFF_TRAFFIC = 'Ei käytössä'
export const ONLINE_STATUS_AVAILABLE_FOR_TRAFFIC = 'Vapaana liikenteeseen'
export const ONLINE_STATUS_PROHIBITED_FROM_TRAFFIC = 'Käyttökiellossa'
export const ONLINE_STATUS_IN_MAINTENANCE = 'Huollossa'
export const ONLINE_STATUS_IN_REPAIRMENT = 'Korjauksessa'

export const TABLE_CELL_TYPE_TEXT = 'text'
export const TABLE_CELL_TYPE_FLOAT_SINGLE_DECIMAL = 'float-single-decimal'
export const TABLE_CELL_TYPE_DATE = 'date'
export const TABLE_CELL_TYPE_DATE_TIME = 'date-time'
export const TABLE_CELL_TYPE_LINK = 'link'
export const TABLE_CELL_TYPE_STATUS = 'status'
export const TABLE_CELL_TYPE_POPUP = 'popup'
export const TABLE_CELL_TYPE_EXTERNAL_LINK = 'external-link'
export const TABLE_CELL_TYPE_DEADLINE = 'deadline'
export const TABLE_CELL_TYPE_SEVERITY_TYPED_TEXT = 'severity-typed-text'
export const TABLE_CELL_TYPE_ONLINE_STATUS = 'online-status'
export const TABLE_CELL_TYPE_PERCENTAGE = 'percentage'
export const TABLE_CELL_TYPE_STATUS_COUNT = 'status-count'
export const TABLE_CELL_TYPE_COLLAPSE_ARRAY = 'collapse-array'
export const TABLE_CELL_TYPE_TRAM_LINE = 'tram-line'

export const DATA_CATEGORY_UNCATEGORIZED = 'Muut'
export const DATA_CATEGORY_UNCATEGORIZED_COLOR = '#E3EAF3'

export const SCROLLABLE_SUBTABLE_MAX_ROWS = 5

export const NEWS_PDF_FILE_PATH = process.env.REACT_APP_API + '/files/info.pdf'
