import React from 'react'
import './RecordSummary.scss'

const RecordSummary = ({ columned, children }) => {
  return (
    <div className={`record-summary ${columned ? 'columned' : ''}`}>
      {children}
    </div>
  )
}

export default RecordSummary
