import React from 'react'
import Drawer from '@material-ui/core/Drawer'

function DrawerMenu ({ children, anchor, open }) {
  return (
    <Drawer
      className='drawer-menu'
      variant='persistent'
      anchor={anchor}
      open={open}
    >
      <div className='drawer-menu-content'>
        {children}
      </div>
    </Drawer>
  )
}

export default DrawerMenu
