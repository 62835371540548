import axios from 'axios'

// Not using default global as it is for secure API endpoints
const clientForAuthCall = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
})

const getUser = async () => {
  const response = await clientForAuthCall.get(process.env.REACT_APP_USER_URL)
  const user = {
    name: '',
    token: ''
  }
  if (response && response.data[0]) {
    const nameObject = response.data[0].user_claims.find(({ typ }) => typ === 'preferred_username')

    nameObject ? user.name = nameObject.val : user.name = ''
    user.token = response.data[0].access_token
  }

  return user
}

export default getUser
