import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import store from './store'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from './theme'
import i18n from './i18n/i18n'
import { I18nextProvider } from 'react-i18next'
import './chartjs/plugins/doughnutTextCenter'
import './chartjs/plugins/radiusBackground'

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA¶
serviceWorker.unregister()
