import {
  ACTIVATE_MALFUNCTION_DATA,
  TOGGLE_MALFUNCTION_TABLE_ORDER,
  SET_MALFUNCTION_TABLE_COLUMN_TO_ORDER_BY,
  TOGGLE_MALFUNCTION_TABLE_DENSITY,
  SET_MALFUNCTION_TABLE_PAGE,
  SET_MALFUNCTION_TABLE_ROWS_PER_PAGE,
  SET_INFRA_MALFUNCTION_DATA,
  SET_INFRA_MALFUNCTION_SUMMARY_DATA,
  SET_EQUIPMENT_MALFUNCTION_DATA,
  SET_EQUIPMENT_MALFUNCTION_SUMMARY_DATA,
  SET_MALFUNCTION_DATA_FILTERS,
  TOGGLE_MALFUNCTION_DATA_FILTER
} from './actionTypes'
import {
  activateData, setDataFilters, setDataWithDataTimestamp, setSummaryData,
  setTableColumnToOrderBy, setTablePage, setTableRowsPerPage,
  toggleDataFilter, toggleTableDensity, toggleTableOrder
} from '../general/mutations'

export const INFRA_ID = 'infra'
export const EQUIPMENT_ID = 'equipment'
export const TRAFFIC_ID = 'traffic'

const initialState = {
  active: INFRA_ID,
  [INFRA_ID]: {
    data: [],
    dataFilters: {
      name: [],
      category: [],
      status: [],
      summaryCategories: []
    },
    summaryData: [],
    dataTimestamp: null
  },
  [EQUIPMENT_ID]: {
    data: [],
    dataFilters: {
      tramId: [],
      malfunctionType: [],
      processingStatus: [],
      summaryCategories: []
    },
    summaryData: [],
    dataTimestamp: null
  },
  [TRAFFIC_ID]: {
    data: [],
    summaryData: [],
    dataTimestamp: null
  },
  table: {
    order: '',
    orderBy: '',
    dense: false,
    page: 0,
    rowsPerPage: 5
  }
}

const malfunctions = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVATE_MALFUNCTION_DATA: {
      const { dataId } = action.payload
      return activateData(state, dataId)
    }
    case SET_INFRA_MALFUNCTION_DATA: {
      const { dataId, data, dataTimestamp } = action.payload
      return setDataWithDataTimestamp(state, dataId, data, dataTimestamp)
    }
    case SET_INFRA_MALFUNCTION_SUMMARY_DATA: {
      const { dataId, summaryData } = action.payload
      return setSummaryData(state, dataId, summaryData)
    }
    case SET_EQUIPMENT_MALFUNCTION_DATA: {
      const { dataId, data, dataTimestamp } = action.payload
      return setDataWithDataTimestamp(state, dataId, data, dataTimestamp)
    }
    case SET_EQUIPMENT_MALFUNCTION_SUMMARY_DATA: {
      const { dataId, summaryData } = action.payload
      return setSummaryData(state, dataId, summaryData)
    }
    case SET_MALFUNCTION_DATA_FILTERS: {
      const { dataId, filterGroupId, filters } = action.payload
      return setDataFilters(state, dataId, filterGroupId, filters)
    }
    case TOGGLE_MALFUNCTION_DATA_FILTER: {
      const { dataId, filterGroupId, filter } = action.payload
      return toggleDataFilter(state, dataId, filterGroupId, filter)
    }
    case TOGGLE_MALFUNCTION_TABLE_ORDER: {
      const { order } = action.payload
      return toggleTableOrder(state, order)
    }
    case SET_MALFUNCTION_TABLE_COLUMN_TO_ORDER_BY: {
      const { orderBy } = action.payload
      return setTableColumnToOrderBy(state, orderBy)
    }
    case TOGGLE_MALFUNCTION_TABLE_DENSITY: {
      const { dense } = action.payload
      return toggleTableDensity(state, dense)
    }
    case SET_MALFUNCTION_TABLE_PAGE: {
      const { page } = action.payload
      return setTablePage(state, page)
    }
    case SET_MALFUNCTION_TABLE_ROWS_PER_PAGE: {
      const { rowsPerPage } = action.payload
      return setTableRowsPerPage(state, rowsPerPage)
    }
    default:
      return state
  }
}

export default malfunctions
